import { Route, Switch } from 'react-router-dom'

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Ticket from './pages/Ticket';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/Terms';
import BattlePlan from './pages/battleplans/BattlePlan';

// UI
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { darkTheme } from "./util/theme";
import NavBar from './components/NavBar';

function App()
{
  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      <NavBar />
      <div style={{ height: '100%', backgroundColor: darkTheme.palette.primary.background }}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/battleplan' component={BattlePlan} />
          <Route exact path='/ticket' component={Ticket} />
          <Route exact path='/terms' component={TermsConditions} />
          <Route exact path='/policy' component={Privacy} />
          {/* <Route exact path='/encounters' component={} /> */}
        </Switch>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
