import React, {useState, useEffect} from 'react';

// Material UI
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PartyCart from './../partycart/PartyCart';
import { map } from 'lodash';

import { useSelector } from 'react-redux';
import { useWindowDimensions } from './../hooks/useWindowDimensions';

const exp = new Map();
    exp.set( 0, 10)
    exp.set( "1/8", 25)
    exp.set( "1/4", 50)
    exp.set( "1/2", 100)
    exp.set( 1, 200)
    exp.set( 2, 450)
    exp.set( 3, 700)
    exp.set( 4, 1100)
    exp.set( 5, 1800)
    exp.set( 6, 2300)
    exp.set( 7, 2900)
    exp.set( 8, 3900)
    exp.set( 9, 5000)
    exp.set( 10, 5900)
    exp.set( 11, 7200)
    exp.set( 12, 8400)
    exp.set( 13, 10000)
    exp.set( 14, 11500)
    exp.set( 15, 13000)
    exp.set( 16, 15000)
    exp.set( 17, 18000)
    exp.set( 18, 20000)
    exp.set( 19, 22000)
    exp.set( 20, 25000)
    exp.set( 21, 33000)
    exp.set( 22, 41000)
    exp.set( 23, 50000)
    exp.set( 24, 62000)
    exp.set( 25, 75000)
    exp.set( 26, 90000)
    exp.set( 27, 105000)
    exp.set( 28, 120000)
    exp.set( 29, 135000)
    exp.set( 30, 155000)
//TODO: NEed to adjust the height of the buttom box
const MonsterList = (props) => {
    const {height, width} = useWindowDimensions();
    const handleCrExpConversion = (cr) =>{
        // console.log(exp.get(cr))
        return exp.get(cr);
    }
    // const monsters = useSelector( state.selected)
    // const playerCol = useSelector(state => state.battle.playerCollection)
    // const [heightSize, setHeightSize] = useState('61vh')
    
    // useEffect(() => {
    //     setHeightSize(calculateHeight(playerCol.length))
    // }, [playerCol])

    // const calculateHeight = (length)=>{
    //     if(length <= 1)
    //         return '61vh'
    //     else
    //         return '20vh'
    // }

    const monsterList = useSelector(state => state.battle.selectedMonsters);
    return (
        <div style={{maxHeight: '50vh'}}>
            <div style={{height: `50vh`, overflowY: "auto"}}>
                {monsterList.map((monster,i) => {
                    return(<> 

                    <PartyCart item={monster} handleRemoveItem={props.removeMonster} handleIncrement={props.addMonsterCount} handleDecrement={props.subMonsterCount} handleCrExpConversion={handleCrExpConversion} classes={props.classes}/>
                    </>
                    )
                    ; 
                })
            }
            </div>
        </div>
    );
};

export default MonsterList;
