import styled from '@emotion/styled';

const Form = styled.form`
    padding-left: 5rem;
    padding-right: 5em;
    display: grid;
    grid-template-columns: 10% 60% 20% 10%;
    justify-items: center;
    align-content: center;
    line-height: 1.5rem;
    font-weight: 600;
    input,
    textarea,
    select {
        width: 80%;
        padding: 0.5rem;
        font-size: 1rem;
        border: 2px solid grey;
        height: 130%;
        border-radius: 5px;
        background-color: #424242;
        &:focus {
            outline: 0;
            background-color: #2C2F33;
            border-color: #D9B08C;
        }
    }
    button {
        box-shadow: 0 2px 0 rgba(0,0,0,.35);
        background-color: #116466;
        width: 80%;
        height: 140%;
        color: #FFCB9A;
        margin-left: 30;
        min-height: 44;
        margin-top: 20;
        margin-bottom: 0;
        font-family: 'draconis';
        font-size: 1.5rem;
        border-radius: 10px;
        border-color: transparent;
        &:hover {
            color: #116466;
            background-color: #D1E8E2;
            font-family: prototype;
            cursor: pointer;
            font-family: 'draconis';
            min-height: 44;
            margin-top: 20;
            margin-bottom: 0;
        }
    }
`;

export default Form;