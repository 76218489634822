import React, {useEffect, useState} from 'react'
import { DataGrid } from '@material-ui/data-grid';

import { useSelector, useDispatch } from 'react-redux';
import { selectMonster} from '../../redux/actions/battleActions';
import { convertCRStringToDigit } from './../../redux/ReducerHelpers'

function createData(name, cr, size, type, alignment, source, id) {
  return {
    name, cr, size, type, alignment, source, id
  };
}

const DataTable = () => {

  const [rows, setRows ] = useState([]);
  const monsterList = useSelector(state => state.battle.monsterList)
  const selectedMonsters = useSelector(state => state.battle.selectedMonsters);
  // const selectedRows = useSelector(state => state.battle.selectRows)
  const [selectedRows, setSelectedRows ] = useState([]);
  const dispatch = useDispatch();

  const convertValue = (data) =>{
    // console.log(data)
    return convertCRStringToDigit(data.cr);
  }
  const columns = [
    {
      field: 'name',
      headerName: 'Name ',
      width: 250
    },
    {
      field: 'cr',
      headerName: 'CR',
      width: 100,
      editable: true,
      type: 'number',
      valueGetter: (params) =>
        `${convertValue(params.row) }`,
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 150,
      editable: true,
      // type: 'number',

    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      editable: true,
    },

    {
      field: 'alignment',
      headerName: 'Alignment',
      width: 150,
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      // width: 160,
      // valueGetter: (params) =>
      //   `${params.getValue(params.id, 'firstName') || ''} ${
      //     params.getValue(params.id, 'lastName') || ''
      //   }`,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 450,
      editable: false,
      sortable: false,
    },
  ];


  useEffect(()=> {
    setRows(monsterList.map((monster, i) => createData(monster.name,
      monster.cr,
      monster.size,
      monster.type,
      monster.alignment,
      monster.sources, i)));
  },[monsterList])

  useEffect(()=>{
    const temp = selectedMonsters.map((monster)=>{
      return monster.id
    })
    setSelectedRows(temp)
  },[selectedMonsters])

  console.log(selectedRows)
  // const handleSelection = (id) =>{
  //   console.log(id)

  //   let temp = selectedRows;
  //   if (selectedRows.indexOf(id) === -1){
  //     temp.push(id)
  //     temp.sort()
  //     // setSelectedRows(temp);
  //     // return temp
  //   }
  //   // return selectedRows

  //   dispatch()
  // }
  return (
    <div style={{ height: '87%', width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={15}
      // checkboxSelection= {(params)=>{
      //   console.log(params)
      // }}
      // disableSelectionOnClick
      onRowClick={ (params) =>{
        // console.log(params.row)
        dispatch(selectMonster(selectedMonsters, params.row, params.id))
        // handleSelection(params.id);
        // console.log(data)
      }}
      // onSelectionModelChange={(data)=>{

      //  return true
      // }}
      selectionModel={[...selectedRows]}
    />
  </div>
  )
}

export default DataTable
