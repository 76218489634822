import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#116466",
      background: "#424242",

    },
    secondary: {
      light: "#D1E8E2",
      main: "#FFCB9A",
      dark: "#D9B08C",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#2C2F33",
    },
  },
  typography: {
    useNextVariants: true,
  }
});

export default darkTheme;
