import React from 'react'
import { Link } from 'react-router-dom';
import artBoard from '../images/Artboard_7.png';

// UI 
import TermsConditionsStyle from '../components/styles/TermsConditionsStyle';
import Footer from '../components/Footer';



const TermsConditions = () =>
{
    return (
        <TermsConditionsStyle>
            <div className='header_image'>
                <Link to='/'>
                    <img src={artBoard} alt="tavern" style={{ height: "125px" }} />
                </Link>
            </div>
            <h1>Terms and Conditions</h1>
            <div className='main_text'>
                <p>
                    Please read these terms and conditions ("terms and conditions", "terms") carefully before using
                    daruzo.com (website and service) operated by Daruzo LLC ("us", 'we", "our").
                </p>
                <div>
                    <h3 >Conditions of use</h3>
                    <ul>
                        By using this website, you certify that you have read and reviewed this Agreement and that you
                        agree to comply with its terms. If you do not want to be bound by the terms of this Agreement,
                        you are advised to leave the website accordingly. Daruzo only grants use and access of this
                        website, its products, and its services to those who have accepted its terms.
                    </ul>
                </div>

                <div>
                    <h3> Privacy policy </h3>
                    <ul>
                        Before you continue using our website, we advise you to read our <span><Link to='/policy' style={{ color: "white" }}>Privacy Policy</Link></span> regarding our user data collection. It will help you better understand our practices.
                    </ul>
                </div>

                <div>
                    <h3>Age restriction</h3>
                    <ul>
                        You must be at least 18 (eighteen) years of age before you can use this website. By using this
                        website, you warrant that you are at least 18 years of age and you may legally adhere to this
                        Agreement. Daruzo assumes no responsibility for liabilities related to age misrepresentation.
                    </ul>
                </div>

                <div>
                    <h3>Intellectual property</h3>
                    <ul>
                        <p>
                            You agree that all materials, products, and services provided on this website are the property of
                            Daruzo, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all
                            copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree
                            that you will not reproduce or redistribute the Daruzo’s intellectual property in any way,
                            including electronic, digital, or new trademark registrations.

                        </p>
                        <p>
                            You grant Daruzo a royalty-free and non-exclusive license to display, use, copy, transmit, and
                            broadcast the content you upload and publish. For issues regarding intellectual property claims,
                            you should contact the company in order to come to an agreement.
                        </p>
                    </ul>
                </div>

                <div>
                    <h3>User accounts</h3>
                    <ul>
                        <p>
                            As a user of this website, you may be asked to register with us and provide private information.
                            You are responsible for ensuring the accuracy of this information, and you are responsible for
                            maintaining the safety and security of your identifying information. You are also responsible for
                            all activities that occur under your account or password.
                        </p>
                        <p>
                            If you think there are any possible issues regarding the security of your account on the website,
                            inform us immediately so we may address them accordingly.
                        </p>
                        <p>
                            We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole
                            discretion.
                        </p>
                    </ul>
                </div>
                <div>
                    <h3>Applicable law </h3>
                    <ul>
                        By visiting this website, you agree that the laws of the United States, without regard to principles of
                        conflict laws, will govern these terms and conditions, or any dispute of any sort that might come
                        between Daruzo and you, or its business partners and associates.
                    </ul>
                    <div>
                        <h3>Disputes</h3>
                        <ul>
                            Any dispute related in any way to your visit to this website or to products you purchase from us
                            shall be arbitrated by state or federal court United States and you consent to exclusive jurisdiction
                            and venue of such courts
                        </ul>
                    </div>
                    <div>
                        <h3>Indemnification</h3>
                        <ul>
                            You agree to indemnify Daruzo and its affiliates and hold Daruzo harmless against legal claims
                            and demands that may arise from your use or misuse of our services. We reserve the right to
                            select our own legal counsel.
                        </ul>
                    </div>

                    <div>
                        <h3>Limitation on liability</h3>
                        <ul>
                            <p>Daruzo is not liable for any damages that may occur to you as a result of your misuse of our
                                website.
                            </p>
                            <p>
                                Daruzo reserves the right to edit, modify, and change this Agreement at any time. We shall let
                                our users know of these changes through electronic mail. This Agreement is an understanding
                                between Daruzo and the user, and this supersedes and replaces all prior agreements regarding
                                the use of this website.
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </TermsConditionsStyle>
    )
}

export default TermsConditions;