import styled from '@emotion/styled';

const HomeStyleBody = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    font-family: 'draconis';
    .left_box {
        max-width: 1200px;
        background-color: #424242;
        overflow-Y: hidden;
        display: grid;
        grid-template-columns: 1fr;
        font-family: prototype;
    }
    .logo {
        margin: 0 auto;
        padding: 1.5rem;
        margin-top: 0;
        width: 192;
        height: 192;
    }
    h2 {    
        color: #B0C4DE;
        margin: auto;
        font-family: 'draconis';
        padding: 0;
        font-size: 2rem;
        font-weight: 100;
    }
    h3 {
        font-family: 'draconis';
        text-align: center;
        font-size: 1.3rem;
        font-weight: 100;
    }
    .main_body {
        display: grid;
        grid-template-rows: 1fr;
        align-items: center;
    }
    .links {
        font-size: 14;
        color: #FFCB9A;
        margin-left: 30;
        margin-top: 10;
        max-width: 600;
    }
    .terms_links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        column-gap: 20px;
        align-items: flex-end;
    }
    h4 {
        font-size: 16;
        font-family: montserrat;
        margin-left: 30;
        margin-top: 10;
        margin-bottom: 15;
        max-width: 600;
        text-align: center;
    }
    .email_error {
        text-align: center;
        margin-left: 30;
        color: red;
        margin-bottom: 5;
    }
    .email_success {
        text-align: center;
        margin: 30;
        margin-top: 20;
        font-size: clamp(16px, 3vw, 24px)
    }
    .link {
        color: #116466;
    }
`;

export default HomeStyleBody;