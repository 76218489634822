import React from "react";

//cat
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from '@material-ui/core/Grid';

//image
// import Cat from "../../images/other/Cat.jpg";

//components
import useMedia from "./../../components/useMedia";


import {   selectMonster,
  // addMonster,
  subMonster,
  removeMonster} from '../../redux/actions/battleActions'; 

import { useSelector, useDispatch } from "react-redux";

const PartyCart = (props) => {
  // const { classes, item, handleRemoveItem, handleIncrement, handleDecrement, handleCrExpConversion } =
  //   props;

  const dispatch = useDispatch();

  const { classes, item, handleCrExpConversion } = props;
  const isMob = useMedia("(min-width: 675px)");

  const currentMonsterList = useSelector(state => state.battle.selectedMonsters);
  const handleExpCalculation = () => {

    return item.count * handleCrExpConversion(item.cr);
  }

  const handleIncrement = (monster) =>{
    dispatch(selectMonster(currentMonsterList, monster))
  }

  const handleRemoveItem = (monster) =>{
    dispatch(removeMonster(currentMonsterList, monster))
  } 

  const handleDecrement = (monster) => {
    if( item.count > 1)
      dispatch(subMonster(currentMonsterList, monster))
    else
      dispatch(removeMonster(currentMonsterList, monster))
  }
  return (
    <>

      <div style={{ marginTop: ".5rem", display: "flex", justifyContent: "space-between", alignItems:"flex-start", flex: "1" }}>
        <div>
          <Typography style={{ fontSize: 28, fontFamily: "draconis", paddingTop: "1 rem" }}>
          {item.name}
          </Typography>
          <div style={{display:"flex"}}>
            <Typography style={{ fontSize: 16, fontFamily: "monotype", }}>
            CR: {item.cr}
            </Typography>
            <Typography style={{ fontSize: 16, fontFamily: "monotype", marginLeft: "2rem" }}>
              XP: { handleExpCalculation() }
            </Typography>
          </div>
          <Typography style={{ fontSize: 16, paddingTop: ".5rem",fontFamily: "monotype",}}>
        {item.source}
      </Typography>
        </div>
        <div style={{margin: ".5rem "}}>
          <div style={{ marginTop: "auto", marginBottom: "auto",  }}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"

            >
              <Button className={classes.button } onClick={() => handleDecrement(item)}>-</Button>
              <Button style={{color: "white"}} disabled>{item.count}</Button>
              <Button className={classes.button } onClick={() => handleIncrement(item)}>+</Button>
            </ButtonGroup>
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly", margin: 'auto' }}>
            <IconButton onClick={() => handleRemoveItem(item)} >
              <DeleteIcon style={{ fontSize: 22, color: 'red' }} />
            </IconButton>
          </div>
        </div>
      </div>
      {/* <br/> */}
    </>
  );
};

export default PartyCart;
