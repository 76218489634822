// battle Reducer Types
export const REMOVE_SELECETED_MONSTER = "REMOVE_SELECETED_MONSTER";
export const SET_SELECTED_MONSTER = "SET_SELECTED_MONSTER";
export const FILTER_MONSTER_LIST = "FILTER_MONSTER_LIST";
export const ADD_ANOTHER_PLAYER = "ADD_ANOTHER_PLAYER";
export const ADD_MONSTER_FILTER = "ADD_MONSTER_FILTER";
export const RESET_MONSTER_LIST = "RESET_MONSTER_LIST";
export const RESET_FILTERS = "RESET_FILTERS";
export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const ADD_MONSTER = "ADD_MONSTER";
export const SUB_MONSTER = "SUB_MONSTER";
export const UPDATE_LIST = "UPDATE_LIST";
export const EDIT_PLAYER = "EDIT_PLAYER";

//ecounters
export const SAVE_BATTLE_PLAN = "SAVE_BATTLE_PLAN";
export const REMOVE_SAVED_BATTLE_PLAN = "REMOVE_SAVED_BATTLE_PLAN";