import { easyMap, mediumMap, hardMap, deadlyMap, dailyBudgetMap } from '../pages/battleplans/selectData'

export const AddItemToArray = (arr, item) => {
  arr.push(item);
  return arr;
};

export const findAndAddMaps = (arrayObjects, newValue) => {
  const newArrayObject = arrayObjects.map(obj => {
    if (obj.id === newValue.id)
      return { ...obj, maps: newValue };
    else
      return obj;
  });

  return newArrayObject;
};

export const RemoveItemFromArray = (arr, item) => {
  const newArray = arr.filter((obj) => item.id !== obj.id);
  return newArray;
};

export function findAndReplaceEditObject(arrayObjects, newValue) {
  const newArrayObject = arrayObjects.map(obj => {
    if (obj.id === newValue.id)
    return newValue;
    else
    return obj;
  });
  return newArrayObject;
}

export const RemoveFilterOptoinList = ( arr, name, value) => {
  let newArray = []

  newArray = arr.filter( (obj) =>  obj[name] === value)

  return newArray;
}

export const environmentFilter = (arr, name, value) => {

  let newArray =[]

  newArray = arr.filter( (obj) => obj[name].split(', ').indexOf(value) > -1);

  return newArray;
}

  // const calculateDiff = ()=>{

  //   if(totalExp <= battleDifficulty.easy )
  //     return "Easy"
  //   if(totalExp > battleDifficulty.easy && totalExp <= battleDifficulty.medium)
  //     return "Medium"
  //   if(totalExp > battleDifficulty.medium && totalExp <= battleDifficulty.hard)
  //     return "Hard"
  //   if(totalExp > battleDifficulty.hard)
  //     return "Deadly"
  // }

export const calculateBattleDifficulties = (adjustedExp, players) => {

  const battleDifficulty = {
    difficulty: "",
    easy: 0,
    medium: 0,
    hard: 0,
    deadly: 0,
    dailyBudget: 0,
  }

  players.map((player) => {
    battleDifficulty.easy += (player.players* easyMap.get(player.levels));
    battleDifficulty.medium += (player.players* mediumMap.get(player.levels));
    battleDifficulty.hard += (player.players* hardMap.get(player.levels));
    battleDifficulty.deadly += (player.players* deadlyMap.get(player.levels));
    battleDifficulty.dailyBudget += player.players* dailyBudgetMap.get(player.levels);
  })


  if(adjustedExp <= battleDifficulty.easy )
    battleDifficulty.difficulty = "Easy"
  if(adjustedExp > battleDifficulty.easy && adjustedExp <= battleDifficulty.medium)
    battleDifficulty.difficulty =  "Medium"
  if(adjustedExp > battleDifficulty.medium && adjustedExp <= battleDifficulty.hard)
   battleDifficulty.difficulty = "Hard"
  if(adjustedExp > battleDifficulty.hard)
    battleDifficulty.difficulty= "Deadly"

  return battleDifficulty;
}

export const RemoveFilterAlignment = ( arr, name, value) => {
  let newArray = arr;


  const newValue = value.split(' ')[1]

  if( value.split(' ')[0] === 'any' && ( newValue === 'chaotic' ||  newValue === 'neutral' || newValue === 'lawful') )
    return newArray = newArray.filter( (obj) =>  obj[name].split(' ')[0] === newValue)
  if( value.split(' ')[0] === 'any' && ( newValue === 'good' ||  newValue === 'evil' || newValue === 'neutral') )
    return newArray = newArray.filter( (obj) =>  obj[name].split(' ')[1] === newValue);

  if(  value === 'non-chaotic' ||  value === 'non-evil' || value === 'non-good' || value === 'non-lawful'  ){
    
    const removeWord = value.split('-')[1];

    if( removeWord === 'good' || removeWord === 'evil') 
      return newArray = newArray.filter( (obj) =>  obj[name].split(' ')[1] !== removeWord)
    else
      return newArray = newArray.filter( (obj) =>  obj[name].split(' ')[0] !== removeWord)
  }

  return newArray.filter( (obj) =>  obj[name] === value);
}


export function filterHelper( arr, filters){

  let newArray = arr
  // filter size

  if( filters.size !== "Any")
    newArray = RemoveFilterOptoinList( newArray, 'size', filters.size);

  // filter type
  if( filters.type !== "Any")
    newArray = RemoveFilterOptoinList( newArray, 'type', filters.type);
  
  // filter alignment
  if( filters.alignment !== "Any")
      newArray = RemoveFilterAlignment( newArray, 'alignment', filters.alignment );
      
      // filter enviorment
  if( filters.environment !== "Any")
    newArray = environmentFilter( newArray, 'environment', filters.environment );
  
  // filter legendary
  if( filters.legendary !== "Any")
    newArray = RemoveFilterOptoinList( newArray, 'legendary', filters.legendary.toLowerCase() );   
 
  // filter min cr
  if( filters.minCr !== "Any")
    newArray = RemoveCRFilter( newArray, filters.minCr, '<')
  
  // filter max cr
  if( filters.maxCr !== "Any")
    newArray = RemoveCRFilter( newArray,  filters.maxCr, '>')

  // console.log(filters.maxCr, filters.minCr)

  return newArray
}

export const convertCRStringToDigit = (str) =>{
  if ( str === "1/8")
    str = .125
  if (str === "1/4")
    str = .25 
  if (str === "1/2")
    str = .5

  return str;
}

export const RemoveCRFilter = ( arr,  value, type) => {
  let newArray = []


  // if ( value === "1/8")
  //   value = .125
  // if (value === "1/4")
  //   value = .25 
  // if (value === "1/2")
  //   value = .5

  value = convertCRStringToDigit(value);

  if(type === '<')
    newArray = arr.filter( (obj) =>  convertCRStringToDigit(obj.cr) >= value)
  if(type === '>')
    newArray = arr.filter( (obj) =>  convertCRStringToDigit(obj.cr) <= value)

  console.log(newArray)

  return newArray;
}

export const selectedMonster = (arr, monster) => {
  const temp = [...arr];
  const index = temp.findIndex(obj => obj.name === monster.name);
  
  if (-1 === index) {
      // setselMonster([...arr, { ...monster, count: 1 }]);
      return ([...arr, { ...monster, count: 1 }]);
  } else {
      temp[index].count = temp[index].count + 1;
      // setselMonster(temp);
      return temp;
  }
};