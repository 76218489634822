import Api from './index';
let mainURL;

switch (process.env.NODE_ENV)
{
    case 'production':
        mainURL = process.env.REACT_APP_AXIOS_PROD;
        break;
    case 'staging':
        mainURL = process.env.REACT_APP_AXIOS_STAGING;
        break;
    case 'dev':
    case 'local':
        mainURL = process.env.REACT_APP_AXIOS_DEV;
        break;
    default:
        mainURL = process.env.REACT_APP_AXIOS_DEV;
        break;
}


class fireBaseApi extends Api 
{
    sendReport(data)
    {
        return this.instance.post('/sendreport', data);
    }

    welcomeUser(data)
    {
        return this.instance.post('/registeremail', data);
    }
}

const fireApi = new fireBaseApi(mainURL);

export default fireApi;