import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import Footer from './../components/Footer';
import fireApi from '../util/fireBaseApi';
import {AlertSuccess} from '../components/AlertSuccess';

// UI 
import TicketStyles from '../components/styles/TicketStyles';

const Ticket = () =>
{
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [alert, setAlert] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const options = [
    { label: "Bug", value: 1 },
    { label: "Suggestion", value: 2 },
    { label: "Account- Coming Soon", value: 3 },
    { label: "Other", value: 4 }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted red',
      color: state.isSelected ? '#116466' : 'black',
      backgroundColor: state.isFocused ? '#FFCB9A' : 'white',
      fontWeight: '100',
      fontSize: '2em' ,
      padding: 20,
    }),
    control: (provided, state) => ({
      ...provided,
      width: 350,
      backgroundColor: '#424242',
      borderColor: '#D9B08C',
      color: 'white',
      borderRadius: 5,
    }),
    singleValue: (provided, state) =>
    {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#D9B08C';
      const fontWeight =  '100';
      const fontSize = '1.5em' ;
      return { ...provided, opacity, transition, color, fontWeight, fontSize };
    },
    placeholder: (provided) => (
      {
        ...provided,
        color: '#D9B08C',
        fontWeight: '100',
        fontSize: '2em' ,
      })
  }

  const handleSubmit = async (event) =>
  {
    event.preventDefault();
    const sentObject = {
      email: email,
      category: `${category}`,
      content: content
    }
    const response = await fireApi.sendReport(sentObject);
    if (response.status === 200)
    {
      setAlert(true);
      setEmail('');
      setSubmitted(true);
      setCategory('');
      setContent('');
      setTimeout(
        function ()
        {
          setAlert(false);
        },
        4000
      );
    }
  }
  return (

    <TicketStyles>
      {alert ? <AlertSuccess /> : null}

      <div className='header'>
        <h1>Ticket</h1>
        <h4>To report problems or give us suggestions, check out our discord!</h4>
      </div>
      { !isSubmitted ? 
      <form>
        <label>Category</label>
        <Select
          styles={customStyles}
          options={options}
          onChange={value => setCategory(value.label)}
        />
        <lable className='email'>Email</lable>
        <input
          id="email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Comments</label>
        <textarea
          name="content"
          cols="40"
          rows="5"
          id="content"
          type="text"
          autoComplete="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        >
        </textarea>
        <div></div>
      </form> : <div>
        <h3>Thank You for you report, we will review it! Have a wonderful day</h3>
      </div>
    }
     { !loading ? 
      (!isSubmitted &&
      <button
        className='submit'
        onClick={handleSubmit}
      >Submit</button>
      ) : 
      <div>Loading</div>}

      <Footer />
    </TicketStyles>
  )
}

export default Ticket
