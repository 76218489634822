import React, { useState } from 'react'

//redux
import { useSelector } from 'react-redux';

// material ui



const BattleExp = (props) => {
  const {classes } = props;

  // const [battleDifficult, setBattleDifficulty] = useState({easy: 25, medium: 50, hard: 75, deadly: 100});

  // const [dailyBudget, setDailyBudget] = useState(300);

  // const adjustExp = useSelector( state =>  state.battle.adjustXp);
  const totalExp = useSelector(state => state.battle.totalXp);
  const easy = useSelector( state =>  state.battle.easy)
  const medium = useSelector( state =>  state.battle.medium)
  const hard = useSelector( state =>  state.battle.hard)
  const deadly = useSelector( state =>   state.battle.deadly)
  // const difficulty = useSelector( state =>  state.battle.difficulty) 

  const calculateDiff = ()=>{

    if(totalExp <= easy )
      return "Easy"
    if(totalExp > easy && totalExp <= medium)
      return "Medium"
    if(totalExp > medium && totalExp <= hard)
      return "Hard"
    if(totalExp > hard)
      return "Deadly"
  }

  return (
    <div >
      <div className={classes.list}>
        {`Easy: ${easy.toLocaleString("en-US")} exp`} 
      </div>
      <div className={classes.list}>
        {`Medium: ${medium.toLocaleString("en-US")} exp`}
      </div>
      <div className={classes.list}>
        {`Hard: ${hard.toLocaleString("en-US")} exp`}
      </div>
      <div className={classes.list}>
      {`Deadly: ${deadly.toLocaleString("en-US")} exp`}
      </div>
      <div className={classes.list}>
        {`Difficulty: ${calculateDiff()}`}
      </div>
    </div>
  )
}

export default BattleExp
