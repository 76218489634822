import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { throttle } from 'lodash';
import thunk from "redux-thunk";
import { loadState, saveState } from './persistState';

import battleReducer from "./reducers/battleReducer";

const middleware = [thunk];

const persistedState = loadState();

const reducers = combineReducers({
  battle: battleReducer,
});

const composeEnhancers =
  // eslint-disable-next-line no-undef
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

store.subscribe(throttle(() => {
  saveState({
    user: store.getState().user,
    battle: battleReducer,
    // encounters: encounterReducer,
    // data: store.getState().data,
  });
}, 1000));

export default store;