import
{
    SET_SELECTED_MONSTER,
    ADD_MONSTER,
    SUB_MONSTER,
    REMOVE_SELECETED_MONSTER,
    RESET_FILTERS,
    ADD_MONSTER_FILTER,
    FILTER_MONSTER_LIST,
    UPDATE_LIST,
    RESET_MONSTER_LIST,
    ADD_ANOTHER_PLAYER,
    REMOVE_PLAYER,
    EDIT_PLAYER,
    // SELECT_ROW,
} from "./../types";

import { monsters } from './../../pages/battleplans/monsters';
import { filterHelper, AddItemToArray, findAndReplaceEditObject, calculateBattleDifficulties, RemoveItemFromArray } from './../ReducerHelpers';

const initialState = {
    playerCollection: [{ id: 0, players: 1, levels: 1
    }],
    // selectedRows: [],
    selectedMonsters: [],
    monsterList: monsters,
    filters:{
        size: "Any",
        type: "Any",
        minCr: "Any",
        maxCr: "Any",
        alignment: "Any",
        environment: "Any",
        legendary: "Any"
    },
    // anotherPlayer: [],
    totalCount: 0,
    totalXp: 0,
    adjustXp: 0,
    difficulty: "Easy",
    easy: 25,
    medium: 50,
    hard: 75,
    deadly: 100,
    dailyBudget: 300,
};

const battleReducer = (state = initialState, action) =>
{
    switch (action.type)
    {
        case ADD_ANOTHER_PLAYER:
            const newPlayer = AddItemToArray( state.playerCollection, {  id: state.playerCollection.length, players: 1, levels: 1});
            const newBattle = calculateBattleDifficulties(state.totalXp, newPlayer );
            return {
                ...state,
                playerCollection: newPlayer,
                ...newBattle
            };
        case EDIT_PLAYER:
            const updatedPlayerCol = findAndReplaceEditObject(state.playerCollection, action.payload)
            const newBattleInfo = calculateBattleDifficulties( state.adjustXp, updatedPlayerCol)
            return {
                ...state,
                playerCollection: updatedPlayerCol,
                difficulty: newBattleInfo.difficulty,
                ...newBattleInfo
                // easy: newBattleInfo.easy,
                // medium: newBattleInfo.medium,
                // hard: newBattleInfo.hard,
                // deadly: newBattleInfo.deadly,
                // dailyBudget: newBattleInfo.dailyBudget,
            };
        case REMOVE_PLAYER:
            const removePlayerCol = RemoveItemFromArray(state.playerCollection, action.payload)
            const battleChange = calculateBattleDifficulties(state.totalXp, removePlayerCol)
            return{
                ...state,
                playerCollection: RemoveItemFromArray(state.playerCollection, action.payload),
                ...battleChange
            }
        case UPDATE_LIST:
            return{
                ...state,
                monsterList: filterHelper(action.payload, state.filters)
            };
        case SET_SELECTED_MONSTER:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                totalXp: action.payload.totalXp,
                adjustXp: action.payload.adjustXp,
                selectedMonsters: action.payload.monsterList,
            };
        case ADD_MONSTER:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                totalXp: action.payload.totalXp,
                adjustXp: action.payload.adjustXp,
                selectedMonsters: action.payload.monsterList,
            };
        case SUB_MONSTER:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                totalXp: action.payload.totalXp,
                adjustXp: action.payload.adjustXp,
                selectedMonsters: action.payload.monsterList,
            };
        case REMOVE_SELECETED_MONSTER:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                totalXp: action.payload.totalXp,
                adjustXp: action.payload.adjustXp,
                selectedMonsters: action.payload.monsterList,
            };
        case RESET_FILTERS:
            return{
                ...state,
                filters: initialState.filters,
                monsterList: monsters
            }
        case RESET_MONSTER_LIST:
            return{
                ...state,
                monsterList: filterHelper(monsters, state.filters)
            };
        case ADD_MONSTER_FILTER:
            return {
                ...state,
                filters: action.payload,
                monsterList: filterHelper(monsters, action.payload)
            }
        default:
            return state;
    }
};



export default battleReducer;