import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch, shallowEqual  } from 'react-redux';

import { editPlayer, addPlayer, updatelist, removePlayer } from '../redux/actions/battleActions';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

// styles
import { useStyles } from '../pages/battleplans/BattlePlan.styles';


const PlayerSelect = (props) => {
  const {players, levels, id, handleChange, handleRmovePlayer} = props;

  const classes = useStyles();
  return (
    <div style={{display: "flex"}}>
      <div>
        {id ===0 && <FormHelperText style={{padding: "5px 0", fontSize: 16}}>Players:</FormHelperText>}
        <FormControl className={classes.formControl} >
          <Select
            value={players}
            name="players"
            onChange={(event) => handleChange(event,{players, levels, id})}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Players
            </MenuItem>
            {[...Array(12).keys()].map((value)=>{
              return (  <MenuItem key={value} value={value +1}>{value+1}</MenuItem>)
            })}
          </Select>
        
        </FormControl>
      </div>
      <div>
      {id ===0 && <FormHelperText style={{padding: "5px 0", fontSize: 16, marginLeft: ".5rem"}}>Levels:</FormHelperText>}
      <FormControl  style={{marginLeft: '.5rem'}}className={classes.formControl}>
        <Select
          value={levels}
          name='levels'
          onChange={(event,) => handleChange(event, {players, levels, id})}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" disabled>
            Levels
          </MenuItem>
          {[...Array(20).keys()].map((value)=>{
            return (  <MenuItem key={value} value={value +1}>{value+1}</MenuItem>)
          })}
        </Select>
        {/* <FormHelperText>Levels</FormHelperText> */}
      </FormControl>
      
      </div>
      <div style={{marginTop: "-1rem"}}>
        { id > 0 && 
          <IconButton onClick={(event) => handleRmovePlayer({players, levels, id})} style={{justifyContent: "center", alignItems: "center", alignContent: "center", margin: "auto"}}>
          <DeleteIcon style={{ color: "red", fontSize: 24 }} />
          </IconButton>
          }

      </div>
    
    </div>
  )
}
const BattlePlayer = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const playerCollection = useSelector(state => state.battle.playerCollection, shallowEqual );

  const [playersCol, setPlayersCol] = useState(playerCollection);

  useEffect(()=> {

    setPlayersCol(playerCollection)
  }, [playerCollection])


  const handleChange = (event, player) => {
    console.log(event.target)
    const values = {
      id: player.id,
      players: player.players,
      levels: player.levels
    }
    if(event.target.name === "players"){
      // setPlayers(event.target.value);
      values.players = event.target.value;
    }

    if(event.target.name === "levels"){
      // setLevels(event.target.value);
      values.levels = event.target.value;
    }
    console.log(values)
    
    // setBattleDifficulty(
    //   { easy: (values.players* easyMap.get(values.levels)),
    //     medium: (values.players* mediumMap.get(values.levels)),
    //     hard: (values.players* hardMap.get(values.levels)),
    //     deadly: (values.players* deadlyMap.get(values.levels))
    // })

    // const budget =  values.players* dailyBudgetMap.get(values.levels);
    // setDailyBudget(budget);

    dispatch(editPlayer(values))
  }


  const handleAddPlayer = () => {
    dispatch(addPlayer())
  }

  const handleRmovePlayer = (player) => {
    // console.log("Remove player")
    dispatch(removePlayer(player));
  }

  return (
    <div style={{maxHeight: '20vh', overflowY: "auto"}}>
      {  playersCol.length > 0 && playersCol.map((data, i)=>{
        return <div key={i}>
            <PlayerSelect players={data.players} levels={data.levels}  id={data.id} handleChange={handleChange} handleRmovePlayer={handleRmovePlayer}/>
          </div>
      })}
    </div>
  )
}

export default BattlePlayer
