import React from 'react'

// Components
import Footer from '../components/Footer';

// images
import vex from '../images/vex30.png'
import forse from '../images/forse.jpg';
import bodies from '../images/bodies.jpg';

// UI
import AboutStyled from '../components/styles/AboutStyled';
import AboutBodyStyle from '../components/styles/AboutBodyStyle';

const team = [{
    name: "Bryce 'Vex30' Kelly",
    ign: "Vex30",
    img: vex,
    about: "Hey everyone! I am the community manager for thetavernio. I've known this team for over five years now since we played other games competitively together. I have been an avid gamer for over 10 years now and I don’t see myself stopping anytime soon! I myself have a lot of experience growing an audience and communicating with communities alike. I have grown my YouTube channel to over 31,000 subscribers based on the game Paladins. I have done this over a few years and a lot of work. I absolutely love working with people and talking with them, well of course I do or else I would have never taken this role in the website :)",
    exp: "My first experience with D&D was six years ago when some friends of mine invited me to join them in an online session. I worked with the DMS for HOURS and HOURS to create my first character and I was so proud of my ranger. Towards the end of our first session we got to a fort of Kobolds and one of my louder members decided to attempt to climb the wall and be a hero...he fell and now an entire fort of kobolds were after us. We decided to stay and fight and my entire party went down besides me. I was one shot, but there was only one kobold left, the kobold missed its attack so I struck it, killing it with a natural 20 with my rapier. I will never forget this first experience and the rush that came with it!"
  },
  {
    name: "Ilya Indik",
    ign: "Forse, BullettiP",
    img: forse,
    about: "Hello, my name is Ilya Indik. Online I go by Forse, or BullettiP.\n  I don’t play D&D myself but did participate in one random event to coming as a demi-god to judge the players for mischievous behavior. In real life and online, most of my friends play D&D. I have been around D&D for a long time. I got into coding about a year and a half ago, realizing the great things I can create to help communities come together. I really enjoy programming and do it in my spare time. I love the new technology, and I believe we can make software to help people connect and spend time together.",
    exp: "One day of smiting people."
  },
  {
    name: "Enno Aragon",
    ign: "iDropBodies",
    img: bodies,
    about: "Yo what's up everyone! I'm an ex-professional gamer, now developer, hoping to bring everyone the best products and build a community. I'm an ultra nerd that loves to gaming, movies, being active, and traveling/meeting new people!",
    exp: "I played D&D for the first time back in 2016. I started with a half Orc barbarian inspired by Lennie from the book \"Of Mice and Men.\" My other friend played the part of George, which guided and translated my broken English. Initially, my shyness didn't allow me to enjoy D&D as much as I do now. When you commit to your campaign and character, it truly becomes the best game and second to none. My latest character, I focused on Role-Playing, and it's been a game-changer. The experience is unbeatable, especially when your other campaigners immerse themselves into the campaign. My favorite moment in D&D was when my 4ft tall 175ft pound Paladin dwarf, clenched by a Giant Crocodile in a swampy forest. I decided to misty step out. Instead of teleporting into a safe spot, I ended up 30ft into the air and striking the beast with my war hammer. The best part was me rolling a nat 20, smiting the croc, which I jumped out of my chair in excitement. The moment played out exactly how I envisioned it, and it was glorious! "
  }]

const About = () => {
  return (
    <>
      <AboutStyled>
        <h1>Meet the Team</h1>
        {team.map((member) =>{
          return (
            <AboutBodyStyle>
              <div className='image_div'>
                <div className='image'>
                  <img src={member.img} alt="portrait" />
                </div>
                <div className='member_name'>
                  <span style={{ fontWeight: 600, color: '#20B2AA' }}>Name: </span> {member.name}
                  <span style={{ fontWeight: 600, color: '#20B2AA' }}>IGN: </span> {member.ign}
                </div>
              </div>
              <div className='member_description'>
                <span style={{ fontWeight: 900, fontSize: '1.5em', color: "#eaba61" }}>About: </span> {member.about}
                <br />
                <br />
                <span style={{ fontWeight: 900, fontSize: '1.5em', color: "#eaba61" }}>D&D Experience: </span>  {member.exp === "" ? 'None' : member.exp}
              </div>
            </AboutBodyStyle>
          )
        })
        }
        <Footer />
      </AboutStyled>
    </>
  )
}

export default About
