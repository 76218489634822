import styled from '@emotion/styled';

const HomeStyle = styled.div`
    max-width: 1200px;
    height: 100vh;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 15px;
    padding-top: 65px;
    margin: auto;
    font-family: prototype;
    background-color: #424242;
`;

export default HomeStyle;