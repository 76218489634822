import React from 'react';
import { useHistory } from 'react-router-dom'

import { isMobile } from 'react-device-detect';
import DropDownMenu from './dropdownmenu/DropDownMenu';

// materia UI
import { makeStyles } from '@material-ui/core/styles';
import { Button, Toolbar, Typography, AppBar } from '@material-ui/core';
import { AlertMobileMessage } from './AlertSuccess';
const myStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // width: '100%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "draconis",
  },
  buttons:{
    fontFamily: "draconis",
    fontSize: 24
  }
}));

// Maket his more dynamic when a campaign is selected or the url is within the correct location

const NavBar = () =>
{
  const classes = myStyles();
  const history = useHistory();

  return (
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Typography variant="h3" className={classes.title} >
            <a style={{textDecoration: 'none', color: 'white'}} href='/'>
              The Tavern
            </a>
          </Typography>
          {isMobile ?  <DropDownMenu/>: <>
            <Button color="inherit" className={classes.buttons} onClick={()=> { history.push("/")}}>Home</Button>
            <Button color="inherit" className={classes.buttons} onClick={() => { history.push("/battleplan")}}>BattlePlan</Button>
            <Button color="inherit" className={classes.buttons} onClick={() => { history.push("/encounters")}}>
              Encounters
            </Button>
            <Button color="inherit" className={classes.buttons} onClick={() => { history.push("/ticket")}}>Ticket</Button>
            <Button color="inherit" className={classes.buttons} onClick={() => { history.push("/about")}}>About Us</Button>
          </>
          }
          </Toolbar>
          {isMobile && <div style={{top: "200px", position: "fixed", zIndex: 0 }}>
          <AlertMobileMessage/>
          </div>}
      </AppBar>

  );
};

export default NavBar;