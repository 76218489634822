import React from 'react'
import twitter from '../images/twitter-gold.png';
// import facebook from '../images/facebook-Gold.png'
import discord from '../images/discord-gold.png';
// import twitch from '../images/twitch-filled.png'
import patreon from '../images/patreon-gold.png';
import email from '../images/email-gold-outline.png'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& div ": {
      padding: ".5em 0"
    },
    fontFamily: "prototype"
  },
  icon: {
    height: "32px",
    width: "32px",
    margin: "0 1em"
  }
}));

// TODO: Add links to bells and I,  name
const Footer = () =>
{
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div >
        <a href="https://discord.com/invite/wcZszqJUm7" target="_blank" rel="noreferrer">
          <img className={classes.icon} src={discord} alt="discord" />
        </a>
        <a href="https://www.patreon.com/thetavernio?fan_landing=true" target="_blank" rel="noreferrer">
          <img className={classes.icon} src={patreon} alt="patreon" />

        </a>
        <a href="https://twitter.com/TheTavernio" target="_blank" rel="noreferrer">
          <img className={classes.icon} src={twitter} alt="twitter" />
        </a>
        <a href="mailto:barkeep@thetavern.io">
          <img className={classes.icon} src={email} alt="email" />
        </a>
        {/* <a href="https://twitter.com/_zarabell" target="_blank" rel="noreferrer">
          <img className={classes.icon}src={twitch} alt="twitch"/>
        </a>  */}
        {/* <a href="https://www.facebook.com/DaruzoforGamers" target="_blank" rel="noreferrer">
         <img className={classes.icon} src={facebook} alt="facebook"/>
        </a> */}
      </div>
      <div>
        © 2021 thetavern.io owned by The Tavern. Developed by iDropStudios
      </div>
    </div>
  )
}

export default Footer
