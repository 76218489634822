import React, { useState } from 'react';

// 

// redux
import { useDispatch, useSelector } from 'react-redux';
import { sizeSelect, typeSelect, crSelect, alignmentSelect, environmentSelect, legendarySelect, } from '../../pages/battleplans/selectData';

import {addMonsterFilter, resetMonsterFilter, filterMonsterList } from '../../redux/actions/battleActions'

// Styles

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './SearchBar.styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FilterSharp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import battleReducer from './../../redux/reducers/battleReducer';

const SearchBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const environment = useSelector(state => state.battle.filters.environment );
  const legendary = useSelector(state => state.battle.filters.legendary );
  const alignment = useSelector(state => state.battle.filters.alignment);
  const minCr  = useSelector(state => state.battle.filters.minCr);
  const maxCr = useSelector(state => state.battle.filters.maxCr);
  const size = useSelector(state => state.battle.filters.size);
  const type = useSelector(state => state.battle.filters.type);

  // const [word, setWord] = useState("");
  const handleSelectOption = (event) =>{
    const filters = {
      size,
      type,
      minCr,
      maxCr,
      alignment,
      environment,
      legendary
    }

    filters[event.target.name] = event.target.value;
    
    dispatch(addMonsterFilter( filters));
    dispatch(filterMonsterList( event.target.name, event.target.value));
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={props.searchWord}
              // value={props.word}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={size}
              name='size'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Size
              </MenuItem>
              {sizeSelect.map((value)=>{
                return (  
                    <MenuItem key={value} value={value }>{value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Size</FormHelperText>
          </FormControl>
          </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={type}
              name='type'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Type
              </MenuItem>
              {typeSelect.map((value)=>{
                return (  <MenuItem  key={value}  value={value}>{value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Type</FormHelperText>
          </FormControl>
         </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={minCr}
              name='minCr'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Min CR
              </MenuItem>
              {crSelect.map((value)=>{
                return (  <MenuItem   key={value}  value={value}>{value === "Any" ? "Min CR" : value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Min CR</FormHelperText>
          </FormControl>
         </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={maxCr}
              name='maxCr'
              variant="filled"
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Max CR
              </MenuItem>
              {crSelect.map((value)=>{
                return (  <MenuItem  key={value} value={value }>{value === "Any" ? "Max CR" : value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Max CR</FormHelperText>
          </FormControl>
         </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={alignment}
              name='alignment'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Alignment
              </MenuItem>
              {alignmentSelect.map((value)=>{
                return (  <MenuItem  key={value}  value={value}>{value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Alignment</FormHelperText>
          </FormControl>
         </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={environment}
              name='environment'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Environment
              </MenuItem>
              {environmentSelect.map((value)=>{
                return (  <MenuItem  key={value}  value={value}>{value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Environment</FormHelperText>
          </FormControl>
         </div>
         <div style={{marginLeft: '.5em' , minWidth: 120,}}>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              value={legendary}
              name='legendary'
              onChange={handleSelectOption}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Legendary
              </MenuItem>
              {legendarySelect.map((value)=>{
                return (  <MenuItem  key={value} value={value}>{value}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Legendary</FormHelperText>
          </FormControl>
         </div>
          <div  style={{marginLeft: ".5rem"}} >
            <Tooltip title="Reset Filters" placement="bottom">
              <Button onClick={() => dispatch(resetMonsterFilter())} style={{backgroundColor:"red", height: "45", maringLeft: "1rem"}}> <RefreshIcon/> </Button> 

            </Tooltip>
          </div >
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default SearchBar;
