import { useRef } from 'react'
import { NavLink } from 'react-router-dom';
import menubar from '../../assets/icons/menubar.svg'

//hooks
import { useDetectOutsideClick } from './../hooks/useDetectOutsideClick';

//styles
import './dropdownmenu.styles.css'

const DropDownMenu  = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const handleMenuExpand = () => {
    setIsActive(!isActive);
  }

  return (
    <div>
      <button className="menu-trigger">
        <img src={menubar} alt="menu" height={35} onClick={handleMenuExpand} style={{position: 'relative'}}/>
      </button>
      <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
        <ul>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/battleplan">BattlePlan</NavLink></li>
          <li><a>Manange Battles:Soon!</a></li>
          <li><NavLink to='/ticket'>Ticket</NavLink></li>
          <li><NavLink to="/about">About Us</NavLink></li>
        </ul>
      </nav>
    </div>
  )
}

export default DropDownMenu 
