
export const sizeSelect = ["Any", "Tiny", "Small","Medium","Large","Huge","Gargantuan",]
export const typeSelect = ["Any", "Aberration", "Beast", "Celestial", "Construct", "Dragon", "Elemental", "Fey",
                    "Fiend", "Giant", "Humanoid", "Monstrosity", "Ooze", "Plant", "Undead"]
export const crSelect = ["Any",
                  "0",
                  "1/8",
                  "1/4",
                  "1/2",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12",
                  "13",
                  "14",
                  "15",
                  "16",
                  "17",
                  "18",
                  "19",
                  "20",
                  "21",
                  "22",
                  "23",
                  "24",
                  "25",
                  "26",
                  "27",
                  "28",
                  "29",
                  "30"]

export const alignmentSelect = [ "Any",
"any chaotic",
"any evil",
"any good",
"any lawful",
"any neutral" ,
"non-chaotic",
"non-evil",
"non-good",
"non-lawful",
"unaligned",
"lawful good",
"neutral good",
"chaotic good",
"lawful neutral",
"neutral neutral",
"chaotic neutral",
"lawful evil",
"neutral evil",
"chaotic evil" ]

export const environmentSelect = ["Any",
                            "aquatic",
                            "arctic",
                            "cave",
                            "coast",
                            "desert",
                            "dungeon",
                            "forest",
                            "grassland",
                            "mountain",
                            "planar",
                            "ruins",
                            "swamp",
                            "underground",
                            "urban"]


export const legendarySelect = [ "Any", "Ordinary", "Legendary", "Legendary (in lair)"];

const easyMap = new Map()
const mediumMap = new Map()
const hardMap = new Map()
const deadlyMap = new Map()

easyMap.set(1, 25)
easyMap.set(2,50)
easyMap.set(3,75)
easyMap.set(4, 125)
easyMap.set(5, 250)
easyMap.set(6, 300)
easyMap.set(7, 350)
easyMap.set(8, 450)
easyMap.set(9, 550)
easyMap.set(10, 600)
easyMap.set(11, 800)
easyMap.set(12, 1000)
easyMap.set(13, 1100)
easyMap.set(14, 1250)
easyMap.set(15, 1400)
easyMap.set(16, 1600)
easyMap.set(17, 2000)
easyMap.set(18, 2100)
easyMap.set(19, 2400)
easyMap.set(20, 2800)


mediumMap.set(1, 50)
mediumMap.set(2, 100)
mediumMap.set(3, 150)
mediumMap.set(4, 250)
mediumMap.set(5, 500)
mediumMap.set(6, 600)
mediumMap.set(7, 750)
mediumMap.set(8, 900)
mediumMap.set(9, 1100)
mediumMap.set(10, 1200)
mediumMap.set(11, 1600)
mediumMap.set(12, 2000)
mediumMap.set(13, 2200)
mediumMap.set(14, 2500)
mediumMap.set(15, 2800)
mediumMap.set(16, 3200)
mediumMap.set(17, 3900)
mediumMap.set(18, 4200)
mediumMap.set(19, 4900)
mediumMap.set(20, 5700)

hardMap.set(1, 75)
hardMap.set(2, 150)
hardMap.set(3, 225)
hardMap.set(4, 375)
hardMap.set(5, 750)
hardMap.set(6, 900)
hardMap.set(7, 1100)
hardMap.set(8, 1400)
hardMap.set(9, 1600)
hardMap.set(10, 1900)
hardMap.set(11, 2400)
hardMap.set(12, 3000)
hardMap.set(13, 3400)
hardMap.set(14, 3800)
hardMap.set(15, 4300)
hardMap.set(16, 4800)
hardMap.set(17, 5900)
hardMap.set(18, 6300)
hardMap.set(19, 7300)
hardMap.set(20, 8500)

deadlyMap.set(1, 100)
deadlyMap.set(2, 200)
deadlyMap.set(3, 400)
deadlyMap.set(4, 500)
deadlyMap.set(5, 1100)
deadlyMap.set(6, 1400)
deadlyMap.set(7, 1700)
deadlyMap.set(8, 2100)
deadlyMap.set(9, 2400)
deadlyMap.set(10, 2800)
deadlyMap.set(11, 3600)
deadlyMap.set(12, 4500)
deadlyMap.set(13, 5100)
deadlyMap.set(14, 5700)
deadlyMap.set(15, 6400)
deadlyMap.set(16, 7200)
deadlyMap.set(17, 8800)
deadlyMap.set(18, 9500)
deadlyMap.set(19, 10900)
deadlyMap.set(20, 12700)

const dailyBudgetMap = new Map();

dailyBudgetMap.set(1,300)
dailyBudgetMap.set(2,600)
dailyBudgetMap.set(3,1200)
dailyBudgetMap.set(4,1700)
dailyBudgetMap.set(5,3500)
dailyBudgetMap.set(6,4000)
dailyBudgetMap.set(7,5000)
dailyBudgetMap.set(8,6000)
dailyBudgetMap.set(9,7500)
dailyBudgetMap.set(10,9000)
dailyBudgetMap.set(11,10500)
dailyBudgetMap.set(12,11500)
dailyBudgetMap.set(13,13500)
dailyBudgetMap.set(14,15000)
dailyBudgetMap.set(15,18000)
dailyBudgetMap.set(16,20000)
dailyBudgetMap.set(17,25000)
dailyBudgetMap.set(18,27000)
dailyBudgetMap.set(19,30000)
dailyBudgetMap.set(20,40000)

export { easyMap, mediumMap, hardMap, deadlyMap, dailyBudgetMap}