import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import AlertSuccessStyle from "../components/styles/AlertSuccessStyle";

export  function AlertSuccess()
{
  return (
    <AlertSuccessStyle>
      <Alert severity="success">
        <AlertTitle>Subscribed</AlertTitle>
        Email has been sent!
      </Alert>
    </AlertSuccessStyle>
  );
}

export  function AlertMobileMessage()
{
  return (
    <AlertSuccessStyle>
      <Alert severity="success">
        <AlertTitle>We recommend using a desktop For The Best Expereince </AlertTitle>
          Mobile support currently being crafted
      </Alert>
    </AlertSuccessStyle>
  );
}