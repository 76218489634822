import styled from "@emotion/styled";

const PrivacyStyle = styled.div`
    position: realitve;
    color: white;
    width: 100%;
    height: 100%;
    background: #424242;
    padding-top: 65px;
    display: grid;
    grid-template-rows: 1fr;
    .header_image{
        padding-top: 50px;
        margin: auto;
        text-align: center ;
        background-color: #424242;
    }
    h1 {
        text-align: center;
    }
    .main_text {
        padding-left: 2rem;
        text-align: start;
        width: 100%;
    }
    h3 {
        color: #B0C4DE
    }
`;

export default PrivacyStyle;