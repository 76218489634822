export const monsters = [
  {
    guid: 'd4ceb2aa-9505-443c-b76f-290f6a926df3',
    fid: 'abyss.baphomet',
    name: 'Baphomet',
    cr: 23,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 22,
    hp: 333,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 235'
  },
  {
    guid: '4685b839-bb7b-47d2-8f3e-a31552bd37fc',
    fid: 'abyss.bridesmaid-of-zuggtmoy',
    name: 'Bridesmaid of Zuggtmoy',
    cr: '1/8',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 22,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 230'
  },
  {
    guid: 'dc3ee8c2-b9e6-43e7-b1c2-866dd293223a',
    fid: 'abyss.chamberlain-of-zuggtmoy',
    name: 'Chamberlain of Zuggtmoy',
    cr: 2,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 45,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 230'
  },
  {
    guid: 'eafb811a-c73d-4799-84cf-dd86bd50b79f',
    fid: 'abyss.chuul-spore-servant',
    name: 'Chuul Spore Servant',
    cr: 4,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 16,
    hp: 93,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 228'
  },
  {
    guid: '7346e68c-5d9c-47d2-9e14-d6b6f809adba',
    fid: 'abyss.demogorgon',
    name: 'Demogorgon',
    cr: 26,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 22,
    hp: 496,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 236'
  },
  {
    guid: 'dd01bced-063c-41cb-ba10-1c933b9d9d88',
    fid: 'abyss.derro',
    name: 'Derro',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 13,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 224'
  },
  {
    guid: '',
    fid: 'abyss.derro-savant',
    name: 'Derro Savant',
    cr: 3,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 13,
    hp: 49,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 225'
  },
  {
    guid: 'dadd100d-b2ae-4683-8010-0cab2bbc5377',
    fid: 'abyss.droki',
    name: 'Droki',
    cr: 2,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 31,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Out of the Abyss: 231'
  },
  {
    guid: '62d870d7-71d3-45b8-a129-db41d42b2dd7',
    fid: 'abyss.drow-spore-servant',
    name: 'Drow Spore Servant',
    cr: '1/8',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 229'
  },
  {
    guid: '001aaabc-e829-4e64-8877-7b8db8d205d6',
    fid: 'abyss.duergar-soulblade',
    name: 'Duergar Soulblade',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 14,
    hp: 18,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 227'
  },
  {
    guid: '14123d05-16e0-4045-ba6b-b022bd5845e4',
    fid: 'abyss.duergar-spore-servant',
    name: 'Duergar Spore Servant',
    cr: '1/2',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 16,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 229'
  },
  {
    guid: '2efccc81-8f74-45ff-8ccc-241552884efd',
    fid: 'abyss.duergar-stone-guard',
    name: 'Duergar Stone Guard',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 18,
    hp: 39,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 227'
  },
  {
    guid: '0541ae2c-244b-4a26-92c6-d32df0a581ab',
    fid: 'abyss.duergar-xarrorn',
    name: 'Duergar Xarrorn',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 18,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 228'
  },
  {
    guid: '24206108-08c1-42cd-9a3d-d0223545d586',
    fid: 'abyss.female-steeder',
    name: 'Female Steeder',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 14,
    hp: 30,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 231'
  },
  {
    guid: 'deabbe0c-ec60-4eee-87ed-2e02a5961892',
    fid: 'abyss.fraz-urbluu',
    name: 'Fraz-Urb\'luu',
    cr: 23,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 18,
    hp: 350,
    init: 1,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 238'
  },
  {
    guid: '02e1b0f5-912e-447c-8118-4946a0c57ef4',
    fid: 'abyss.grazzt',
    name: 'Graz\'zt',
    cr: 24,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon, Shapechanger',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 20,
    hp: 378,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 241'
  },
  {
    guid: 'fbd25f01-f510-4790-b778-5e5313ad6a2b',
    fid: 'abyss.grisha',
    name: 'Grisha',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Damaran Human',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 18,
    hp: 33,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Out of the Abyss: 232'
  },
  {
    guid: '39171a85-e224-425b-ac84-19d6996efe0e',
    fid: 'abyss.hook-horror-servant',
    name: 'Hook Horror Servant',
    cr: 3,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 229'
  },
  {
    guid: 'df38742a-fad9-4039-a501-cf5149f2254e',
    fid: 'abyss.ixitxachitl',
    name: 'Ixitxachitl',
    cr: '1/4',
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 18,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 225'
  },
  {
    guid: '67fe91fb-6a04-41ba-9efe-7ebd5bec55b4',
    fid: 'abyss.juiblex',
    name: 'Juiblex',
    cr: 23,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 18,
    hp: 350,
    init: 0,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 243'
  },
  {
    guid: 'd9af029b-c451-4d74-bbf8-8fb25fdd609b',
    fid: 'abyss.male-steeder',
    name: 'Male Steeder',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 12,
    hp: 13,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 231'
  },
  {
    guid: '516ba46d-27c1-4f5b-afc3-a4b216c88d52',
    fid: 'abyss.narrak',
    name: 'Narrak',
    cr: 2,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 12,
    hp: 40,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Out of the Abyss: 232'
  },
  {
    guid: 'd6b88949-50e7-4b5f-b15a-7541485cfa62',
    fid: 'abyss.orcus',
    name: 'Orcus',
    cr: 26,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: '17 (20 with Wand of Orcus)',
    hp: 405,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 245'
  },
  {
    guid: '5772d898-4440-4583-b157-73714db987db',
    fid: 'abyss.the-pudding-king',
    name: 'The Pudding King',
    cr: 4,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Gnome, Shapechanger',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 49,
    init: 4,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Out of the Abyss: 233'
  },
  {
    guid: '00a707e5-c44c-43ad-9568-e576abe476a1',
    fid: 'abyss.troglodyte-champion-of-laogzed',
    name: 'Troglodyte Champion of Laogzed',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Troglodyte',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 14,
    hp: 59,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 229'
  },
  {
    guid: '8ccf2d1c-a9d9-48af-a91b-cceb1cd258cc',
    fid: 'abyss.vampiric-ixitxachitl',
    name: 'Vampiric Ixitxachitl',
    cr: 2,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 16,
    hp: 44,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Out of the Abyss: 226'
  },
  {
    guid: 'd6d31cb2-1b0a-4953-8301-4c16cb51434e',
    fid: 'abyss.yeenoghu',
    name: 'Yeenoghu',
    cr: 24,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 22,
    hp: 348,
    init: 3,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 247'
  },
  {
    guid: 'e49a7f28-3cf5-4778-b316-0b9fed11cca6',
    fid: 'abyss.yestabrod',
    name: 'Yestabrod',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 75,
    init: 0,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 233'
  },
  {
    guid: '14a4af4f-0916-4015-9006-5c71985b07c5',
    fid: 'abyss.zuggtmoy',
    name: 'Zuggtmoy',
    cr: 23,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: '',
    ac: 18,
    hp: 304,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Out of the Abyss: 249'
  },
  {
    guid: '42e798b0-d4ce-48d8-9e2e-eb12b44ec2f9',
    fid: 'apoc.aerisi-kalinoth',
    name: 'Aerisi Kalinoth',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Howling Hatred Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 66,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 192'
  },
  {
    guid: '110da8de-4ec0-4b5e-b531-c8a34d355a4b',
    fid: 'apoc.aerisi-kalinoth-in-lair',
    name: 'Aerisi Kalinoth (in lair)',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Howling Hatred Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 66,
    init: 3,
    lair: 'lair',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 192'
  },
  {
    guid: 'e3d1956e-2243-4e4f-9794-ed71f0160122',
    fid: 'apoc.air-elemental-myrmidon',
    name: 'Air Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elemental Myrmidons',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 18,
    hp: 117,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 212'
  },
  {
    guid: '952018b8-9f08-4f18-827e-2dcd7e5e367a',
    fid: 'apoc.bastian-thermandar',
    name: 'Bastian Thermandar',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Fire genasi',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 12,
    hp: 78,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 201'
  },
  {
    guid: 'e677e29f-eba3-44ae-ae6e-47c5141291e1',
    fid: 'apoc.black-earth-guard',
    name: 'Black Earth Guard',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 18,
    hp: 39,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 195'
  },
  {
    guid: '3a6fb726-4468-49fe-b079-1ca3daf8794d',
    fid: 'apoc.black-earth-priest',
    name: 'Black Earth Priest',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 17,
    hp: 45,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 195'
  },
  {
    guid: '6511413e-afd9-425d-a2d8-13c245bfff9a',
    fid: 'apoc.burrow-shark',
    name: 'Burrow Shark',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 18,
    hp: 82,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 196'
  },
  {
    guid: '10a656be-585c-4e2d-89fc-a44d742c0650',
    fid: 'apoc.crushing-wave-priest',
    name: 'Crushing Wave Priest',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'neutral evil',
    environment: 'aquatic, cave, coast, grassland, swamp, underground',
    ac: 13,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 205'
  },
  {
    guid: '0d17db23-30dd-4e07-9b8f-1ea31879a5f4',
    fid: 'apoc.crushing-wave-reaver',
    name: 'Crushing Wave Reaver',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'neutral evil',
    environment: 'aquatic, cave, coast, grassland, swamp, underground',
    ac: 14,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 205'
  },
  {
    guid: 'f276978d-37ac-4acd-9559-52d3281365c6',
    fid: 'apoc.dark-tide-knight',
    name: 'Dark Tide Knight',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'lawful evil',
    environment: 'aquatic, cave, coast, grassland, swamp, underground',
    ac: 13,
    hp: 58,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 205'
  },
  {
    guid: 'c0ac9048-9d40-418a-b3b1-cdc58843e1cb',
    fid: 'apoc.drannin-splithelm',
    name: 'Drannin Splithelm',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Shield dwarf',
    section: 'Other Villains',
    alignment: 'neutral evil',
    environment: '',
    ac: 18,
    hp: 93,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 210'
  },
  {
    guid: 'f8ae5941-9f22-4089-a9ea-43c0163771c1',
    fid: 'apoc.earth-elemental-myrmidon',
    name: 'Earth Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elemental Myrmidons',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 18,
    hp: 127,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 212'
  },
  {
    guid: '19bb278f-988d-4c48-9fde-10e4ff357c19',
    fid: 'apoc.elizar-dryflagon',
    name: 'Elizar Dryflagon',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 14,
    hp: 71,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 202'
  },
  {
    guid: '11506c53-58eb-4732-a877-570ed1fb4408',
    fid: 'apoc.eternal-flame-guardian',
    name: 'Eternal Flame Guardian',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 17,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 200'
  },
  {
    guid: '81ff5c00-72f3-4225-96a4-0453a57aff9c',
    fid: 'apoc.eternal-flame-priest',
    name: 'Eternal Flame Priest',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Eternal Flame Cultists',
    alignment: 'neutral evil',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 12,
    hp: 52,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 200'
  },
  {
    guid: '151a7399-6612-4424-8a67-da8419f2af1d',
    fid: 'apoc.fathomer',
    name: 'Fathomer',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'neutral evil',
    environment: 'aquatic, cave, coast, grassland, swamp, underground',
    ac: 10,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 207'
  },
  {
    guid: 'feadfec7-96a7-4ff2-8b22-6f6ab232d6d5',
    fid: 'apoc.feathergale-knight',
    name: 'Feathergale knight',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'lawful evil',
    environment: 'arctic, cave, coast, desert, dungeon, grassland, mountain, ruins',
    ac: 16,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 189'
  },
  {
    guid: 'a52b610e-729b-42bd-b124-62835ed4932b',
    fid: 'apoc.fire-elemental-myrmidon',
    name: 'Fire Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elemental Myrmidons',
    alignment: 'neutral',
    environment: 'cave, coast, desert, dungeon, grassland, mountain, planar, ruins, swamp, underground, urban',
    ac: 18,
    hp: 117,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 213'
  },
  {
    guid: '530d4e60-7c5f-4d84-aff8-608a033ec85f',
    fid: 'apoc.flamewrath',
    name: 'Flamewrath',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 12,
    hp: 105,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 201'
  },
  {
    guid: '77336909-5839-48c4-954d-3eaf416dc729',
    fid: 'apoc.gar-shatterkeel',
    name: 'Gar Shatterkeel',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 16,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 208'
  },
  {
    guid: '9b6c6412-5a3d-4ff1-8151-376eac201182',
    fid: 'apoc.gar-shatterkeel-in-lair',
    name: 'Gar Shatterkeel (in lair)',
    cr: 13,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 16,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 208'
  },
  {
    guid: '788ad772-47f1-442f-a7f8-74988b191b11',
    fid: 'apoc.ghald',
    name: 'Ghald',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Sahuagin',
    section: 'Other Villains',
    alignment: 'lawful evil',
    environment: '',
    ac: 15,
    hp: 102,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 210'
  },
  {
    guid: '808b9d5b-9de0-4692-88b3-c3c589cd04c2',
    fid: 'apoc.hellenrae',
    name: 'Hellenrae',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 16,
    hp: 78,
    init: 4,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 198'
  },
  {
    guid: '0ba96911-ae8f-4a67-ab56-e206b404a16c',
    fid: 'apoc.howling-hatred-initiate',
    name: 'Howling Hatred Initiate',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, grassland, mountain, ruins',
    ac: 13,
    hp: 9,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 190'
  },
  {
    guid: 'd54be24f-498d-41b6-9947-fe0d3eab8bda',
    fid: 'apoc.howling-hatred-priest',
    name: 'Howling Hatred Priest',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, grassland, mountain, ruins',
    ac: 15,
    hp: 45,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 190'
  },
  {
    guid: '64954fd2-b46e-4c35-95b4-4c6ff153fb41',
    fid: 'apoc.hurricane',
    name: 'Hurricane',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'lawful evil',
    environment: 'arctic, cave, coast, desert, dungeon, grassland, mountain, ruins',
    ac: 14,
    hp: 33,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 191'
  },
  {
    guid: '83b24acd-ca3b-416c-b66e-aa750c33581f',
    fid: 'apoc.imix',
    name: 'Imix',
    cr: 19,
    size: 'Huge',
    type: 'Elemental',
    tags: '',
    section: 'Princes of Elemental Evil',
    alignment: 'neutral evil',
    environment: '',
    ac: 17,
    hp: 325,
    init: 7,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 214'
  },
  {
    guid: 'd4f4981c-f46b-4a18-a9c0-62bda3aee9b0',
    fid: 'apoc.marlos-urnrayle',
    name: 'Marlos Urnrayle',
    cr: 8,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 136,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 199'
  },
  {
    guid: '21d312b3-de51-492a-966c-235c76960ff2',
    fid: 'apoc.marlos-urnrayle-in-lair',
    name: 'Marlos Urnrayle (in lair)',
    cr: 12,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 136,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 199'
  },
  {
    guid: '474f3c52-7a41-4ffa-a882-42fa94b83fda',
    fid: 'apoc.miraj-vizann',
    name: 'Miraj Vizann',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Earth genasi',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 10,
    hp: 82,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 198'
  },
  {
    guid: 'b7d4b220-33f9-4326-beaa-a0935acc4f64',
    fid: 'apoc.ogrmoch',
    name: 'OgrÃ©moch',
    cr: 20,
    size: 'Huge',
    type: 'Elemental',
    tags: '',
    section: 'Princes of Elemental Evil',
    alignment: 'neutral evil',
    environment: '',
    ac: 20,
    hp: 526,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 216'
  },
  {
    guid: '6cea5f48-7326-4c1e-bbeb-b117997c4d95',
    fid: 'apoc.olhydra',
    name: 'Olhydra',
    cr: 18,
    size: 'Huge',
    type: 'Elemental',
    tags: '',
    section: 'Princes of Elemental Evil',
    alignment: 'neutral evil',
    environment: '',
    ac: 18,
    hp: 324,
    init: 6,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 218'
  },
  {
    guid: 'eb3c332a-e5c8-4a50-a212-bc6041ceec4d',
    fid: 'apoc.one-eyed-shiver',
    name: 'One-Eyed Shiver',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Crushing Wave Cultists',
    alignment: 'chaotic evil',
    environment: 'aquatic, cave, coast, grassland, swamp, underground',
    ac: 12,
    hp: 49,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 207'
  },
  {
    guid: 'e1c70697-6681-42a7-9289-dcc8d441d422',
    fid: 'apoc.oreioth',
    name: 'Oreioth',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Other Villains',
    alignment: 'chaotic evil',
    environment: '',
    ac: 11,
    hp: 39,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 212'
  },
  {
    guid: 'f71b3427-cb38-4e49-afab-1e23f67c64a2',
    fid: 'apoc.razerblast',
    name: 'Razerblast',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 17,
    hp: 112,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 201'
  },
  {
    guid: '7bfeb1e6-6ad4-4e1d-9565-993782b70f68',
    fid: 'apoc.sacred-stone-monk',
    name: 'Sacred Stone Monk',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'lawful evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 14,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 196'
  },
  {
    guid: '9653c256-e429-491a-82a4-14a7d35ccc7d',
    fid: 'apoc.shoalar-quanderil',
    name: 'Shoalar Quanderil',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Water genasi',
    section: 'Crushing Wave Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 10,
    hp: 60,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 208'
  },
  {
    guid: 'a04fcf2a-bcce-4bce-b0d1-d8421034ef57',
    fid: 'apoc.skyweaver',
    name: 'Skyweaver',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, grassland, mountain, ruins',
    ac: 12,
    hp: 44,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 191'
  },
  {
    guid: '8468bebf-a893-41c9-9a52-63097a71535b',
    fid: 'apoc.stonemelder',
    name: 'Stonemelder',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Black Earth Cultists',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, underground',
    ac: 17,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 197'
  },
  {
    guid: 'b556c163-d83e-480e-b43c-20719bc337aa',
    fid: 'apoc.thurl-merosska',
    name: 'Thurl Merosska',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Howling Hatred Cultists',
    alignment: 'lawful evil',
    environment: '',
    ac: 16,
    hp: 71,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 192'
  },
  {
    guid: 'b2def732-06fb-483d-b0bd-41977e9726a4',
    fid: 'apoc.vanifer',
    name: 'Vanifer',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tiefling',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 112,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 203'
  },
  {
    guid: '8e7bb425-8d3b-4051-8dc8-40fd31a79f12',
    fid: 'apoc.vanifer-in-lair',
    name: 'Vanifer (in lair)',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tiefling',
    section: 'Eternal Flame Cultists',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 112,
    init: 3,
    lair: 'lair',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 203'
  },
  {
    guid: 'c7d911b4-2ae2-4314-949e-df469804c176',
    fid: 'apoc.water-elemental-myrmidon',
    name: 'Water Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elemental Myrmidons',
    alignment: 'neutral',
    environment: 'aquatic, arctic, cave, coast, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 18,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Princes of the Apocalypse: 213'
  },
  {
    guid: '489ae12c-5439-4bc0-8f7c-ab354a7bf3f3',
    fid: 'apoc.wiggan-nettlebee',
    name: 'Wiggan Nettlebee',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Halfling',
    section: 'Other Villains',
    alignment: 'neutral evil',
    environment: '',
    ac: 11,
    hp: 36,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 212'
  },
  {
    guid: '58a87589-0a1c-4d4c-9874-e61d7bbff92b',
    fid: 'apoc.windharrow',
    name: 'Windharrow',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-elf',
    section: 'Howling Hatred Cultists',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 55,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 192'
  },
  {
    guid: '1dd55750-d131-4b9b-998b-d1ce9833e82b',
    fid: 'apoc.yan-c-bin',
    name: 'Yan-C-Bin',
    cr: 18,
    size: 'Huge',
    type: 'Elemental',
    tags: '',
    section: 'Princes of Elemental Evil',
    alignment: 'neutral evil',
    environment: '',
    ac: 22,
    hp: 283,
    init: 7,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Princes of the Apocalypse: 221'
  },
  {
    guid: 'f495d63f-f936-4985-a2d3-6a8ec2a20fb0',
    fid: 'hoard.ambush-drake',
    name: 'Ambush Drake',
    cr: '1/2',
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, mountain, ruins, swamp, urban',
    ac: 13,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Hoard of the Dragon Queen: 88'
  },
  {
    guid: '9e85c13d-ef1d-4baf-a01e-d8f59d8936ef',
    fid: 'hoard.azbara-jos',
    name: 'Azbara Jos',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 13,
    hp: 39,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 88'
  },
  {
    guid: 'cd3d6e12-28d4-4f1b-a0f2-97a932f932e3',
    fid: 'hoard.blagothkus',
    name: 'Blagothkus',
    cr: 9,
    size: 'Huge',
    type: 'Giant',
    tags: 'Cloud giant',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 17,
    hp: 138,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 89'
  },
  {
    guid: 'a3ac6397-dfed-4461-9f0b-d63a29ea429d',
    fid: 'hoard.captain-othelstan',
    name: 'Captain Othelstan',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 19,
    hp: 93,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 89'
  },
  {
    guid: 'ddb767bf-4484-4d5e-b54d-b03a6f9c6795',
    fid: 'hoard.dragonclaw',
    name: 'Dragonclaw',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, urban',
    ac: 14,
    hp: 16,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Hoard of the Dragon Queen: 89, Rise of Tiamat: 89'
  },
  {
    guid: '3bb548b6-e049-4355-84cb-47bc1d94620a',
    fid: 'hoard.dragonwing',
    name: 'Dragonwing',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, urban',
    ac: 14,
    hp: 32,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Hoard of the Dragon Queen: 89'
  },
  {
    guid: 'c48931c8-30ce-4dca-bd44-367e0dac5296',
    fid: 'hoard.dralmorrer-borngray',
    name: 'Dralmorrer Borngray',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'High-elf',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 16,
    hp: 52,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 90'
  },
  {
    guid: '70e1d732-ce01-4f48-b88b-1a45e1cd77df',
    fid: 'hoard.frulam-mondath',
    name: 'Frulam Mondath',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 16,
    hp: 44,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 90'
  },
  {
    guid: 'a76fc0b1-2c1f-4847-aa54-fa889c212c3f',
    fid: 'hoard.jamna-gleamsilver',
    name: 'Jamna Gleamsilver',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Gnome',
    section: '',
    alignment: 'neutral',
    environment: '',
    ac: 15,
    hp: 22,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 91'
  },
  {
    guid: '708b2570-c88f-4e4f-a414-a98088faa52c',
    fid: 'hoard.langdedrosa-cynwrath',
    name: 'Langdedrosa Cyanwrath',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-Dragon',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 17,
    hp: 57,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 91'
  },
  {
    guid: '5210357f-f874-4807-bbf6-c203d6431ab4',
    fid: 'hoard.pharblex-spattergoo',
    name: 'Pharblex Spattergoo',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Bullywug',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 59,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 91'
  },
  {
    guid: '8db8b7f3-375c-4e14-abd5-f26e6622f330',
    fid: 'hoard.rath-modar',
    name: 'Rath Modar',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 13,
    hp: 71,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 92, Rise of Tiamat: 91'
  },
  {
    guid: '84df86ca-cc90-4080-a65c-f99314bb351d',
    fid: 'hoard.rezmir',
    name: 'Rezmir',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-black dragon',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 90,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 93'
  },
  {
    guid: 'bf42ec5e-94ff-4595-a36e-e7a5dba0d822',
    fid: 'hoard.talis-the-white',
    name: 'Talis the White',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-elf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 18,
    hp: 58,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Hoard of the Dragon Queen: 93'
  },
  {
    guid: '0cd9a2e0-16bc-4c84-86c8-feb035c0b5d6',
    fid: 'mm.aarakocra',
    name: 'Aarakocra',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Aarakocra',
    section: '',
    alignment: 'neutral good',
    environment: 'mountain, planar',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 12, Princes of the Apocalypse Online Supplement v1.0: 6'
  },
  {
    guid: '43385a8d-759b-4f44-a712-d46069b8dd88',
    fid: 'mm.aboleth',
    name: 'Aboleth',
    cr: 10,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'aquatic, coast',
    ac: 17,
    hp: 135,
    init: -1,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 13, Princes of the Apocalypse Online Supplement v1.0: 6'
  },
  {
    guid: '52386d70-24c8-461a-ba1f-4fbaccc6d446',
    fid: 'mm.abominable-yeti',
    name: 'Abominable Yeti',
    cr: 9,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: 'Yeti',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, mountain',
    ac: 15,
    hp: 137,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 306'
  },
  {
    guid: 'f88906c2-146d-4abb-8537-8bf5e1d0ac97',
    fid: 'mm.acolyte',
    name: 'Acolyte',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, desert, dungeon, forest, grassland, mountain, swamp, underground, urban',
    ac: 10,
    hp: 9,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 53, HotDQ supplement: 4, Monster Manual: 342'
  },
  {
    guid: '925ae71b-869a-4067-aed6-803e50dc3e41',
    fid: 'mm.adult-black-dragon',
    name: 'Adult Black Dragon',
    cr: 14,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'swamp',
    ac: 19,
    hp: 195,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 88, Princes of the Apocalypse Online Supplement v1.0: 7'
  },
  {
    guid: '437ddd59-2aa8-489f-bc2e-e9845a935947',
    fid: 'mm.adult-blue-dracolich',
    name: 'Adult Blue Dracolich',
    cr: 17,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: 'Dracolich',
    alignment: 'lawful evil',
    environment: 'desert, dungeon',
    ac: 19,
    hp: 225,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 84'
  },
  {
    guid: '0a70230e-18d8-49e3-898a-49e740acb4a2',
    fid: 'mm.adult-blue-dragon',
    name: 'Adult Blue Dragon',
    cr: 16,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'desert',
    ac: 19,
    hp: 225,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'HotDQ supplement: 4, Monster Manual: 91'
  },
  {
    guid: '02616673-2113-4e70-82fc-729b766d3c58',
    fid: 'mm.adult-brass-dragon',
    name: 'Adult Brass Dragon',
    cr: 13,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, underground',
    ac: 18,
    hp: 172,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 105'
  },
  {
    guid: 'ac41ad48-6631-413e-8e68-6e29696c8f35',
    fid: 'mm.adult-bronze-dragon',
    name: 'Adult Bronze Dragon',
    cr: 15,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'coast',
    ac: 19,
    hp: 212,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 108, Princes of the Apocalypse Online Supplement v1.0: 7'
  },
  {
    guid: '43984f24-324e-4656-ae7a-aeaaa14d74be',
    fid: 'mm.adult-copper-dragon',
    name: 'Adult Copper Dragon',
    cr: 14,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, desert, grassland',
    ac: 18,
    hp: 184,
    init: 1,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 111'
  },
  {
    guid: '99872957-920d-4007-a66b-0f5922ba584d',
    fid: 'mm.adult-gold-dragon',
    name: 'Adult Gold Dragon',
    cr: 17,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'mountain, ruins',
    ac: 19,
    hp: 256,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 114'
  },
  {
    guid: '1182dfb4-3b70-4ce0-a41c-c5ff1ca6ad21',
    fid: 'mm.adult-green-dragon',
    name: 'Adult Green Dragon',
    cr: 15,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 19,
    hp: 207,
    init: 1,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 94'
  },
  {
    guid: 'a27291de-91c5-4b8e-9ffe-5055e90cc6cd',
    fid: 'mm.adult-red-dragon',
    name: 'Adult Red Dragon',
    cr: 17,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 19,
    hp: 256,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Basic Rules v1: 8, Monster Manual: 98'
  },
  {
    guid: '4002e3ba-63b1-48a8-87d1-044a319cb02d',
    fid: 'mm.adult-silver-dragon',
    name: 'Adult Silver Dragon',
    cr: 16,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'arctic, mountain',
    ac: 19,
    hp: 243,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 117'
  },
  {
    guid: '4218c74b-27c2-45be-abde-e23ad4223061',
    fid: 'mm.adult-white-dragon',
    name: 'Adult White Dragon',
    cr: 13,
    size: 'Huge',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, underground',
    ac: 18,
    hp: 200,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'HotDQ supplement: 5, Monster Manual: 101'
  },
  {
    guid: '071f1203-f6e6-4fcf-928c-b9cdeb256b2b',
    fid: 'mm.air-elemental',
    name: 'Air Elemental',
    cr: 5,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Elementals',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 15,
    hp: 90,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 9, HotDQ supplement: 5, Monster Manual: 124'
  },
  {
    guid: '4dfdcf3d-5bdc-4d4d-a55c-6a9149d97079',
    fid: 'mm.allosaurus',
    name: 'Allosaurus',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'coast, grassland, swamp',
    ac: 13,
    hp: 51,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 9, Monster Manual: 79'
  },
  {
    guid: '19a2039e-4cc1-4181-96d2-5f33d5d75764',
    fid: 'mm.ancient-black-dragon',
    name: 'Ancient Black Dragon',
    cr: 21,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'swamp',
    ac: 22,
    hp: 367,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 87'
  },
  {
    guid: '3e861d52-a964-4c84-8ffc-44a814e3070b',
    fid: 'mm.ancient-blue-dragon',
    name: 'Ancient Blue Dragon',
    cr: 23,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'desert',
    ac: 22,
    hp: 481,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 90'
  },
  {
    guid: '75ff17b1-4f33-49ca-ae07-80088c028ea5',
    fid: 'mm.ancient-brass-dragon',
    name: 'Ancient Brass Dragon',
    cr: 20,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, underground',
    ac: 20,
    hp: 297,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 104'
  },
  {
    guid: '49924bb6-804f-4150-870b-b14b85716129',
    fid: 'mm.ancient-bronze-dragon',
    name: 'Ancient Bronze Dragon',
    cr: 22,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'coast',
    ac: 22,
    hp: 444,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 107'
  },
  {
    guid: 'e06dae63-02d4-41c4-b910-0831ca111514',
    fid: 'mm.ancient-copper-dragon',
    name: 'Ancient Copper Dragon',
    cr: 21,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, desert, grassland',
    ac: 21,
    hp: 350,
    init: 1,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 110'
  },
  {
    guid: 'd830724d-8d58-4464-af65-3befceb878bf',
    fid: 'mm.ancient-gold-dragon',
    name: 'Ancient Gold Dragon',
    cr: 24,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'mountain, ruins',
    ac: 22,
    hp: 546,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 113'
  },
  {
    guid: '67f50578-6e21-4e73-9d47-5a34d5d340c4',
    fid: 'mm.ancient-green-dragon',
    name: 'Ancient Green Dragon',
    cr: 22,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 21,
    hp: 385,
    init: 1,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 93'
  },
  {
    guid: 'fa6112d9-803c-4c9e-b995-41cb04b8872d',
    fid: 'mm.ancient-red-dragon',
    name: 'Ancient Red Dragon',
    cr: 24,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 22,
    hp: 546,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 97'
  },
  {
    guid: '867fd8db-72d8-4bc7-8d8a-272afe0c42c6',
    fid: 'mm.ancient-silver-dragon',
    name: 'Ancient Silver Dragon',
    cr: 23,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'arctic, mountain',
    ac: 22,
    hp: 487,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 116'
  },
  {
    guid: '44702dbb-036c-4eee-923e-565cf17e77ac',
    fid: 'mm.ancient-white-dragon',
    name: 'Ancient White Dragon',
    cr: 20,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, underground',
    ac: 20,
    hp: 333,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 100'
  },
  {
    guid: 'f0faeb6f-9112-467a-bf48-b4fb5e2f4b26',
    fid: 'mm.androsphinx',
    name: 'Androsphinx',
    cr: 17,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Sphinxes',
    alignment: 'lawful neutral',
    environment: 'dungeon, ruins',
    ac: 17,
    hp: 199,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 281'
  },
  {
    guid: '3c5a63ab-b94f-4451-aa2a-02545b381797',
    fid: 'mm.animated-armor',
    name: 'Animated Armor',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Animated Objects',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 18,
    hp: 33,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 9, Monster Manual: 19'
  },
  {
    guid: '789c33ef-c5f6-484d-8bfe-5c520e7d3f82',
    fid: 'mm.ankheg',
    name: 'Ankheg',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'coast, forest, grassland, underground',
    ac: '14, 11 while prone',
    hp: 39,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 21, Princes of the Apocalypse Online Supplement v1.0: 8'
  },
  {
    guid: '935a62a6-3538-4ea8-a06c-adddef096e56',
    fid: 'mm.ankylosaurus',
    name: 'Ankylosaurus',
    cr: 3,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 15,
    hp: 68,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 10, Monster Manual: 79'
  },
  {
    guid: '0788c447-ada3-4bc2-aa38-bc9e08b186d4',
    fid: 'mm.ape',
    name: 'Ape',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 12,
    hp: 19,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 10, Monster Manual: 317'
  },
  {
    guid: 'f9ee20cb-0dff-4585-8f5f-95141f86f9dc',
    fid: 'mm.arcanaloth',
    name: 'Arcanaloth',
    cr: 12,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'dungeon, planar',
    ac: 17,
    hp: 104,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 313'
  },
  {
    guid: 'd4aec3c5-0b0a-4fff-b77b-fe7c900d7d31',
    fid: 'mm.archmage',
    name: 'Archmage',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'ruins, urban',
    ac: '12 (15 with mage armor)',
    hp: 99,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 342'
  },
  {
    guid: 'f42ac24e-7196-4ba4-ae4a-3e34a744d3de',
    fid: 'mm.assassin',
    name: 'Assassin',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-good',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 15,
    hp: 78,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 6, Monster Manual: 343, Princes of the Apocalypse Online Supplement v1.0: 8'
  },
  {
    guid: '0966d112-1f88-45cc-b018-c03103c2b3a0',
    fid: 'mm.awakened-shrub',
    name: 'Awakened Shrub',
    cr: 0,
    size: 'Small',
    type: 'Plant',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 9,
    hp: 10,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 10, Monster Manual: 317'
  },
  {
    guid: '98e6eb4b-4998-4a6c-9366-09a82b0c3028',
    fid: 'mm.awakened-tree',
    name: 'Awakened Tree',
    cr: 2,
    size: 'Huge',
    type: 'Plant',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 13,
    hp: 59,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 10, Monster Manual: 317'
  },
  {
    guid: '62b74702-7ffa-4827-83dc-b97dec26690a',
    fid: 'mm.axe-beak',
    name: 'Axe Beak',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 11,
    hp: 19,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 11, Monster Manual: 317'
  },
  {
    guid: '1180c9bd-d7e3-48d7-8087-79a936ab01d3',
    fid: 'mm.azer',
    name: 'Azer',
    cr: 2,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'lawful neutral',
    environment: 'planar, underground',
    ac: 17,
    hp: 39,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 22, Princes of the Apocalypse Online Supplement v1.0: 9'
  },
  {
    guid: 'f3d4a9b6-622b-4c6c-b809-df565839699c',
    fid: 'mm.baboon',
    name: 'Baboon',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 12,
    hp: 3,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 11, Monster Manual: 318'
  },
  {
    guid: 'e4ca5f7e-4218-4083-a8e0-009b24bd66cb',
    fid: 'mm.badger',
    name: 'Badger',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 10,
    hp: 3,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 11, Monster Manual: 318'
  },
  {
    guid: 'e99e9430-d387-4c3f-8168-cfd6257174a1',
    fid: 'mm.balor',
    name: 'Balor',
    cr: 19,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 19,
    hp: 262,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 55'
  },
  {
    guid: 'd9d4ae84-bf86-4b94-9806-1b32f5cc69aa',
    fid: 'mm.bandit',
    name: 'Bandit',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-lawful',
    environment: 'arctic, coast, desert, forest, grassland, mountain, ruins, urban',
    ac: 12,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 53, HotDQ supplement: 6, Monster Manual: 343'
  },
  {
    guid: 'f0109337-c855-4795-946c-93c92d97df29',
    fid: 'mm.bandit-captain',
    name: 'Bandit Captain',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-lawful',
    environment: 'arctic, coast, desert, forest, grassland, mountain, ruins, urban',
    ac: 15,
    hp: 65,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 344, Princes of the Apocalypse Online Supplement v1.0: 9'
  },
  {
    guid: '59a79db3-22c9-457f-bcb0-990013e4057a',
    fid: 'mm.banshee',
    name: 'Banshee',
    cr: 4,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, ruins, swamp',
    ac: 12,
    hp: 58,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 11, Monster Manual: 23'
  },
  {
    guid: 'f9fe17c3-2688-4434-98fc-1321b8e1d48d',
    fid: 'mm.barbed-devil',
    name: 'Barbed Devil',
    cr: 5,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 15,
    hp: 110,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 70'
  },
  {
    guid: 'b8148f95-9e1c-45b9-aabf-78d11ad0ee25',
    fid: 'mm.barlgura',
    name: 'Barlgura',
    cr: 5,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 15,
    hp: 68,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 56, Princes of the Apocalypse Online Supplement v1.0: 9'
  },
  {
    guid: 'fca1066d-9c2c-4745-972c-a4e19376bab7',
    fid: 'mm.basilisk',
    name: 'Basilisk',
    cr: 3,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground',
    ac: 15,
    hp: 52,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 12, Monster Manual: 24'
  },
  {
    guid: 'afee8041-1219-4d31-931d-3c3a08b0b449',
    fid: 'mm.bat',
    name: 'Bat',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, forest, mountain, underground, urban',
    ac: 12,
    hp: 1,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 12, Monster Manual: 318, Player\'s Handbook: 304'
  },
  {
    guid: 'f32f1817-8310-41f0-8a83-0d39a5d464c3',
    fid: 'mm.bearded-devil',
    name: 'Bearded Devil',
    cr: 3,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 13,
    hp: 52,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 70'
  },
  {
    guid: '05a1b5f9-d2de-44ba-b292-b7b63fca6826',
    fid: 'mm.behir',
    name: 'Behir',
    cr: 11,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'cave, mountain, underground',
    ac: 17,
    hp: 168,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 25'
  },
  {
    guid: 'a97f037f-85d9-47a4-8eae-814eb5176810',
    fid: 'mm.beholder',
    name: 'Beholder',
    cr: 13,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, underground',
    ac: 18,
    hp: 180,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 28'
  },
  {
    guid: '095cd054-da20-44b7-a511-b22c42a5b201',
    fid: 'mm.beholder-in-lair',
    name: 'Beholder (in lair)',
    cr: 14,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, underground',
    ac: 18,
    hp: 180,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 28'
  },
  {
    guid: '81191c85-cef0-4782-9e4e-70accdae757e',
    fid: 'mm.beholder-zombie',
    name: 'Beholder Zombie',
    cr: 5,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, underground',
    ac: 15,
    hp: 93,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 316'
  },
  {
    guid: '8f6ae154-74f1-4ae2-b165-54f109fa2733',
    fid: 'mm.berserker',
    name: 'Berserker',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any chaotic',
    environment: 'arctic, cave, desert, forest, grassland, mountain, swamp, urban',
    ac: 13,
    hp: 67,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 53, HotDQ supplement: 6, Monster Manual: 344'
  },
  {
    guid: 'a233bc77-4107-456a-89d2-9d91b4ada1a2',
    fid: 'mm.black-bear',
    name: 'Black Bear',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, forest, grassland, mountain',
    ac: 11,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 12, Monster Manual: 318, Player\'s Handbook: 304'
  },
  {
    guid: 'db95bd5e-565b-44fa-8bdb-0732ce4d3b67',
    fid: 'mm.black-dragon-wyrmling',
    name: 'Black Dragon Wyrmling',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'swamp',
    ac: 17,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 88'
  },
  {
    guid: 'e5612386-a501-4f25-afd2-8cd9977ec4af',
    fid: 'mm.black-pudding',
    name: 'Black Pudding',
    cr: 4,
    size: 'Large',
    type: 'Ooze',
    tags: '',
    section: 'Oozes',
    alignment: 'unaligned',
    environment: 'dungeon',
    ac: 7,
    hp: 85,
    init: -3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 241, Princes of the Apocalypse Online Supplement v1.0: 10'
  },
  {
    guid: '92452019-5b58-4f07-acdc-89be2ab34c2f',
    fid: 'mm.blink-dog',
    name: 'Blink Dog',
    cr: '1/4',
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'lawful good',
    environment: 'forest, grassland',
    ac: 13,
    hp: 22,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 12, Monster Manual: 318'
  },
  {
    guid: '2fd54197-cb28-4dcd-b0b3-adfc90de53f6',
    fid: 'mm.blood-hawk',
    name: 'Blood Hawk',
    cr: '1/8',
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'coast, grassland, mountain',
    ac: 12,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 13, Monster Manual: 319'
  },
  {
    guid: '15c821b4-abed-42f4-bbcd-2035edec5aad',
    fid: 'mm.blue-dragon-wyrmling',
    name: 'Blue Dragon Wyrmling',
    cr: 3,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'desert',
    ac: 17,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 91'
  },
  {
    guid: '36879d12-0c10-4f48-beff-bc39984968c7',
    fid: 'mm.blue-slaad',
    name: 'Blue Slaad',
    cr: 7,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Slaadi',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 15,
    hp: 123,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 276'
  },
  {
    guid: '14ef11ed-2340-46de-a4eb-e9932f839f5d',
    fid: 'mm.boar',
    name: 'Boar',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 11,
    hp: 11,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 13, Monster Manual: 319, Player\'s Handbook: 304'
  },
  {
    guid: 'd6d8860b-6c94-4056-b02d-d4161206d24c',
    fid: 'mm.bone-devil',
    name: 'Bone Devil',
    cr: 9,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 19,
    hp: 142,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 71'
  },
  {
    guid: 'dd409232-47c5-4cc2-adcf-c13ef6b3b489',
    fid: 'mm.bone-naga',
    name: 'Bone Naga',
    cr: 4,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Nagas',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins',
    ac: 15,
    hp: 58,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 233'
  },
  {
    guid: '03e32c50-a660-4314-b701-393153a80fcd',
    fid: 'mm.brass-dragon-wyrmling',
    name: 'Brass Dragon Wyrmling',
    cr: 1,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, underground',
    ac: 16,
    hp: 16,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 106'
  },
  {
    guid: '1fac16f9-7b5f-4d8f-85b9-fcecacd16d2a',
    fid: 'mm.bronze-dragon-wyrmling',
    name: 'Bronze Dragon Wyrmling',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'coast',
    ac: 17,
    hp: 32,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 109'
  },
  {
    guid: '197d96a0-a8c4-48be-af3d-6174ac281afb',
    fid: 'mm.brown-bear',
    name: 'Brown Bear',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, forest',
    ac: 11,
    hp: 34,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 13, Monster Manual: 319, Player\'s Handbook: 304'
  },
  {
    guid: '2dad5420-04bb-4921-9857-34700a52f413',
    fid: 'mm.bugbear',
    name: 'Bugbear',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Bugbears',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, forest, mountain, ruins, urban',
    ac: 16,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 13, Monster Manual: 33'
  },
  {
    guid: 'bab41d17-f80f-4b5f-abf9-792b03bb98ef',
    fid: 'mm.bugbear-chief',
    name: 'Bugbear Chief',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Bugbears',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, forest, mountain, ruins, urban',
    ac: 17,
    hp: 65,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 33'
  },
  {
    guid: '6b6b6245-cc31-4950-8ec4-91e06b56a282',
    fid: 'mm.bulette',
    name: 'Bulette',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'cave, desert, grassland, mountain, underground',
    ac: 17,
    hp: 94,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 34, Princes of the Apocalypse Online Supplement v1.0: 10'
  },
  {
    guid: 'c8328817-b3e9-4c24-984b-f278678bc3a4',
    fid: 'mm.bullywug',
    name: 'Bullywug',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Bullywug',
    section: '',
    alignment: 'neutral evil',
    environment: 'coast, swamp',
    ac: 15,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 7, Monster Manual: 35'
  },
  {
    guid: '1f3ad1ff-6606-4f75-ae2a-02293de88198',
    fid: 'mm.cambion',
    name: 'Cambion',
    cr: 5,
    size: 'Medium',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'any evil',
    environment: 'dungeon, planar, urban',
    ac: 19,
    hp: 82,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 36'
  },
  {
    guid: '67f7d07c-5002-4768-9586-3ed988bec1e9',
    fid: 'mm.camel',
    name: 'Camel',
    cr: '1/8',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'desert',
    ac: 9,
    hp: 15,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 14, Monster Manual: 320'
  },
  {
    guid: '9c9180b5-4e30-424b-9823-a8375131ffe7',
    fid: 'mm.carrion-crawler',
    name: 'Carrion Crawler',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'cave, dungeon, underground',
    ac: 13,
    hp: 51,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 37'
  },
  {
    guid: '8db064e4-cca6-4757-b558-a7f122ddf06f',
    fid: 'mm.cat',
    name: 'Cat',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 12,
    hp: 2,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 14, Monster Manual: 320, Player\'s Handbook: 305'
  },
  {
    guid: 'a9212953-cb55-4b5d-80ff-c3703fc5cb0e',
    fid: 'mm.centaur',
    name: 'Centaur',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral good',
    environment: 'coast, desert, grassland',
    ac: 12,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 14, Monster Manual: 38'
  },
  {
    guid: '9c4a2dce-bc31-4644-88d1-a590d19c8b47',
    fid: 'mm.chain-devil',
    name: 'Chain Devil',
    cr: 8,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 16,
    hp: 85,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 72'
  },
  {
    guid: 'b2d9646e-cd3b-4045-803f-aedf158216a2',
    fid: 'mm.chasme',
    name: 'Chasme',
    cr: 6,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 15,
    hp: 84,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 57'
  },
  {
    guid: 'a11e72bd-d81b-4826-901d-d2101c287b1e',
    fid: 'mm.chimera',
    name: 'Chimera',
    cr: 6,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 14,
    hp: 114,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 14, Monster Manual: 39'
  },
  {
    guid: 'c20e65d5-8e5b-4f3b-845e-fb12a8342260',
    fid: 'mm.chuul',
    name: 'Chuul',
    cr: 4,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'aquatic, coast',
    ac: 16,
    hp: 93,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 40, Princes of the Apocalypse Online Supplement v1.0: 11'
  },
  {
    guid: '7272ad74-80e0-4170-a9af-8566bc84d236',
    fid: 'mm.clay-golem',
    name: 'Clay Golem',
    cr: 9,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: 'Golems',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 14,
    hp: 133,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 168'
  },
  {
    guid: '3624bf90-c532-4e42-b97e-66cf22005a1a',
    fid: 'mm.cloaker',
    name: 'Cloaker',
    cr: 8,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'cave, dungeon, underground',
    ac: 14,
    hp: 78,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 41, Princes of the Apocalypse Online Supplement v1.0: 11'
  },
  {
    guid: 'ddb5a9fe-4323-479f-86b1-79d810a0ff69',
    fid: 'mm.cloud-giant',
    name: 'Cloud Giant',
    cr: 9,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'neutral good (50%) or neutral evil (50%)',
    environment: 'mountain, planar',
    ac: 14,
    hp: 200,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 154, Princes of the Apocalypse Online Supplement v1.0: 12'
  },
  {
    guid: '6782e851-fe66-4e6f-abd2-2de17c0f4db9',
    fid: 'mm.cockatrice',
    name: 'Cockatrice',
    cr: '1/2',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest, grassland, swamp',
    ac: 11,
    hp: 27,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 15, Monster Manual: 42'
  },
  {
    guid: 'a5f5b9e0-71af-4788-a471-7135557c8321',
    fid: 'mm.commoner',
    name: 'Commoner',
    cr: 0,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 10,
    hp: 4,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 54, HotDQ supplement: 7, Monster Manual: 345'
  },
  {
    guid: '5ed4761c-b22d-4873-9ad3-31344c34819b',
    fid: 'mm.constrictor-snake',
    name: 'Constrictor Snake',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, swamp',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 15, Monster Manual: 320, Player\'s Handbook: 305'
  },
  {
    guid: 'b226d74a-b4ea-48cf-b510-48e6de7fc765',
    fid: 'mm.copper-dragon-wyrmling',
    name: 'Copper Dragon Wyrmling',
    cr: 1,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, desert, grassland',
    ac: 16,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 112'
  },
  {
    guid: '37abaf7a-e840-4ea1-b34f-334f13a6a7ca',
    fid: 'mm.couatl',
    name: 'Couatl',
    cr: 4,
    size: 'Medium',
    type: 'Celestial',
    tags: '',
    section: '',
    alignment: 'lawful good',
    environment: 'planar',
    ac: 19,
    hp: 97,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 43'
  },
  {
    guid: 'a92307d2-e938-4ee2-aee7-36eae63e2fc9',
    fid: 'mm.crab',
    name: 'Crab',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast',
    ac: 11,
    hp: 2,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 15, Monster Manual: 320'
  },
  {
    guid: '2a0c5d92-fca1-4a81-a702-3c45f230b5fe',
    fid: 'mm.crawling-claw',
    name: 'Crawling Claw',
    cr: 0,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins, swamp',
    ac: 12,
    hp: 2,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 44, Princes of the Apocalypse Online Supplement v1.0: 12'
  },
  {
    guid: '604dfa7a-4b77-4633-a3ce-83e2c073e0d4',
    fid: 'mm.crocodile',
    name: 'Crocodile',
    cr: '1/2',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast, swamp',
    ac: 12,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 15, HotDQ supplement: 7, Monster Manual: 320, Player\'s Handbook: 305'
  },
  {
    guid: '57f917eb-d654-4a66-b1ca-400245e59fc8',
    fid: 'mm.cult-fanatic',
    name: 'Cult Fanatic',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-good',
    environment: 'dungeon, urban',
    ac: 13,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 345, Princes of the Apocalypse Online Supplement v1.0: 12'
  },
  {
    guid: '51bea60c-7686-4bc7-8964-46c75e6953ff',
    fid: 'mm.cultist',
    name: 'Cultist',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-good',
    environment: 'dungeon, urban',
    ac: 12,
    hp: 9,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 54, HotDQ supplement: 7, Monster Manual: 345'
  },
  {
    guid: '1c299dc6-ea10-4314-bc9a-0f40fe4dbfab',
    fid: 'mm.cyclops',
    name: 'Cyclops',
    cr: 6,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'cave, coast, grassland, mountain, ruins, swamp',
    ac: 14,
    hp: 138,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 16, Monster Manual: 45'
  },
  {
    guid: 'c3b998ee-064e-4034-aaac-8e3d8dcb4eae',
    fid: 'mm.dao',
    name: 'Dao',
    cr: 11,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Genies',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, mountain, planar, underground',
    ac: 18,
    hp: 187,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 143, Princes of the Apocalypse Online Supplement v1.0: 13'
  },
  {
    guid: 'f113bf67-3a28-494e-b74b-d495c2ca4d8c',
    fid: 'mm.darkmantle',
    name: 'Darkmantle',
    cr: '1/2',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 11,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 46, Princes of the Apocalypse Online Supplement v1.0: 13'
  },
  {
    guid: '3f109065-752c-496c-9169-b2ab3399126b',
    fid: 'mm.death-dog',
    name: 'Death Dog',
    cr: 1,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral evil',
    environment: 'desert, grassland, underground',
    ac: 12,
    hp: 39,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 16, Monster Manual: 321'
  },
  {
    guid: 'e4c765b3-445f-475b-853d-d27b6d4d12fe',
    fid: 'mm.death-knight',
    name: 'Death Knight',
    cr: 17,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 20,
    hp: 180,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 47'
  },
  {
    guid: '7eee83ee-5f71-458a-b0c7-ac04fd78301f',
    fid: 'mm.death-slaad',
    name: 'Death Slaad',
    cr: 10,
    size: 'Medium',
    type: 'Aberration',
    tags: 'Shapechanger',
    section: 'Slaadi',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 170,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 278'
  },
  {
    guid: '47c0a532-6d34-4cf9-9d89-ffc4b8575898',
    fid: 'mm.death-tyrant',
    name: 'Death Tyrant',
    cr: 14,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, underground',
    ac: 19,
    hp: 187,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 29'
  },
  {
    guid: 'efc8bf04-0f28-4c53-80fd-38e4f6404231',
    fid: 'mm.death-tyrant-in-lair',
    name: 'Death Tyrant (in lair)',
    cr: 15,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, underground',
    ac: 19,
    hp: 187,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 29'
  },
  {
    guid: 'dd4a1c24-730a-467c-ac2f-8417fac0fdf8',
    fid: 'mm.deep-gnome-svirfneblin',
    name: 'Deep Gnome (Svirfneblin)',
    cr: '1/2',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Gnome',
    section: 'Gnome, Deep (Svirfneblin)',
    alignment: 'neutral good',
    environment: 'cave, dungeon, planar, underground',
    ac: 15,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 164, Princes of the Apocalypse Online Supplement v1.0: 13'
  },
  {
    guid: 'ba54ab80-5007-4695-9f82-003a88cfdeeb',
    fid: 'mm.deer',
    name: 'Deer',
    cr: 0,
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 13,
    hp: 4,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 16, HotDQ supplement: 7, Monster Manual: 321'
  },
  {
    guid: '7678ccea-6973-4647-afa0-a0f1957392df',
    fid: 'mm.demilich',
    name: 'Demilich',
    cr: 18,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon',
    ac: 20,
    hp: 80,
    init: 5,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 48'
  },
  {
    guid: '44738bcb-dfb3-4ce0-bf2e-836df2601fdd',
    fid: 'mm.demilich-acererak',
    name: 'Demilich (Acererak)',
    cr: 21,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 20,
    hp: 80,
    init: 5,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Monster Manual: 49'
  },
  {
    guid: 'd315f181-effc-499e-947b-caf6eeec524a',
    fid: 'mm.demilich-acererak-in-lair',
    name: 'Demilich (Acererak in lair)',
    cr: 23,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 20,
    hp: 80,
    init: 5,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Monster Manual: 49'
  },
  {
    guid: 'ccb1f0eb-3037-40f3-bbe0-1f63f89ba71c',
    fid: 'mm.demilich-in-lair',
    name: 'Demilich (in lair)',
    cr: 20,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon',
    ac: 20,
    hp: 80,
    init: 5,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 48'
  },
  {
    guid: 'c7e54573-9829-490d-9c8f-4874cb14ba3d',
    fid: 'mm.deva',
    name: 'Deva',
    cr: 10,
    size: 'Medium',
    type: 'Celestial',
    tags: '',
    section: 'Angels',
    alignment: 'lawful good',
    environment: 'planar',
    ac: 17,
    hp: 136,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 16'
  },
  {
    guid: '9a507770-7644-4486-b73e-ca04b78d2e43',
    fid: 'mm.dire-wolf',
    name: 'Dire Wolf',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic, cave, forest, grassland',
    ac: 14,
    hp: 37,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 16, Monster Manual: 321, Player\'s Handbook: 305'
  },
  {
    guid: '6169f0f9-4bc6-4453-8d20-a7ceed20dbbd',
    fid: 'mm.displacer-beast',
    name: 'Displacer Beast',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'forest, grassland',
    ac: 13,
    hp: 85,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 81'
  },
  {
    guid: 'bd3dbe66-519f-4aaf-96a0-4058b8910af6',
    fid: 'mm.djinni',
    name: 'Djinni',
    cr: 11,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Genies',
    alignment: 'chaotic good',
    environment: 'arctic, coast, dungeon, grassland, planar',
    ac: 17,
    hp: 161,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 144, Princes of the Apocalypse Online Supplement v1.0: 14'
  },
  {
    guid: '738ff802-57e5-4f73-89cb-5def2b527fc0',
    fid: 'mm.doppelganger',
    name: 'Doppelganger',
    cr: 3,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon, ruins, urban',
    ac: 14,
    hp: 52,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 17, HotDQ supplement: 8, Monster Manual: 82'
  },
  {
    guid: '46979307-705f-43e6-be68-7f72c936b1ba',
    fid: 'mm.draft-horse',
    name: 'Draft Horse',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 10,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 17, Monster Manual: 321'
  },
  {
    guid: '5a1662ec-9630-404e-8f37-7c79e9f9f450',
    fid: 'mm.dragon-turtle',
    name: 'Dragon Turtle',
    cr: 17,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'aquatic',
    ac: 20,
    hp: 341,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 119, Princes of the Apocalypse Online Supplement v1.0: 14'
  },
  {
    guid: '84860c36-652d-44df-bd00-905303a4e589',
    fid: 'mm.dretch',
    name: 'Dretch',
    cr: '1/4',
    size: 'Small',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 11,
    hp: 18,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 57'
  },
  {
    guid: 'cd17abab-5062-4ab5-9a1c-df53d3962a87',
    fid: 'mm.drider',
    name: 'Drider',
    cr: 6,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'dungeon, underground',
    ac: 19,
    hp: 123,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 120'
  },
  {
    guid: 'e28ee68c-d06b-411c-9ed5-423a151d6697',
    fid: 'mm.drow',
    name: 'Drow',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Elves: Drow',
    alignment: 'neutral evil',
    environment: 'dungeon, underground, urban',
    ac: 15,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 128'
  },
  {
    guid: '197e15f8-c5f7-41ac-a47a-a92144e2bfac',
    fid: 'mm.drow-elite-warrior',
    name: 'Drow Elite Warrior',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Elves: Drow',
    alignment: 'neutral evil',
    environment: 'dungeon, underground, urban',
    ac: 18,
    hp: 71,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 128'
  },
  {
    guid: 'a2a7ae70-c46a-48b8-9a5f-d3b1c82b91b5',
    fid: 'mm.drow-mage',
    name: 'Drow Mage',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Elves: Drow',
    alignment: 'neutral evil',
    environment: 'dungeon, underground, urban',
    ac: '12 (15 with mage armor)',
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 129, Princes of the Apocalypse Online Supplement v1.0: 15'
  },
  {
    guid: '525d2a5a-4fba-4183-926e-4cca39164da4',
    fid: 'mm.drow-priestess-of-lolth',
    name: 'Drow Priestess of Lolth',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Elves: Drow',
    alignment: 'neutral evil',
    environment: 'dungeon, underground, urban',
    ac: 16,
    hp: 71,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 129'
  },
  {
    guid: 'aa328a81-cbcb-4800-96d4-3f9f7cc021f3',
    fid: 'mm.druid',
    name: 'Druid',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, cave, coast, desert, forest, grassland, mountain, swamp, underground',
    ac: 11,
    hp: 27,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 346, Princes of the Apocalypse Online Supplement v1.0: 15'
  },
  {
    guid: 'ca145277-a157-4591-af02-3d5a3cfe3368',
    fid: 'mm.dryad',
    name: 'Dryad',
    cr: 1,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'forest',
    ac: '11 (16 with barkskin)',
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 121'
  },
  {
    guid: '3dabeab2-dbcb-4915-a633-797f17fe5f4d',
    fid: 'mm.duergar',
    name: 'Duergar',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon, underground',
    ac: 16,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 122, Princes of the Apocalypse Online Supplement v1.0: 16'
  },
  {
    guid: '1c8b43c4-f7ad-40cb-b5c6-ceab1f78c94f',
    fid: 'mm.duodrone',
    name: 'Duodrone',
    cr: '1/4',
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Modrons',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 15,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 225'
  },
  {
    guid: '82d58511-f0e1-4e2c-a4fc-8908210146a1',
    fid: 'mm.dust-mephit',
    name: 'Dust Mephit',
    cr: '1/2',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'dungeon, planar, ruins, urban',
    ac: 12,
    hp: 17,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 215, Princes of the Apocalypse Online Supplement v1.0: 16'
  },
  {
    guid: '2b990f56-68e4-4c1c-8905-c1a05269beeb',
    fid: 'mm.eagle',
    name: 'Eagle',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic, grassland, mountain',
    ac: 12,
    hp: 3,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 17, Monster Manual: 322'
  },
  {
    guid: 'e5f96c20-3f6c-4374-90e8-89a3952f2562',
    fid: 'mm.earth-elemental',
    name: 'Earth Elemental',
    cr: 5,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Elementals',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 17,
    hp: 126,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 17, Monster Manual: 124'
  },
  {
    guid: '56fac8a2-3ef7-4ed6-bf94-df506191ed77',
    fid: 'mm.efreeti',
    name: 'Efreeti',
    cr: 11,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Genies',
    alignment: 'lawful evil',
    environment: 'desert, dungeon, mountain, planar',
    ac: 17,
    hp: 200,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 145, Princes of the Apocalypse Online Supplement v1.0: 16'
  },
  {
    guid: '45934b57-b2f7-4bce-bc98-0af4e1233b62',
    fid: 'mm.elephant',
    name: 'Elephant',
    cr: 4,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 12,
    hp: 76,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 18, Monster Manual: 322'
  },
  {
    guid: 'eb4b6767-1035-48a2-8a66-b9e2572e2322',
    fid: 'mm.elk',
    name: 'Elk',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 10,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 18, HotDQ supplement: 8, Monster Manual: 322'
  },
  {
    guid: '607299fd-9bfc-41da-983e-47a821eb35a9',
    fid: 'mm.empyrean',
    name: 'Empyrean',
    cr: 23,
    size: 'Huge',
    type: 'Celestial',
    tags: 'Titan',
    section: '',
    alignment: 'chaotic good (75%) or neutral evil (25%)',
    environment: 'planar',
    ac: 22,
    hp: 313,
    init: 5,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 130'
  },
  {
    guid: '17e723aa-5268-4ed7-8c54-113a4e6fb76c',
    fid: 'mm.erinyes',
    name: 'Erinyes',
    cr: 12,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 153,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 73'
  },
  {
    guid: '1ae109e6-763a-46cd-b643-396c0e83da84',
    fid: 'mm.ettercap',
    name: 'Ettercap',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, forest, ruins, swamp, underground',
    ac: 13,
    hp: 44,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 9, Monster Manual: 131'
  },
  {
    guid: '2d0d5297-dd18-4bd7-85e9-0759e4a6bb8b',
    fid: 'mm.ettin',
    name: 'Ettin',
    cr: 4,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, grassland, mountain, swamp',
    ac: 12,
    hp: 85,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 132, Princes of the Apocalypse Online Supplement v1.0: 17'
  },
  {
    guid: '6a982eda-d984-429f-b6e0-8b3c28e2914f',
    fid: 'mm.faerie-dragon-blue',
    name: 'Faerie Dragon (blue)',
    cr: 2,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: '2f69eb5e-7c8c-4f6b-97f3-aab4ee7c5501',
    fid: 'mm.faerie-dragon-green',
    name: 'Faerie Dragon (green)',
    cr: 2,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: 'fd9e0821-7b20-4184-b292-e50d154b7f7b',
    fid: 'mm.faerie-dragon-indigo',
    name: 'Faerie Dragon (indigo)',
    cr: 2,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: '009bfc30-93b0-48f5-a403-269c8ace67bf',
    fid: 'mm.faerie-dragon-orange',
    name: 'Faerie Dragon (orange)',
    cr: 1,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: '8e6e36dd-dabc-4fd9-ba4d-261b26baeb81',
    fid: 'mm.faerie-dragon-red',
    name: 'Faerie Dragon (red)',
    cr: 1,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: 'd5edc918-ee9d-46dd-a780-fd6203e75228',
    fid: 'mm.faerie-dragon-violet',
    name: 'Faerie Dragon (violet)',
    cr: 2,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: '83602f7b-43f6-4d20-ab7c-c2dd52990826',
    fid: 'mm.faerie-dragon-yellow',
    name: 'Faerie Dragon (yellow)',
    cr: 1,
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, grassland, mountain, ruins, swamp',
    ac: 15,
    hp: 14,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 133'
  },
  {
    guid: '4630fbab-b0ca-41dc-9e42-3a69a737ff0e',
    fid: 'mm.fire-elemental',
    name: 'Fire Elemental',
    cr: 5,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Elementals',
    alignment: 'neutral',
    environment: 'cave, coast, desert, dungeon, grassland, mountain, planar, ruins, underground',
    ac: 13,
    hp: 102,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 18, Monster Manual: 125'
  },
  {
    guid: 'cc07acfb-a623-43ec-8c23-70a0385eb481',
    fid: 'mm.fire-giant',
    name: 'Fire Giant',
    cr: 9,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'lawful evil',
    environment: 'mountain, planar',
    ac: 18,
    hp: 162,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 19, Monster Manual: 154'
  },
  {
    guid: '20207713-fef6-4f14-a3b0-1417d18d755a',
    fid: 'mm.fire-snake',
    name: 'Fire Snake',
    cr: 1,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Salamanders',
    alignment: 'neutral evil',
    environment: 'forest, planar',
    ac: 14,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 265, Princes of the Apocalypse Online Supplement v1.0: 17'
  },
  {
    guid: '4665dbb1-cbc4-433a-a0df-0b6477d6f4fa',
    fid: 'mm.flameskull',
    name: 'Flameskull',
    cr: 4,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins',
    ac: 13,
    hp: 40,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 19, Monster Manual: 134'
  },
  {
    guid: '1a0097d3-c91a-4515-89cf-a63c4badc355',
    fid: 'mm.flesh-golem',
    name: 'Flesh Golem',
    cr: 5,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Golems',
    alignment: 'unaligned',
    environment: 'dungeon, ruins',
    ac: 9,
    hp: 93,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 20, Monster Manual: 169'
  },
  {
    guid: '14d204ca-04d2-44d4-a644-2e1e9e2d9091',
    fid: 'mm.flumph',
    name: 'Flumph',
    cr: '1/8',
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'lawful good',
    environment: 'dungeon, underground',
    ac: 12,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 135'
  },
  {
    guid: '6afa4798-b9c5-4139-adeb-55eaba3e9cdb',
    fid: 'mm.flying-snake',
    name: 'Flying Snake',
    cr: '1/8',
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, swamp',
    ac: 14,
    hp: 5,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 20, Monster Manual: 322'
  },
  {
    guid: '35c699fb-a8d2-491f-9a73-206a61937297',
    fid: 'mm.flying-sword',
    name: 'Flying Sword',
    cr: '1/4',
    size: 'Small',
    type: 'Construct',
    tags: '',
    section: 'Animated Objects',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 17,
    hp: 17,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 20, Monster Manual: 20'
  },
  {
    guid: 'fdb52704-4bb5-4909-b55e-0f8ee199f877',
    fid: 'mm.fomorian',
    name: 'Fomorian',
    cr: 8,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, underground',
    ac: 14,
    hp: 149,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 136'
  },
  {
    guid: '303172de-265f-4046-bf47-2aff0f444f6e',
    fid: 'mm.frog',
    name: 'Frog',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast, forest, swamp',
    ac: 11,
    hp: 1,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 21, Monster Manual: 322, Player\'s Handbook: 305'
  },
  {
    guid: '8a2720eb-fb5c-4a79-8560-b3d1443d5e76',
    fid: 'mm.frost-giant',
    name: 'Frost Giant',
    cr: 8,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'neutral evil',
    environment: 'arctic, mountain, planar',
    ac: 15,
    hp: 138,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 21, Monster Manual: 155'
  },
  {
    guid: '1e738fdf-524b-4127-b5b9-916758a8b52a',
    fid: 'mm.galeb-duhr',
    name: 'Galeb Duhr',
    cr: 6,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'cave, dungeon, mountain, planar, underground',
    ac: 16,
    hp: 85,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 139, Princes of the Apocalypse Online Supplement v1.0: 17'
  },
  {
    guid: '625ff3cf-c836-498f-bb9b-de25adb1c26d',
    fid: 'mm.gargoyle',
    name: 'Gargoyle',
    cr: 2,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'dungeon, urban',
    ac: 15,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 21, HotDQ supplement: 9, Monster Manual: 140'
  },
  {
    guid: '5ad9d340-d07d-48bf-8938-2d304730746d',
    fid: 'mm.gas-spore',
    name: 'Gas Spore',
    cr: '1/2',
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: 'Fungi',
    alignment: 'unaligned',
    environment: 'cave, dungeon, underground',
    ac: 5,
    hp: 1,
    init: -5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 138'
  },
  {
    guid: '2230d3a4-822b-48b2-99f5-373ffb6695a8',
    fid: 'mm.gelatinous-cube',
    name: 'Gelatinous Cube',
    cr: 2,
    size: 'Large',
    type: 'Ooze',
    tags: '',
    section: 'Oozes',
    alignment: 'unaligned',
    environment: 'dungeon',
    ac: 6,
    hp: 84,
    init: -4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 242'
  },
  {
    guid: '73113f3b-87d9-46a3-90a8-38ab64ba2dab',
    fid: 'mm.ghast',
    name: 'Ghast',
    cr: 2,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Ghouls',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 13,
    hp: 36,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 148, Princes of the Apocalypse Online Supplement v1.0: 18'
  },
  {
    guid: 'c15c8f1e-5e6c-4011-a994-683fbb0b18dd',
    fid: 'mm.ghost',
    name: 'Ghost',
    cr: 4,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'any',
    environment: 'cave, dungeon, ruins, swamp, underground, urban',
    ac: 11,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 22, Monster Manual: 147'
  },
  {
    guid: '369e60c6-5792-4623-8e11-36951e652011',
    fid: 'mm.ghoul',
    name: 'Ghoul',
    cr: 1,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Ghouls',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 22, Monster Manual: 148'
  },
  {
    guid: '4115f79a-084f-4775-b821-2b7ce6fc60d6',
    fid: 'mm.giant-ape',
    name: 'Giant Ape',
    cr: 7,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 12,
    hp: 157,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 22, Monster Manual: 323'
  },
  {
    guid: '2a435613-2ac3-4486-90e6-e62e2efce0ec',
    fid: 'mm.giant-badger',
    name: 'Giant Badger',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 10,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 23, Monster Manual: 323'
  },
  {
    guid: 'f96d5318-3768-4f78-b800-6cc36f474c0a',
    fid: 'mm.giant-bat',
    name: 'Giant Bat',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, ruins, swamp, underground',
    ac: 13,
    hp: 22,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 23, Monster Manual: 323'
  },
  {
    guid: 'bcc30ff0-0853-456b-961e-c23a7236b979',
    fid: 'mm.giant-boar',
    name: 'Giant Boar',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 12,
    hp: 42,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 23, Monster Manual: 323'
  },
  {
    guid: '98ec83af-5011-4e90-b17e-7885df911032',
    fid: 'mm.giant-centipede',
    name: 'Giant Centipede',
    cr: '1/4',
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground',
    ac: 13,
    hp: 4,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 23, HotDQ supplement: 9, Monster Manual: 323'
  },
  {
    guid: 'f2d2909a-895b-4f43-89eb-5a17a5f129c3',
    fid: 'mm.giant-constrictor-snake',
    name: 'Giant Constrictor Snake',
    cr: 2,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland, swamp',
    ac: 12,
    hp: 60,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 24, Monster Manual: 324'
  },
  {
    guid: 'df3f2dc9-40e2-48f3-9d40-a62a57dd0492',
    fid: 'mm.giant-crab',
    name: 'Giant Crab',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast',
    ac: 15,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 24, Monster Manual: 324'
  },
  {
    guid: '2408c546-fa02-40e0-9264-08d13fe5386c',
    fid: 'mm.giant-crocodile',
    name: 'Giant Crocodile',
    cr: 5,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast, swamp',
    ac: 14,
    hp: 85,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 24, Monster Manual: 324'
  },
  {
    guid: 'a02c80fc-b950-4e71-be07-2a3b28a126a4',
    fid: 'mm.giant-eagle',
    name: 'Giant Eagle',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral good',
    environment: 'grassland, mountain',
    ac: 13,
    hp: 26,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 24, Monster Manual: 324, Player\'s Handbook: 306'
  },
  {
    guid: '68039952-d660-43e4-8949-ae6eb04606b6',
    fid: 'mm.giant-elk',
    name: 'Giant Elk',
    cr: 2,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 14,
    hp: 42,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 25, Monster Manual: 325'
  },
  {
    guid: '3b66d494-fe9b-411c-8e8e-d8f384c41ef8',
    fid: 'mm.giant-fire-beetle',
    name: 'Giant Fire Beetle',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground',
    ac: 13,
    hp: 4,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 25, Monster Manual: 325'
  },
  {
    guid: '7a608516-9ef3-4bf3-85c6-45837c61a77e',
    fid: 'mm.giant-frog',
    name: 'Giant Frog',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast, forest, swamp',
    ac: 11,
    hp: 18,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 25, HotDQ supplement: 10, Monster Manual: 325'
  },
  {
    guid: '6589f3c5-4596-4fd4-b69c-94be539aade0',
    fid: 'mm.giant-goat',
    name: 'Giant Goat',
    cr: '1/2',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland, mountain',
    ac: 11,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 25, Monster Manual: 326'
  },
  {
    guid: 'd56e1457-2295-4bfd-b1d6-d84e53d6debc',
    fid: 'mm.giant-hyena',
    name: 'Giant Hyena',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'coast, desert, forest, grassland',
    ac: 12,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 26, Monster Manual: 326'
  },
  {
    guid: 'b1e3ee1f-d616-412d-ab5e-10aad27b2476',
    fid: 'mm.giant-lizard',
    name: 'Giant Lizard',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground',
    ac: 12,
    hp: 19,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 26, HotDQ supplement: 10, Monster Manual: 326'
  },
  {
    guid: '0dcde23a-8100-4855-ac07-3388d1b8cc40',
    fid: 'mm.giant-octopus',
    name: 'Giant Octopus',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 11,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 26, Monster Manual: 326'
  },
  {
    guid: '0f0d61e7-2a8f-47c6-b1c9-35d7390dd6bb',
    fid: 'mm.giant-owl',
    name: 'Giant Owl',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral',
    environment: 'forest, grassland, urban',
    ac: 12,
    hp: 19,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 26, Monster Manual: 327'
  },
  {
    guid: 'ab030d7f-8e46-4167-bf36-45ff59a95665',
    fid: 'mm.giant-poisonous-snake',
    name: 'Giant Poisonous Snake',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland, swamp',
    ac: 14,
    hp: 11,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 27, Monster Manual: 327'
  },
  {
    guid: 'dd4df726-a041-4058-ac44-225aab9be366',
    fid: 'mm.giant-rat',
    name: 'Giant Rat',
    cr: '1/8',
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 12,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 27, Monster Manual: 327'
  },
  {
    guid: 'ff4ee842-7c21-4d0e-b6ff-d72dc13913db',
    fid: 'mm.giant-scorpion',
    name: 'Giant Scorpion',
    cr: 3,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, coast, desert, dungeon, grassland, ruins, swamp, underground',
    ac: 15,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 27, Monster Manual: 327'
  },
  {
    guid: '2fe6b60e-017f-40fa-9746-44e5b9f6bb2d',
    fid: 'mm.giant-sea-horse',
    name: 'Giant Sea Horse',
    cr: '1/2',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 13,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 27, Monster Manual: 328'
  },
  {
    guid: '4d78880d-7eae-4494-ac22-e0bf415e32ad',
    fid: 'mm.giant-shark',
    name: 'Giant Shark',
    cr: 5,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 13,
    hp: 126,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 28, Monster Manual: 328'
  },
  {
    guid: 'ceff6967-7d2c-4b84-9e2a-df1d36a9a98c',
    fid: 'mm.giant-spider',
    name: 'Giant Spider',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 14,
    hp: 26,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 28, HotDQ supplement: 10, Monster Manual: 328, Player\'s Handbook: 306'
  },
  {
    guid: 'dfd9f55f-e53c-4abb-b761-144062af6d0d',
    fid: 'mm.giant-toad',
    name: 'Giant Toad',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic, coast, forest, swamp',
    ac: 11,
    hp: 39,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 28, Monster Manual: 329'
  },
  {
    guid: '6b6e5aa1-bc01-4af6-b64a-8ac3f468eaf7',
    fid: 'mm.giant-vulture',
    name: 'Giant Vulture',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral evil',
    environment: 'desert, grassland, ruins',
    ac: 10,
    hp: 22,
    init: 13,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 29, Monster Manual: 329'
  },
  {
    guid: 'ff490947-a963-4c11-8a1c-f238f4f6ad27',
    fid: 'mm.giant-wasp',
    name: 'Giant Wasp',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 29, Monster Manual: 329'
  },
  {
    guid: 'a30c0261-68cd-4b59-8bf6-c47bbde26f36',
    fid: 'mm.giant-weasel',
    name: 'Giant Weasel',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 13,
    hp: 9,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 29, Monster Manual: 329'
  },
  {
    guid: 'f51ac131-dfc5-4672-a569-ab361ac31447',
    fid: 'mm.giant-wolf-spider',
    name: 'Giant Wolf Spider',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 13,
    hp: 11,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 29, Monster Manual: 330'
  },
  {
    guid: '3394673a-e802-409a-b0b2-8d3f972a86fc',
    fid: 'mm.gibbering-mouther',
    name: 'Gibbering Mouther',
    cr: 2,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'cave, dungeon, planar, underground',
    ac: 9,
    hp: 67,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 157'
  },
  {
    guid: '54fd43f7-d101-461d-b6db-e64f095eed84',
    fid: 'mm.githyanki-knight',
    name: 'Githyanki Knight',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful evil',
    environment: 'planar, swamp',
    ac: 18,
    hp: 91,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 160'
  },
  {
    guid: 'a3e7690f-8fc3-4a63-ba8c-75edbdbd755b',
    fid: 'mm.githyanki-warrior',
    name: 'Githyanki Warrior',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 17,
    hp: 49,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 160'
  },
  {
    guid: '09632ffa-87af-47aa-b906-1e343f742ab5',
    fid: 'mm.githzerai-monk',
    name: 'Githzerai Monk',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 14,
    hp: 38,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 161'
  },
  {
    guid: '3058ccdb-7705-4545-8b7d-a09345c676dc',
    fid: 'mm.githzerai-zerth',
    name: 'Githzerai Zerth',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 17,
    hp: 84,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 161'
  },
  {
    guid: '681eb77f-9e46-4d7c-b96b-c850f1037dd0',
    fid: 'mm.glabrezu',
    name: 'Glabrezu',
    cr: 9,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 17,
    hp: 157,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 58'
  },
  {
    guid: '95c84527-c3f5-41df-a449-3f9c2cb56953',
    fid: 'mm.gladiator',
    name: 'Gladiator',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 16,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 346'
  },
  {
    guid: '07256557-e839-43d0-b84b-e91f3e7866fc',
    fid: 'mm.gnoll',
    name: 'Gnoll',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 15,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 30, Monster Manual: 163'
  },
  {
    guid: 'e6e7eb98-e83c-4f36-ab56-35f50982c411',
    fid: 'mm.gnoll-fang-of-yeenoghu',
    name: 'Gnoll Fang of Yeenoghu',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 14,
    hp: 65,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 163'
  },
  {
    guid: 'a07c63be-7fd9-441a-bbe0-469a0e732bbb',
    fid: 'mm.gnoll-pack-lord',
    name: 'Gnoll Pack Lord',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 15,
    hp: 49,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 163, Princes of the Apocalypse Online Supplement v1.0: 18'
  },
  {
    guid: '4407c527-3be7-4010-b51d-a777203e49e1',
    fid: 'mm.goat',
    name: 'Goat',
    cr: 0,
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland, urban',
    ac: 10,
    hp: 4,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 30, Monster Manual: 330'
  },
  {
    guid: '9cf6c94e-b019-47e1-a746-ab4d02f684a9',
    fid: 'mm.goblin',
    name: 'Goblin',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Goblins',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, forest, grassland, mountain, ruins, urban',
    ac: 15,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 30, Monster Manual: 166'
  },
  {
    guid: '0ad711a0-1b8b-418c-836e-b95ff9abb066',
    fid: 'mm.goblin-boss',
    name: 'Goblin Boss',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Goblins',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, forest, grassland, mountain, ruins, urban',
    ac: 17,
    hp: 21,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 166'
  },
  {
    guid: '524618b1-0d0d-4e12-bcf3-cb8d725c9ebc',
    fid: 'mm.gold-dragon-wyrmling',
    name: 'Gold Dragon Wyrmling',
    cr: 3,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'mountain, ruins',
    ac: 17,
    hp: 60,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 115'
  },
  {
    guid: '7065db5f-03fc-4fc5-964d-8bdf5d47f701',
    fid: 'mm.gorgon',
    name: 'Gorgon',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'mountain, ruins',
    ac: 19,
    hp: 114,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 171'
  },
  {
    guid: '776873f4-290b-4bf8-8d55-1d9b978791b0',
    fid: 'mm.goristro',
    name: 'Goristro',
    cr: 17,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 19,
    hp: 310,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 59'
  },
  {
    guid: 'd82d19dd-2e7e-407f-9861-b7e2ee599e5c',
    fid: 'mm.gray-ooze',
    name: 'Gray Ooze',
    cr: '1/2',
    size: 'Medium',
    type: 'Ooze',
    tags: '',
    section: 'Oozes',
    alignment: 'unaligned',
    environment: 'dungeon',
    ac: 8,
    hp: 22,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 11, Monster Manual: 243'
  },
  {
    guid: '7087acfe-930d-49b7-9fab-a0351650c7ce',
    fid: 'mm.gray-slaad',
    name: 'Gray Slaad',
    cr: 9,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Slaadi',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 18,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 277'
  },
  {
    guid: '41eb895f-32b5-4389-8e99-145545851edc',
    fid: 'mm.green-dragon-wyrmling',
    name: 'Green Dragon Wyrmling',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 17,
    hp: 38,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 95'
  },
  {
    guid: '9447b8b2-f96c-4607-b210-966d201d6da6',
    fid: 'mm.green-hag',
    name: 'Green Hag',
    cr: 3,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'neutral evil',
    environment: 'forest, swamp',
    ac: 17,
    hp: 82,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 177'
  },
  {
    guid: 'af9dbb65-ebf7-468e-9746-131a66a9facb',
    fid: 'mm.green-hag-coven',
    name: 'Green Hag (coven)',
    cr: 5,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'neutral evil',
    environment: 'forest, swamp',
    ac: 17,
    hp: 82,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 177'
  },
  {
    guid: '69a7ed5e-16a9-466d-90a9-867136fb9469',
    fid: 'mm.green-slaad',
    name: 'Green Slaad',
    cr: 8,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Slaadi',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 16,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 277'
  },
  {
    guid: '58efcfe3-54aa-4680-82c0-4ac9cf417bcd',
    fid: 'mm.grell',
    name: 'Grell',
    cr: 3,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, underground',
    ac: 12,
    hp: 55,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 172, Princes of the Apocalypse Online Supplement v1.0: 18'
  },
  {
    guid: '9ab9a61b-5298-44e9-8950-167e4a4e9c98',
    fid: 'mm.grick',
    name: 'Grick',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Grick',
    alignment: 'neutral',
    environment: 'cave, dungeon, underground',
    ac: 14,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 30, Monster Manual: 173'
  },
  {
    guid: 'a227bfa9-bdbe-4a27-9e06-f4e65de57280',
    fid: 'mm.grick-alpha',
    name: 'Grick Alpha',
    cr: 7,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Grick',
    alignment: 'neutral',
    environment: 'cave, dungeon, underground',
    ac: 18,
    hp: 75,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 173'
  },
  {
    guid: '9084361f-6e4a-47ee-9e58-341f346278b2',
    fid: 'mm.griffon',
    name: 'Griffon',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'arctic, coast, grassland, mountain',
    ac: 12,
    hp: 59,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 31, HotDQ supplement: 11, Monster Manual: 174'
  },
  {
    guid: 'f3f05353-e662-4b51-bdf2-19e3be7769eb',
    fid: 'mm.grimlock',
    name: 'Grimlock',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Grimlock',
    section: '',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, underground',
    ac: 11,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 175'
  },
  {
    guid: '2dfd127c-8844-4289-ac04-b4fe2f1656e4',
    fid: 'mm.guard',
    name: 'Guard',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 16,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 54, HotDQ supplement: 11, Monster Manual: 347'
  },
  {
    guid: '9f2cb577-d9e2-48bb-ba99-6fc9ba74ec69',
    fid: 'mm.guardian-naga',
    name: 'Guardian Naga',
    cr: 10,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Nagas',
    alignment: 'lawful good',
    environment: 'cave, coast, forest, ruins',
    ac: 15,
    hp: 75,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 234'
  },
  {
    guid: 'adf26741-67bd-4908-92e7-231a28374808',
    fid: 'mm.gynosphinx',
    name: 'Gynosphinx',
    cr: 11,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Sphinxes',
    alignment: 'lawful neutral',
    environment: 'dungeon, ruins',
    ac: 17,
    hp: 136,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 282'
  },
  {
    guid: '89e0d155-e208-41f0-86fc-af2a62eb4c73',
    fid: 'mm.half-ogre',
    name: 'Half-Ogre',
    cr: 1,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 12,
    hp: 30,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 238, Princes of the Apocalypse Online Supplement v1.0: 19'
  },
  {
    guid: '1a52d02f-8c55-41cb-80c5-48127774767b',
    fid: 'mm.half-red-dragon-veteran',
    name: 'Half-Red Dragon Veteran',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: 'Half-Dragon',
    alignment: 'any',
    environment: 'arctic, coast, desert, grassland, mountain, urban',
    ac: 18,
    hp: 65,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 180'
  },
  {
    guid: '3279999e-4dd9-4149-8ce5-f0867c108d30',
    fid: 'mm.harpy',
    name: 'Harpy',
    cr: 1,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'coast, forest, mountain',
    ac: 11,
    hp: 38,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 31, Monster Manual: 181'
  },
  {
    guid: 'd2ebaf9c-3be0-4c0c-ac98-c65e6d80b741',
    fid: 'mm.hawk',
    name: 'Hawk',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland, mountain',
    ac: 13,
    hp: 1,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 31, Monster Manual: 330, Player\'s Handbook: 306'
  },
  {
    guid: '5545c172-5478-4ac0-8db5-7b2c8e90394c',
    fid: 'mm.hell-hound',
    name: 'Hell Hound',
    cr: 3,
    size: 'Medium',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon, planar, urban',
    ac: 15,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 32, Monster Manual: 182'
  },
  {
    guid: '82ec416b-64ff-4738-b308-9cd44ef5957d',
    fid: 'mm.helmed-horror',
    name: 'Helmed Horror',
    cr: 4,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon, ruins, urban',
    ac: 20,
    hp: 60,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 11, Monster Manual: 183, Princes of the Apocalypse Online Supplement v1.0: 19'
  },
  {
    guid: 'ba7bbb9e-2a12-4e4b-b90c-0d8e90f0898d',
    fid: 'mm.hezrou',
    name: 'Hezrou',
    cr: 8,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 16,
    hp: 136,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 60, Princes of the Apocalypse Online Supplement v1.0: 19'
  },
  {
    guid: 'c7296867-6041-418d-b255-b675639cfc2c',
    fid: 'mm.hill-giant',
    name: 'Hill Giant',
    cr: 5,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, grassland',
    ac: 13,
    hp: 105,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 32, Monster Manual: 155'
  },
  {
    guid: '5d506ff9-4a90-460e-82e4-68bdf0bac7cf',
    fid: 'mm.hippogriff',
    name: 'Hippogriff',
    cr: 1,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'mountain',
    ac: 11,
    hp: 19,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 32, Monster Manual: 184'
  },
  {
    guid: '127e4789-95e3-48e4-8937-7d85ccb3bea0',
    fid: 'mm.hobgoblin',
    name: 'Hobgoblin',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Hobgoblins',
    alignment: 'lawful evil',
    environment: 'arctic, coast, grassland, mountain, urban',
    ac: 18,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 32, HotDQ supplement: 12, Monster Manual: 186'
  },
  {
    guid: '5341cb95-c090-4295-ba1d-83d978988a10',
    fid: 'mm.hobgoblin-captain',
    name: 'Hobgoblin Captain',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Hobgoblins',
    alignment: 'lawful evil',
    environment: 'arctic, coast, grassland, mountain, urban',
    ac: 17,
    hp: 39,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 12, Monster Manual: 186, Princes of the Apocalypse Online Supplement v1.0: 20'
  },
  {
    guid: '3a5c21f3-f610-47ec-ba99-8ea6ceb9a5a2',
    fid: 'mm.hobgoblin-warlord',
    name: 'Hobgoblin Warlord',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Hobgoblins',
    alignment: 'lawful evil',
    environment: 'arctic, coast, grassland, mountain, urban',
    ac: 20,
    hp: 97,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 187'
  },
  {
    guid: '733da9a7-a197-4be8-b8f0-00e492801eec',
    fid: 'mm.homunculus',
    name: 'Homunculus',
    cr: 0,
    size: 'Tiny',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon, urban',
    ac: 13,
    hp: 5,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 188'
  },
  {
    guid: '3e4d8e86-de04-4ccb-aee2-2f4049b6769b',
    fid: 'mm.hook-horror',
    name: 'Hook Horror',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon, underground',
    ac: 15,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 189, Princes of the Apocalypse Online Supplement v1.0: 20'
  },
  {
    guid: '50a88400-dd98-40b7-a03a-4cbedd5730b8',
    fid: 'mm.horned-devil',
    name: 'Horned Devil',
    cr: 11,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 178,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 74'
  },
  {
    guid: '3cbfec14-c628-4140-82ce-8cb47cfcdb98',
    fid: 'mm.hunter-shark',
    name: 'Hunter Shark',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 12,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 33, Monster Manual: 330'
  },
  {
    guid: '3e632ad7-8ffd-40b7-8a37-45386577bd45',
    fid: 'mm.hydra',
    name: 'Hydra',
    cr: 8,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'aquatic, cave, coast, dungeon, forest, mountain, ruins, swamp, underground',
    ac: 15,
    hp: 172,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 33, Monster Manual: 190'
  },
  {
    guid: '589c5ae5-bbbe-4753-a36a-53bfec6987b7',
    fid: 'mm.hyena',
    name: 'Hyena',
    cr: 0,
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 11,
    hp: 5,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 33, Monster Manual: 331'
  },
  {
    guid: 'ccc0b0de-f4e8-418d-b354-dbf6968413ee',
    fid: 'mm.ice-devil',
    name: 'Ice Devil',
    cr: 14,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 180,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 75'
  },
  {
    guid: '85ca0247-73d6-43c3-b25d-4a9026586669',
    fid: 'mm.ice-mephit',
    name: 'Ice Mephit',
    cr: '1/2',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'arctic, dungeon, planar, urban',
    ac: 11,
    hp: 21,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 215, Princes of the Apocalypse Online Supplement v1.0: 20'
  },
  {
    guid: 'c211c5fc-f71a-4e11-8c8b-71a6ae470eaa',
    fid: 'mm.imp',
    name: 'Imp',
    cr: 1,
    size: 'Tiny',
    type: 'Fiend',
    tags: 'Devil, Shapechanger',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 13,
    hp: 10,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 76, Player\'s Handbook: 306'
  },
  {
    guid: '8ccb4fb2-f8aa-421d-a60e-76255953b97a',
    fid: 'mm.intellect-devourer',
    name: 'Intellect Devourer',
    cr: 2,
    size: 'Tiny',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon, underground',
    ac: 12,
    hp: 21,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 191'
  },
  {
    guid: 'ee83fc27-4e79-4a9d-96ec-909aed739470',
    fid: 'mm.invisible-stalker',
    name: 'Invisible Stalker',
    cr: 6,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground, urban',
    ac: 14,
    hp: 104,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 192, Princes of the Apocalypse Online Supplement v1.0: 21'
  },
  {
    guid: 'd9f13daf-d1b1-490c-87a7-33e8d28bb4ee',
    fid: 'mm.iron-golem',
    name: 'Iron Golem',
    cr: 16,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: 'Golems',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 20,
    hp: 210,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 170'
  },
  {
    guid: 'd8e388da-90a9-48b5-8d84-f969f5b98a73',
    fid: 'mm.jackal',
    name: 'Jackal',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'desert, grassland',
    ac: 12,
    hp: 3,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 33, Monster Manual: 331'
  },
  {
    guid: '977db5ba-5f5a-4a4d-851a-854696a05c01',
    fid: 'mm.jackalwere',
    name: 'Jackalwere',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Shapechanger',
    section: '',
    alignment: 'chaotic evil',
    environment: 'desert, grassland, urban',
    ac: 12,
    hp: 18,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 193, Princes of the Apocalypse Online Supplement v1.0: 21'
  },
  {
    guid: '0d1a69f3-7416-4bd7-9166-4e7b0fdd0af3',
    fid: 'mm.kenku',
    name: 'Kenku',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Kenku',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'arctic, coast, desert, forest, grassland, mountain, ruins, urban',
    ac: 13,
    hp: 13,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 194, Princes of the Apocalypse Online Supplement v1.0: 21'
  },
  {
    guid: '7ff7ffda-0e4d-4989-9c61-455178874d43',
    fid: 'mm.killer-whale',
    name: 'Killer Whale',
    cr: 3,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 12,
    hp: 90,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 34, Monster Manual: 331'
  },
  {
    guid: '9094f81d-5fab-43c6-baf5-4a5acbe94b9b',
    fid: 'mm.knight',
    name: 'Knight',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 18,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 54, HotDQ supplement: 12, Monster Manual: 347'
  },
  {
    guid: 'baa3449e-bfd5-408d-8b7e-206a3bebf8ca',
    fid: 'mm.kobold',
    name: 'Kobold',
    cr: '1/8',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: 'Kobolds',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, mountain, underground',
    ac: 12,
    hp: 5,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 34, HotDQ supplement: 13, Monster Manual: 195'
  },
  {
    guid: 'db248a11-5c00-433b-91e5-606ac09a3df9',
    fid: 'mm.kraken',
    name: 'Kraken',
    cr: 23,
    size: 'Gargantuan',
    type: 'Monstrosity',
    tags: 'Titan',
    section: '',
    alignment: 'chaotic evil',
    environment: 'aquatic, coast',
    ac: 18,
    hp: 472,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 197'
  },
  {
    guid: '5bb857a3-c242-4b8e-9ad7-08134220a539',
    fid: 'mm.kuo-toa',
    name: 'Kuo-toa',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Kuo-toa',
    section: 'Kuo-toa',
    alignment: 'neutral evil',
    environment: 'aquatic, coast',
    ac: 13,
    hp: 18,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 199, Princes of the Apocalypse Online Supplement v1.0: 22'
  },
  {
    guid: 'ef27b3eb-48e1-4415-a176-5df27a41d84f',
    fid: 'mm.kuo-toa-archpriest',
    name: 'Kuo-toa Archpriest',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Kuo-toa',
    section: 'Kuo-toa',
    alignment: 'neutral evil',
    environment: 'aquatic, coast',
    ac: 13,
    hp: 97,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 200, Princes of the Apocalypse Online Supplement v1.0: 22'
  },
  {
    guid: '',
    fid: 'mm.kuo-toa-monitor',
    name: 'Kuo-toa Monitor',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Kuo-toa',
    section: 'Kuo-toa',
    alignment: 'neutral evil',
    environment: 'aquatic, coast',
    ac: 13,
    hp: 65,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 198'
  },
  {
    guid: '18f26ee3-cb25-4a4b-a37e-eeef773cb3ad',
    fid: 'mm.kuo-toa-whip',
    name: 'Kuo-toa Whip',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Kuo-toa',
    section: 'Kuo-toa',
    alignment: 'neutral evil',
    environment: 'aquatic, coast',
    ac: 11,
    hp: 65,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 200, Princes of the Apocalypse Online Supplement v1.0: 23'
  },
  {
    guid: '78334f11-4007-4c8d-a133-55c8581d13bf',
    fid: 'mm.lamia',
    name: 'Lamia',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'desert, ruins',
    ac: 13,
    hp: 97,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 201'
  },
  {
    guid: 'a423f6af-82f8-4716-9a32-03e6c21679b1',
    fid: 'mm.lemure',
    name: 'Lemure',
    cr: 0,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 7,
    hp: 13,
    init: -3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 76'
  },
  {
    guid: 'a5dc844c-9763-4ce9-81e9-c83ec00976af',
    fid: 'mm.lich',
    name: 'Lich',
    cr: 21,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'any evil',
    environment: 'dungeon, ruins, urban',
    ac: 17,
    hp: 135,
    init: 3,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 202, Princes of the Apocalypse Online Supplement v1.0: 23'
  },
  {
    guid: '3a25a6fc-7a1d-4437-9010-213e7a9ec153',
    fid: 'mm.lich-in-lair',
    name: 'Lich (in lair)',
    cr: 22,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'any evil',
    environment: 'dungeon, ruins, urban',
    ac: 17,
    hp: 135,
    init: 3,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 202'
  },
  {
    guid: '2feb4531-2954-456c-bdf2-b320b1b8d1a3',
    fid: 'mm.lion',
    name: 'Lion',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 12,
    hp: 26,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 34, Monster Manual: 331, Player\'s Handbook: 307'
  },
  {
    guid: 'c9c0dd9a-d2ce-449c-9bd8-46da79cacdc3',
    fid: 'mm.lizard',
    name: 'Lizard',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground',
    ac: 10,
    hp: 2,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 34, Monster Manual: 332'
  },
  {
    guid: '9e448cc3-7e92-4af8-8610-601efd00aef8',
    fid: 'mm.lizard-kingqueen',
    name: 'Lizard King/Queen',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Lizardfolk',
    section: 'Lizardfolk',
    alignment: 'chaotic evil',
    environment: 'coast, dungeon, forest, grassland, swamp',
    ac: 15,
    hp: 75,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 205, Princes of the Apocalypse Online Supplement v1.0: 24'
  },
  {
    guid: '5ec95d84-a847-496d-9cca-f4a3c13a81d1',
    fid: 'mm.lizardfolk',
    name: 'Lizardfolk',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Lizardfolk',
    section: 'Lizardfolk',
    alignment: 'neutral',
    environment: 'coast, dungeon, forest, grassland, swamp',
    ac: 15,
    hp: 22,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, HotDQ supplement: 13, Monster Manual: 204'
  },
  {
    guid: 'e94dadeb-692d-43cf-8d59-91f1624c795d',
    fid: 'mm.lizardfolk-shaman',
    name: 'Lizardfolk Shaman',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Lizardfolk',
    section: 'Lizardfolk',
    alignment: 'neutral',
    environment: 'coast, dungeon, forest, grassland, swamp',
    ac: 13,
    hp: 27,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 205, Princes of the Apocalypse Online Supplement v1.0: 24'
  },
  {
    guid: 'dd58be51-e15c-4abb-8cf4-97263526d5d5',
    fid: 'mm.mage',
    name: 'Mage',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: '12 (15 with mage armor)',
    hp: 40,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 55, HotDQ supplement: 13, Monster Manual: 347'
  },
  {
    guid: '1f7aa396-edd4-47f6-88b6-f98793df12e5',
    fid: 'mm.magma-mephit',
    name: 'Magma Mephit',
    cr: '1/2',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'cave, dungeon, mountain, planar, urban',
    ac: 11,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 216, Princes of the Apocalypse Online Supplement v1.0: 25'
  },
  {
    guid: 'f9b4996c-a11c-4f18-ab8b-fa627f384da7',
    fid: 'mm.magmin',
    name: 'Magmin',
    cr: '1/2',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'dungeon, planar, ruins, urban',
    ac: 14,
    hp: 9,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 212, Princes of the Apocalypse Online Supplement v1.0: 25'
  },
  {
    guid: '723492cc-2dc4-4c3e-b5a3-96b03dd30320',
    fid: 'mm.mammoth',
    name: 'Mammoth',
    cr: 6,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic',
    ac: 13,
    hp: 126,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, Monster Manual: 332'
  },
  {
    guid: '22a8a7af-8674-4308-9c7c-da4d02965093',
    fid: 'mm.manes',
    name: 'Manes',
    cr: '1/8',
    size: 'Small',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 9,
    hp: 9,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 60'
  },
  {
    guid: '70a0cbe2-5320-465d-afdf-7a4643da02fe',
    fid: 'mm.manticore',
    name: 'Manticore',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'arctic, cave, coast, grassland, mountain, ruins',
    ac: 14,
    hp: 68,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, Monster Manual: 213'
  },
  {
    guid: 'b30c6226-cb44-4070-ba78-12bfcf3e8d9e',
    fid: 'mm.marid',
    name: 'Marid',
    cr: 11,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Genies',
    alignment: 'chaotic neutral',
    environment: 'aquatic, coast, dungeon, planar, swamp',
    ac: 17,
    hp: 229,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 146'
  },
  {
    guid: '8e3f174d-372b-40b5-ac07-c5e4651d410c',
    fid: 'mm.marilith',
    name: 'Marilith',
    cr: 16,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 189,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 61'
  },
  {
    guid: '216f1424-504a-4c7f-8a0a-478bbf97f1ef',
    fid: 'mm.mastiff',
    name: 'Mastiff',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 12,
    hp: 5,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, Monster Manual: 332, Player\'s Handbook: 307'
  },
  {
    guid: '15a09594-1143-4b08-8f4e-0f5d9891b4d1',
    fid: 'mm.medusa',
    name: 'Medusa',
    cr: 6,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, mountain, ruins, urban',
    ac: 15,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 36, Monster Manual: 214'
  },
  {
    guid: '620fe04b-ad34-4183-8af7-980b3609c482',
    fid: 'mm.merfolk',
    name: 'Merfolk',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Merfolk',
    section: '',
    alignment: 'neutral',
    environment: 'aquatic',
    ac: 11,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, Monster Manual: 218'
  },
  {
    guid: '8e619f31-7241-4022-91a2-de617597b090',
    fid: 'mm.merrow',
    name: 'Merrow',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'aquatic',
    ac: 13,
    hp: 45,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 219, Princes of the Apocalypse Online Supplement v1.0: 25'
  },
  {
    guid: '911a2153-0fc4-48bd-bb68-4d36ef72cb3c',
    fid: 'mm.mezzoloth',
    name: 'Mezzoloth',
    cr: 5,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'dungeon, planar',
    ac: 8,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 313, Princes of the Apocalypse Online Supplement v1.0: 26'
  },
  {
    guid: 'ef5521a7-9b12-498b-907d-18acfdf73876',
    fid: 'mm.mimic',
    name: 'Mimic',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon',
    ac: 12,
    hp: 58,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 220'
  },
  {
    guid: '00235a58-a598-495b-ba4e-7d56c15cbfc8',
    fid: 'mm.mind-flayer',
    name: 'Mind Flayer',
    cr: 7,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Mind Flayer',
    alignment: 'lawful evil',
    environment: 'dungeon, underground, urban',
    ac: 15,
    hp: 71,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 222'
  },
  {
    guid: '699c0e8b-def1-41ff-a1bc-23d5315c973e',
    fid: 'mm.mind-flayer-arcanist',
    name: 'Mind Flayer Arcanist',
    cr: 8,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Mind Flayer',
    alignment: 'lawful evil',
    environment: 'dungeon, underground, urban',
    ac: 15,
    hp: 71,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 222'
  },
  {
    guid: '852cfe83-a0d9-4170-86d7-e16b864ea7ef',
    fid: 'mm.minotaur',
    name: 'Minotaur',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, ruins, underground',
    ac: 14,
    hp: 76,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 35, Monster Manual: 223'
  },
  {
    guid: '8e06aaf8-6a32-44d7-8c40-0b829ac94413',
    fid: 'mm.minotaur-skeleton',
    name: 'Minotaur Skeleton',
    cr: 2,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Skeletons',
    alignment: 'lawful evil',
    environment: 'dungeon, underground, urban',
    ac: 12,
    hp: 67,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 273'
  },
  {
    guid: '36cf9acb-d215-4366-b8d8-cdd283bcaf82',
    fid: 'mm.monodrone',
    name: 'Monodrone',
    cr: '1/8',
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Modrons',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 15,
    hp: 5,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 224'
  },
  {
    guid: 'edc88a1f-98d9-44f8-b376-e3b11cb24401',
    fid: 'mm.mud-mephit',
    name: 'Mud Mephit',
    cr: '1/4',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'coast, dungeon, forest, planar, swamp, urban',
    ac: 11,
    hp: 27,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 216, Princes of the Apocalypse Online Supplement v1.0: 26'
  },
  {
    guid: '40e39910-dd75-41ac-a5b2-217d3d4e81de',
    fid: 'mm.mule',
    name: 'Mule',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 10,
    hp: 11,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 37, Monster Manual: 333, Player\'s Handbook: 307'
  },
  {
    guid: 'd86cd0d2-a792-4585-809e-e59919189936',
    fid: 'mm.mummy',
    name: 'Mummy',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Mummies',
    alignment: 'lawful evil',
    environment: 'desert, dungeon',
    ac: 11,
    hp: 58,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 37, Monster Manual: 228'
  },
  {
    guid: 'f210a3a6-0a2d-4d9c-9ab0-0e837eb33749',
    fid: 'mm.mummy-lord',
    name: 'Mummy Lord',
    cr: 15,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Mummies',
    alignment: 'lawful evil',
    environment: 'desert, dungeon',
    ac: 17,
    hp: 97,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 229'
  },
  {
    guid: '13628cc9-ab44-4869-8bfd-cf155aca78c7',
    fid: 'mm.myconid-adult',
    name: 'Myconid Adult',
    cr: '1/2',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Myconids',
    alignment: 'lawful neutral',
    environment: 'underground',
    ac: 12,
    hp: 22,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 232'
  },
  {
    guid: '168dfe89-609f-4112-8925-25595fe88acd',
    fid: 'mm.myconid-sovereign',
    name: 'Myconid Sovereign',
    cr: 2,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: 'Myconids',
    alignment: 'lawful neutral',
    environment: 'underground',
    ac: 13,
    hp: 60,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 232'
  },
  {
    guid: '4704981f-beb2-4b2a-98ce-30901162fe10',
    fid: 'mm.myconid-sprout',
    name: 'Myconid Sprout',
    cr: 0,
    size: 'Small',
    type: 'Plant',
    tags: '',
    section: 'Myconids',
    alignment: 'lawful neutral',
    environment: 'underground',
    ac: 10,
    hp: 7,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 230'
  },
  {
    guid: 'fea2cf64-fbc7-45e9-b901-ede53d696bf3',
    fid: 'mm.nalfeshnee',
    name: 'Nalfeshnee',
    cr: 13,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 184,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 62'
  },
  {
    guid: '87ae63b6-4be9-43d7-a096-5556018c5999',
    fid: 'mm.needle-blight',
    name: 'Needle Blight',
    cr: '1/4',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Blights',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp',
    ac: 12,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 32'
  },
  {
    guid: 'c419ea32-d524-462f-b65d-e88641fa177c',
    fid: 'mm.night-hag',
    name: 'Night Hag',
    cr: 5,
    size: 'Medium',
    type: 'Fiend',
    tags: '',
    section: 'Hags',
    alignment: 'neutral evil',
    environment: 'planar, underground',
    ac: 17,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 178'
  },
  {
    guid: 'ff6fee89-50c4-4bbb-a2b0-07646c55b4e2',
    fid: 'mm.night-hag-coven',
    name: 'Night Hag (coven)',
    cr: 7,
    size: 'Medium',
    type: 'Fiend',
    tags: '',
    section: 'Hags',
    alignment: 'neutral evil',
    environment: 'planar, underground',
    ac: 17,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 178'
  },
  {
    guid: '4ecef288-1f16-48b2-be23-f36b7fde5ced',
    fid: 'mm.nightmare',
    name: 'Nightmare',
    cr: 3,
    size: 'Large',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'planar, swamp',
    ac: 13,
    hp: 68,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 235'
  },
  {
    guid: 'f9a076f4-da7e-457a-912e-9fe8d752ef3e',
    fid: 'mm.noble',
    name: 'Noble',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 15,
    hp: 9,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 13, Monster Manual: 348, Princes of the Apocalypse Online Supplement v1.0: 26'
  },
  {
    guid: 'ed0bb2d0-aef6-4a4b-a9cb-4a38045cc29c',
    fid: 'mm.nothic',
    name: 'Nothic',
    cr: 2,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins, urban',
    ac: 15,
    hp: 45,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 37, Monster Manual: 236'
  },
  {
    guid: '4ec385e4-55ea-4d7b-af20-513227375d22',
    fid: 'mm.nycaloth',
    name: 'Nycaloth',
    cr: 9,
    size: 'Large',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'dungeon, planar',
    ac: 18,
    hp: 123,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 314, Princes of the Apocalypse Online Supplement v1.0: 27'
  },
  {
    guid: '0474b4f6-5b72-46ac-9405-5e39b28750c2',
    fid: 'mm.ochre-jelly',
    name: 'Ochre Jelly',
    cr: 2,
    size: 'Large',
    type: 'Ooze',
    tags: '',
    section: 'Oozes',
    alignment: 'unaligned',
    environment: 'dungeon',
    ac: 8,
    hp: 45,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 38, Monster Manual: 243'
  },
  {
    guid: '61154806-be2a-432b-953f-cd660073496b',
    fid: 'mm.octopus',
    name: 'Octopus',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 12,
    hp: 3,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 38, Monster Manual: 333'
  },
  {
    guid: '545c030a-1674-4838-9d43-752db8265f87',
    fid: 'mm.ogre',
    name: 'Ogre',
    cr: 2,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 11,
    hp: 59,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 38, HotDQ supplement: 14, Monster Manual: 237'
  },
  {
    guid: '563daf80-0285-48c5-908b-2dc607550125',
    fid: 'mm.ogre-zombie',
    name: 'Ogre Zombie',
    cr: 2,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'neutral evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 8,
    hp: 85,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 316'
  },
  {
    guid: '06db3698-ec58-48d4-867a-f6fdd875823b',
    fid: 'mm.oni',
    name: 'Oni',
    cr: 7,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon, grassland, ruins, urban',
    ac: 16,
    hp: 110,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 239, Princes of the Apocalypse Online Supplement v1.0: 27'
  },
  {
    guid: '2fc71e84-7157-4d3f-9042-d9b17236f5f0',
    fid: 'mm.orc',
    name: 'Orc',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 13,
    hp: 15,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 39, HotDQ supplement: 14, Monster Manual: 246'
  },
  {
    guid: '18e67457-97e5-4efc-bb6c-084a910fbbd1',
    fid: 'mm.orc-eye-of-gruumsh',
    name: 'Orc Eye of Gruumsh',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 16,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 247, Princes of the Apocalypse Online Supplement v1.0: 28'
  },
  {
    guid: '21ebdbb0-2305-4922-ae09-3c73eab5fb06',
    fid: 'mm.orc-war-chief',
    name: 'Orc War Chief',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 16,
    hp: 93,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 246'
  },
  {
    guid: '33072b76-ec36-4c54-9318-83d04d6ef44d',
    fid: 'mm.orog',
    name: 'Orog',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'arctic, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, urban',
    ac: 18,
    hp: 42,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 247, Princes of the Apocalypse Online Supplement v1.0: 28'
  },
  {
    guid: '9dca97ac-f3fa-4be6-8a39-09291505f159',
    fid: 'mm.otyugh',
    name: 'Otyugh',
    cr: 5,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'cave, dungeon, ruins, swamp, underground',
    ac: 14,
    hp: 114,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 14, Monster Manual: 248'
  },
  {
    guid: '5b060b59-1142-4d65-9958-212dbce27820',
    fid: 'mm.owl',
    name: 'Owl',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, urban',
    ac: 11,
    hp: 1,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 39, Monster Manual: 333, Player\'s Handbook: 308'
  },
  {
    guid: '293ad61d-ee17-4a20-954a-ab22f6edaa8d',
    fid: 'mm.owlbear',
    name: 'Owlbear',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest, mountain',
    ac: 13,
    hp: 59,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 39, Monster Manual: 249'
  },
  {
    guid: '777fa0ec-7cd3-497e-9a64-d5bf89ac6216',
    fid: 'mm.panther',
    name: 'Panther',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 39, Monster Manual: 333, Player\'s Handbook: 308'
  },
  {
    guid: '9f07db07-b9f9-4913-8603-3aeef1b217f3',
    fid: 'mm.pegasus',
    name: 'Pegasus',
    cr: 2,
    size: 'Large',
    type: 'Celestial',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'mountain',
    ac: 12,
    hp: 59,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 40, Monster Manual: 250'
  },
  {
    guid: '8d7daed1-b832-454d-86cf-be5c99570402',
    fid: 'mm.pentadrone',
    name: 'Pentadrone',
    cr: 2,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: 'Modrons',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 16,
    hp: 32,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 226'
  },
  {
    guid: 'df1f8a95-9f6b-4699-af85-da4368f16cfd',
    fid: 'mm.peryton',
    name: 'Peryton',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 13,
    hp: 33,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 15, Monster Manual: 251, Princes of the Apocalypse Online Supplement v1.0: 28'
  },
  {
    guid: '9f50717c-0766-4e2e-b218-7605ccb0d945',
    fid: 'mm.phase-spider',
    name: 'Phase Spider',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, coast, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 13,
    hp: 32,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 40, Monster Manual: 334'
  },
  {
    guid: 'cc49ec49-caa4-404b-92e6-9e7b4604cb12',
    fid: 'mm.piercer',
    name: 'Piercer',
    cr: '1/2',
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 15,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 252, Princes of the Apocalypse Online Supplement v1.0: 28'
  },
  {
    guid: '14742ff6-ed38-44f5-8e11-4ac74b20ba02',
    fid: 'mm.pit-fiend',
    name: 'Pit Fiend',
    cr: 20,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 19,
    hp: 300,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 77'
  },
  {
    guid: 'd851b9c3-0c0e-4378-a991-9b0d05c80931',
    fid: 'mm.pixie',
    name: 'Pixie',
    cr: '1/4',
    size: 'Tiny',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'neutral good',
    environment: 'coast, forest, grassland, swamp',
    ac: 15,
    hp: 1,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 253'
  },
  {
    guid: '7de4388c-e067-4308-b02d-84ecb296aa6e',
    fid: 'mm.planetar',
    name: 'Planetar',
    cr: 16,
    size: 'Large',
    type: 'Celestial',
    tags: '',
    section: 'Angels',
    alignment: 'lawful good',
    environment: 'planar',
    ac: 19,
    hp: 200,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 17'
  },
  {
    guid: '633bf055-b509-4f7f-b4ca-5c14bbfeb919',
    fid: 'mm.plesiosaurus',
    name: 'Plesiosaurus',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 13,
    hp: 68,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 40, Monster Manual: 80'
  },
  {
    guid: 'e23e499c-9022-482c-ae28-21790a692aab',
    fid: 'mm.poisonous-snake',
    name: 'Poisonous Snake',
    cr: '1/8',
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'desert, forest, grassland, swamp',
    ac: 13,
    hp: 2,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 40, Monster Manual: 334, Player\'s Handbook: 308'
  },
  {
    guid: 'f2a7ac7e-972f-4956-908e-525eec2ae47c',
    fid: 'mm.polar-bear',
    name: 'Polar Bear',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic, cave',
    ac: 12,
    hp: 42,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 41, Monster Manual: 334'
  },
  {
    guid: 'ed0ed3ff-2489-4a07-88f6-1e5e9b33580d',
    fid: 'mm.poltergeist',
    name: 'Poltergeist',
    cr: 2,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Specter',
    alignment: 'chaotic evil',
    environment: 'dungeon, ruins, urban',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 279'
  },
  {
    guid: '18f9d16d-6151-45fc-b449-5ca10e66f49e',
    fid: 'mm.pony',
    name: 'Pony',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 10,
    hp: 11,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 41, Monster Manual: 335'
  },
  {
    guid: 'cc9e3c32-1c13-40a5-9d06-0ded81c4e3cb',
    fid: 'mm.priest',
    name: 'Priest',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 13,
    hp: 27,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 55, HotDQ supplement: 15, Monster Manual: 348'
  },
  {
    guid: 'ada98bc9-cb5c-4f02-8230-a349f85df99c',
    fid: 'mm.pseudodragon',
    name: 'Pseudodragon',
    cr: '1/4',
    size: 'Tiny',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'neutral good',
    environment: 'urban',
    ac: 13,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 254, Player\'s Handbook: 308'
  },
  {
    guid: '2bbec9af-7f96-40ed-84c8-9f5c98ae38c3',
    fid: 'mm.pteranodon',
    name: 'Pteranodon',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'mountain',
    ac: 13,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 41, Monster Manual: 80'
  },
  {
    guid: '0de2b8ec-9d78-497b-83a3-e7eb530a4c85',
    fid: 'mm.purple-worm',
    name: 'Purple Worm',
    cr: 15,
    size: 'Gargantuan',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'underground',
    ac: 18,
    hp: 247,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 255, Princes of the Apocalypse Online Supplement v1.0: 29'
  },
  {
    guid: '06adb243-3e58-4b29-8fbe-34331bcb1b3e',
    fid: 'mm.quadrone',
    name: 'Quadrone',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Modrons',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 16,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 226'
  },
  {
    guid: '0ff27a87-12ee-4196-a998-8d41c2ae1ee1',
    fid: 'mm.quaggoth',
    name: 'Quaggoth',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Quaggoth',
    section: 'Quaggoth',
    alignment: 'chaotic neutral',
    environment: 'dungeon, urban',
    ac: 13,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 256'
  },
  {
    guid: 'fdecca35-c5ec-462b-958c-6c3f3b676158',
    fid: 'mm.quaggoth-spore-servant',
    name: 'Quaggoth Spore Servant',
    cr: 1,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Myconids',
    alignment: 'unaligned',
    environment: 'underground',
    ac: 13,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 230'
  },
  {
    guid: 'd9e9e29a-ba95-4740-86a8-927843168eb8',
    fid: 'mm.quaggoth-thonot',
    name: 'Quaggoth Thonot',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Quaggoth',
    section: 'Quaggoth',
    alignment: 'chaotic neutral',
    environment: 'dungeon, urban',
    ac: 13,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 256'
  },
  {
    guid: '05541b29-3f73-45f4-974c-6aabd25b4708',
    fid: 'mm.quasit',
    name: 'Quasit',
    cr: 1,
    size: 'Tiny',
    type: 'Fiend',
    tags: 'Demon, Shapechanger',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 13,
    hp: 7,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 63, Player\'s Handbook: 309'
  },
  {
    guid: '33980992-7042-4c16-8ffb-d784114520d6',
    fid: 'mm.quipper',
    name: 'Quipper',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 13,
    hp: 1,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 41, Monster Manual: 335'
  },
  {
    guid: '018d5cba-4e7e-4519-b181-fb0bb7bf4474',
    fid: 'mm.rakshasa',
    name: 'Rakshasa',
    cr: 13,
    size: 'Medium',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'planar, urban',
    ac: 16,
    hp: 110,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 257'
  },
  {
    guid: 'b01dae8d-020f-4ee2-a80a-de7cb133de53',
    fid: 'mm.rat',
    name: 'Rat',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic, cave, coast, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 10,
    hp: 1,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 41, Monster Manual: 335, Player\'s Handbook: 309'
  },
  {
    guid: 'cda02d73-dca4-45bb-92cb-ad2092a27ea5',
    fid: 'mm.raven',
    name: 'Raven',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 12,
    hp: 1,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 42, Monster Manual: 335, Player\'s Handbook: 309'
  },
  {
    guid: 'e5d8b994-96ce-48e8-9cf2-5453b0d84959',
    fid: 'mm.red-dragon-wyrmling',
    name: 'Red Dragon Wyrmling',
    cr: 4,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 17,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 98'
  },
  {
    guid: '8ff160ae-eb45-4464-a436-e40c751cad1e',
    fid: 'mm.red-slaad',
    name: 'Red Slaad',
    cr: 5,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Slaadi',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 14,
    hp: 93,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 276'
  },
  {
    guid: '75d4dfd5-8942-4661-b8a8-3fbcaec7d1ab',
    fid: 'mm.reef-shark',
    name: 'Reef Shark',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 12,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 42, Monster Manual: 336, Player\'s Handbook: 309'
  },
  {
    guid: '148bab3f-70b0-45bb-af68-df93ae89c77f',
    fid: 'mm.remorhaz',
    name: 'Remorhaz',
    cr: 11,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: 'Remorhazes',
    alignment: 'unaligned',
    environment: 'arctic',
    ac: 17,
    hp: 195,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 258'
  },
  {
    guid: '89ebcb90-c381-450d-a303-e1cb0f470a6a',
    fid: 'mm.revenant',
    name: 'Revenant',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 13,
    hp: 136,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 259, Princes of the Apocalypse Online Supplement v1.0: 29'
  },
  {
    guid: '73254529-1dde-443a-9cbd-940157812fb1',
    fid: 'mm.rhinoceros',
    name: 'Rhinoceros',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 11,
    hp: 45,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 42, Monster Manual: 336'
  },
  {
    guid: 'e02034e2-fe58-4c02-bdb6-9a54baf6e86b',
    fid: 'mm.riding-horse',
    name: 'Riding Horse',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 10,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 42, Monster Manual: 336, Player\'s Handbook: 310'
  },
  {
    guid: '77263f03-1681-4684-b9fe-8c089d8721a7',
    fid: 'mm.roc',
    name: 'Roc',
    cr: 11,
    size: 'Gargantuan',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'coast, desert, mountain',
    ac: 15,
    hp: 248,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 260'
  },
  {
    guid: '8e7f5fd8-557c-4176-aee8-2a894afb6b99',
    fid: 'mm.roper',
    name: 'Roper',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'cave, underground',
    ac: 20,
    hp: 93,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 15, Monster Manual: 261, Princes of the Apocalypse Online Supplement v1.0: 30'
  },
  {
    guid: '5defa2f6-bdb1-4439-8e3f-a0fe7ae78e31',
    fid: 'mm.rug-of-smothering',
    name: 'Rug of Smothering',
    cr: 2,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: 'Animated Objects',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 12,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 16, Monster Manual: 20'
  },
  {
    guid: '0245be52-98ed-441c-b7b6-823866ac50b0',
    fid: 'mm.rust-monster',
    name: 'Rust Monster',
    cr: '1/2',
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'dungeon, underground',
    ac: 14,
    hp: 27,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 262, Princes of the Apocalypse Online Supplement v1.0: 30'
  },
  {
    guid: 'e8fb3a4e-52e3-4519-bfe4-2710fca7aca2',
    fid: 'mm.saber-toothed-tiger',
    name: 'Saber-Toothed Tiger',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 12,
    hp: 52,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 43, Monster Manual: 336'
  },
  {
    guid: 'cf012d1a-c0c3-4426-afca-8ef34b863071',
    fid: 'mm.sahuagin',
    name: 'Sahuagin',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Sahuagin',
    section: 'Sahuagin',
    alignment: 'lawful evil',
    environment: 'aquatic, coast',
    ac: 12,
    hp: 22,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 263'
  },
  {
    guid: '791f3452-b13b-417b-923b-137e2f2073fa',
    fid: 'mm.sahuagin-baron',
    name: 'Sahuagin Baron',
    cr: 5,
    size: 'Large',
    type: 'Humanoid',
    tags: 'Sahuagin',
    section: 'Sahuagin',
    alignment: 'lawful evil',
    environment: 'aquatic, coast',
    ac: 16,
    hp: 76,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 264'
  },
  {
    guid: 'c234a705-22a1-423d-99d2-73699bb0b58e',
    fid: 'mm.sahuagin-priestess',
    name: 'Sahuagin Priestess',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Sahuagin',
    section: 'Sahuagin',
    alignment: 'lawful evil',
    environment: 'aquatic, coast',
    ac: 12,
    hp: 33,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 264'
  },
  {
    guid: '15cade7d-8730-49a3-bd2b-643a9a62d8c4',
    fid: 'mm.salamander',
    name: 'Salamander',
    cr: 5,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Salamanders',
    alignment: 'neutral evil',
    environment: 'forest, planar',
    ac: 15,
    hp: 90,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 266, Princes of the Apocalypse Online Supplement v1.0: 30'
  },
  {
    guid: 'f373bc88-ba01-4e6a-8889-580be0265c59',
    fid: 'mm.satyr',
    name: 'Satyr',
    cr: '1/2',
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'forest, grassland, urban',
    ac: 14,
    hp: 31,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 43, Monster Manual: 267'
  },
  {
    guid: '24d24844-c27d-4261-b71e-bdca6de3f2f7',
    fid: 'mm.scarecrow',
    name: 'Scarecrow',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'grassland, urban',
    ac: 11,
    hp: 36,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 268'
  },
  {
    guid: 'd30a64ee-ac17-42e5-99ff-2186111615ad',
    fid: 'mm.scorpion',
    name: 'Scorpion',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, desert, grassland, mountain, swamp',
    ac: 11,
    hp: 1,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 43, Monster Manual: 337'
  },
  {
    guid: '14f5f317-1640-4254-b8d7-4d2dadb97394',
    fid: 'mm.scout',
    name: 'Scout',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, coast, desert, forest, grassland, mountain, swamp',
    ac: 13,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 16, Monster Manual: 349, Princes of the Apocalypse Online Supplement v1.0: 31'
  },
  {
    guid: '6e15161a-e1b2-4a0c-94b0-db75d2e6ccb3',
    fid: 'mm.sea-hag',
    name: 'Sea Hag',
    cr: 2,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'chaotic evil',
    environment: 'aquatic, coast, underground',
    ac: 14,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 179, Princes of the Apocalypse Online Supplement v1.0: 31'
  },
  {
    guid: '0f788005-3e4c-47b0-8ffe-8266c424d825',
    fid: 'mm.sea-hag-coven',
    name: 'Sea Hag (coven)',
    cr: 4,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'chaotic evil',
    environment: 'aquatic, coast, underground',
    ac: 14,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 179'
  },
  {
    guid: 'ec2f7099-286e-4384-990a-526cf70b1c55',
    fid: 'mm.sea-horse',
    name: 'Sea Horse',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 11,
    hp: 1,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 43, Monster Manual: 337'
  },
  {
    guid: 'c4551c2c-c686-4105-83f6-87c873f8809f',
    fid: 'mm.shadow',
    name: 'Shadow',
    cr: '1/2',
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'dungeon, ruins, swamp, urban',
    ac: 12,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 269, Princes of the Apocalypse Online Supplement v1.0: 31'
  },
  {
    guid: 'd356934b-d78b-49bf-9ce0-b9fb9946eb64',
    fid: 'mm.shadow-demon',
    name: 'Shadow Demon',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 13,
    hp: 66,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 64, Princes of the Apocalypse Online Supplement v1.0: 32'
  },
  {
    guid: 'ea9c253b-1fe2-4e66-9331-61f6e3b02336',
    fid: 'mm.shambling-mound',
    name: 'Shambling Mound',
    cr: 5,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest, swamp',
    ac: 15,
    hp: 136,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 16, Monster Manual: 270'
  },
  {
    guid: '212a9d4e-60b7-4c1e-ac1c-67f26855e878',
    fid: 'mm.shield-guardian',
    name: 'Shield Guardian',
    cr: 7,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 17,
    hp: 142,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 271, Princes of the Apocalypse Online Supplement v1.0: 32'
  },
  {
    guid: '3aa695ea-6f7e-49bb-aef4-3153347c0fcc',
    fid: 'mm.shrieker',
    name: 'Shrieker',
    cr: 0,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Fungi',
    alignment: 'unaligned',
    environment: 'cave, dungeon, swamp',
    ac: 5,
    hp: 13,
    init: -5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 138'
  },
  {
    guid: 'def8e263-2dae-4ec5-b3b1-6150011ec485',
    fid: 'mm.silver-dragon-wyrmling',
    name: 'Silver Dragon Wyrmling',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'arctic, mountain',
    ac: 17,
    hp: 45,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 118'
  },
  {
    guid: '3e946348-1cc2-405f-9975-5a4c09445750',
    fid: 'mm.skeleton',
    name: 'Skeleton',
    cr: '1/4',
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Skeletons',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins, urban',
    ac: 13,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 43, Monster Manual: 272, Player\'s Handbook: 310'
  },
  {
    guid: '68b382e5-a0a4-4b9b-bc57-d9684105296c',
    fid: 'mm.slaad-tadpole',
    name: 'Slaad Tadpole',
    cr: '1/8',
    size: 'Tiny',
    type: 'Aberration',
    tags: '',
    section: 'Slaadi',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 12,
    hp: 10,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 276'
  },
  {
    guid: 'bdda67cb-ae81-4107-860a-eee6456ea92b',
    fid: 'mm.smoke-mephit',
    name: 'Smoke Mephit',
    cr: '1/4',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'dungeon, planar, urban',
    ac: 12,
    hp: 2,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 217, Princes of the Apocalypse Online Supplement v1.0: 32'
  },
  {
    guid: '6137fe73-562f-411b-8654-1e2d057a8da8',
    fid: 'mm.solar',
    name: 'Solar',
    cr: 21,
    size: 'Large',
    type: 'Celestial',
    tags: '',
    section: 'Angels',
    alignment: 'lawful good',
    environment: 'planar',
    ac: 21,
    hp: 241,
    init: 6,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 18'
  },
  {
    guid: 'aadb1915-c3d4-4349-b9f2-5b7a10ab3f3d',
    fid: 'mm.spectator',
    name: 'Spectator',
    cr: 3,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful neutral',
    environment: 'cave, dungeon, ruins, underground, urban',
    ac: 14,
    hp: 39,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 44, Monster Manual: 30'
  },
  {
    guid: '3a005074-e1df-4b63-88a4-3dd4edbd237a',
    fid: 'mm.specter',
    name: 'Specter',
    cr: 1,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Specter',
    alignment: 'chaotic evil',
    environment: 'dungeon, ruins, urban',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 17, Monster Manual: 279, Princes of the Apocalypse Online Supplement v1.0: 33'
  },
  {
    guid: '5cf71994-66bd-433e-ae5f-d20071c48d85',
    fid: 'mm.spider',
    name: 'Spider',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, coast, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 12,
    hp: 1,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 44, Monster Manual: 337'
  },
  {
    guid: '0cf05453-f134-4a9a-8884-256965c0576b',
    fid: 'mm.spined-devil',
    name: 'Spined Devil',
    cr: 2,
    size: 'Small',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'dungeon, planar',
    ac: 13,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 78'
  },
  {
    guid: '552f997f-6cf1-4150-8144-aea22f652471',
    fid: 'mm.spirit-naga',
    name: 'Spirit Naga',
    cr: 8,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Nagas',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, ruins, underground',
    ac: 18,
    hp: 127,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 234'
  },
  {
    guid: 'e91d49d6-b139-4457-bd9c-45fd656ec408',
    fid: 'mm.sprite',
    name: 'Sprite',
    cr: '1/4',
    size: 'Tiny',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'neutral good',
    environment: 'forest, grassland, swamp',
    ac: 15,
    hp: 2,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 283, Player\'s Handbook: 310, Princes of the Apocalypse Online Supplement v1.0: 33'
  },
  {
    guid: 'a9524fbf-b1e5-4ca4-9f79-f4e494f93bcb',
    fid: 'mm.spy',
    name: 'Spy',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, cave, coast, desert, forest, grassland, mountain, ruins, swamp, urban',
    ac: 12,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 17, Monster Manual: 349, Princes of the Apocalypse Online Supplement v1.0: 33'
  },
  {
    guid: 'd28e71ee-8388-4534-bb38-209de47bbe5e',
    fid: 'mm.steam-mephit',
    name: 'Steam Mephit',
    cr: '1/4',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: 'Mephits',
    alignment: 'neutral evil',
    environment: 'dungeon, planar, urban',
    ac: 10,
    hp: 21,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 217, Princes of the Apocalypse Online Supplement v1.0: 34'
  },
  {
    guid: 'b02dadd4-aa89-426c-ac9b-bfa07b8fe1fc',
    fid: 'mm.stirge',
    name: 'Stirge',
    cr: '1/8',
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest, swamp',
    ac: 14,
    hp: 2,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 44, HotDQ supplement: 17, Monster Manual: 284'
  },
  {
    guid: 'c29cb38d-3b6a-459a-8643-843d256ab995',
    fid: 'mm.stone-giant',
    name: 'Stone Giant',
    cr: 7,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'neutral',
    environment: 'cave, mountain, underground',
    ac: 17,
    hp: 126,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 18, Monster Manual: 156'
  },
  {
    guid: '235ac710-1418-4bda-b0e5-3ded0ccbe1f9',
    fid: 'mm.stone-golem',
    name: 'Stone Golem',
    cr: 10,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: 'Golems',
    alignment: 'unaligned',
    environment: 'dungeon, ruins, urban',
    ac: 17,
    hp: 178,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 45, HotDQ supplement: 18, Monster Manual: 170'
  },
  {
    guid: 'ce9784dc-ee70-416b-955d-c554089ec044',
    fid: 'mm.storm-giant',
    name: 'Storm Giant',
    cr: 13,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Giants',
    alignment: 'chaotic good',
    environment: 'arctic, coast, desert, mountain, planar',
    ac: 16,
    hp: 230,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 156'
  },
  {
    guid: '63a62f8f-06b8-4ae2-a746-9c42448399d0',
    fid: 'mm.succubusincubus',
    name: 'Succubus/Incubus',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Shapechanger',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, planar, urban',
    ac: 15,
    hp: 66,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 285'
  },
  {
    guid: 'c5cc5a1a-d412-40d3-9537-f22aa625f893',
    fid: 'mm.swarm-of-bats',
    name: 'Swarm of Bats',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, ruins, swamp, underground, urban',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 45, Monster Manual: 337'
  },
  {
    guid: 'e3b72f35-5e72-4bfa-b238-f22062184c1c',
    fid: 'mm.swarm-of-insects',
    name: 'Swarm of Insects',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 12,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 45, HotDQ supplement: 18, Monster Manual: 338'
  },
  {
    guid: 'c37dd213-9373-41af-9c87-e27513d16ec5',
    fid: 'mm.swarm-of-poisonous-snakes',
    name: 'Swarm of Poisonous Snakes',
    cr: 2,
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'desert, dungeon, forest, grassland, swamp',
    ac: 14,
    hp: 36,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 46, Monster Manual: 338'
  },
  {
    guid: 'd74a782b-6b71-4c9c-95d0-f8d61bb97e44',
    fid: 'mm.swarm-of-quippers',
    name: 'Swarm of Quippers',
    cr: 1,
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'aquatic',
    ac: 13,
    hp: 28,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 46, Monster Manual: 338'
  },
  {
    guid: '31c042ca-f348-4ba5-a749-bb659b296f8f',
    fid: 'mm.swarm-of-rats',
    name: 'Swarm of Rats',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'cave, dungeon, forest, grassland, ruins, swamp, urban',
    ac: 10,
    hp: 24,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 46, HotDQ supplement: 19, Monster Manual: 339'
  },
  {
    guid: 'adcec956-ebf2-42c3-9e60-4ec3f58c17e2',
    fid: 'mm.swarm-of-ravens',
    name: 'Swarm of Ravens',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland, mountain, ruins, urban',
    ac: 12,
    hp: 24,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 46, Monster Manual: 339'
  },
  {
    guid: '962c28e0-17f2-4fa9-b76c-0017f1768650',
    fid: 'mm.tarrasque',
    name: 'Tarrasque',
    cr: 30,
    size: 'Gargantuan',
    type: 'Monstrosity',
    tags: 'Titan',
    section: '',
    alignment: 'unaligned',
    environment: 'aquatic, arctic, cave, coast, desert, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground, urban',
    ac: 25,
    hp: 676,
    init: 0,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 286'
  },
  {
    guid: '28a1d46d-c0ae-4865-80d1-866629c4f817',
    fid: 'mm.thri-kreen',
    name: 'Thri-kreen',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Thri-kreen',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'desert, grassland',
    ac: 15,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 288'
  },
  {
    guid: '9d458fc1-a489-49f8-9417-1c6c495d5e98',
    fid: 'mm.thug',
    name: 'Thug',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'non-good',
    environment: 'arctic, cave, coast, forest, grassland, urban',
    ac: 11,
    hp: 32,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 55, Monster Manual: 350'
  },
  {
    guid: 'd0a5fb54-32bb-4704-ac1a-862a175d333b',
    fid: 'mm.tiger',
    name: 'Tiger',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 12,
    hp: 37,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 47, Monster Manual: 339, Player\'s Handbook: 311'
  },
  {
    guid: 'b4a2167a-3d19-466f-bcca-65bb10c00c69',
    fid: 'mm.treant',
    name: 'Treant',
    cr: 9,
    size: 'Huge',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: 'forest, swamp',
    ac: 16,
    hp: 138,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 289'
  },
  {
    guid: '183f147b-1fa5-4495-8641-d655c57dbd07',
    fid: 'mm.tribal-warrior',
    name: 'Tribal Warrior',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, cave, desert, forest, grassland, mountain, swamp',
    ac: 12,
    hp: 11,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 350, Princes of the Apocalypse Online Supplement v1.0: 34'
  },
  {
    guid: '75aa0080-9b95-4693-abfd-dc077ba0073a',
    fid: 'mm.triceratops',
    name: 'Triceratops',
    cr: 5,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'grassland, mountain',
    ac: 13,
    hp: 95,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 47, Monster Manual: 80'
  },
  {
    guid: '8d9f2854-d059-4487-a326-ffd80b7ff95d',
    fid: 'mm.tridrone',
    name: 'Tridrone',
    cr: '1/2',
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Modrons',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 15,
    hp: 16,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 225'
  },
  {
    guid: '6d5a54d5-176b-46f6-988e-db5ff8176fab',
    fid: 'mm.troglodyte',
    name: 'Troglodyte',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Troglodyte',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, underground',
    ac: 11,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 19, Monster Manual: 290, Princes of the Apocalypse Online Supplement v1.0: 34'
  },
  {
    guid: '243e91ab-b859-44ce-a9cb-e14a055d1151',
    fid: 'mm.troll',
    name: 'Troll',
    cr: 5,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, dungeon, forest, mountain, swamp',
    ac: 15,
    hp: 84,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 47, HotDQ supplement: 19, Monster Manual: 291'
  },
  {
    guid: '811f1a20-d074-4520-a8de-1e37bc53a00c',
    fid: 'mm.twig-blight',
    name: 'Twig Blight',
    cr: '1/8',
    size: 'Small',
    type: 'Plant',
    tags: '',
    section: 'Blights',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp',
    ac: 13,
    hp: 4,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 47, Monster Manual: 32'
  },
  {
    guid: '49fb470b-4186-49a4-9794-05dfcdc8fe09',
    fid: 'mm.tyrannosaurus-rex',
    name: 'Tyrannosaurus Rex',
    cr: 8,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 13,
    hp: 136,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 48, Monster Manual: 80'
  },
  {
    guid: '17f653b5-f073-4638-855e-24d4c7f6c249',
    fid: 'mm.ultraloth',
    name: 'Ultroloth',
    cr: 13,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'dungeon, planar',
    ac: 19,
    hp: 153,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 314'
  },
  {
    guid: 'ea840106-9d27-453c-9ded-c93725740323',
    fid: 'mm.umber-hulk',
    name: 'Umber Hulk',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, underground',
    ac: 18,
    hp: 93,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 292, Princes of the Apocalypse Online Supplement v1.0: 35'
  },
  {
    guid: 'fb6ed7bc-7f48-465b-8de0-083c4365d979',
    fid: 'mm.unicorn',
    name: 'Unicorn',
    cr: 5,
    size: 'Large',
    type: 'Celestial',
    tags: '',
    section: '',
    alignment: 'lawful good',
    environment: 'forest, ruins',
    ac: 12,
    hp: 67,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 294'
  },
  {
    guid: 'f6277838-fcd7-4d25-b6a5-4049361e0978',
    fid: 'mm.vampire',
    name: 'Vampire',
    cr: 13,
    size: 'Medium',
    type: 'Undead',
    tags: 'Shapechanger',
    section: 'Vampires',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins, urban',
    ac: 16,
    hp: 144,
    init: 4,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'HotDQ supplement: 20, Monster Manual: 297'
  },
  {
    guid: '36f309b0-7f49-44ac-aba7-6a7405e58545',
    fid: 'mm.vampire-spawn',
    name: 'Vampire Spawn',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Vampires',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins, urban',
    ac: 15,
    hp: 82,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 21, Monster Manual: 298, Princes of the Apocalypse Online Supplement v1.0: 35'
  },
  {
    guid: '7298a7a8-a523-49b7-8e60-26e6375cb87f',
    fid: 'mm.vampire-spellcaster',
    name: 'Vampire Spellcaster',
    cr: 15,
    size: 'Medium',
    type: 'Undead',
    tags: 'Shapechanger',
    section: 'Vampires',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins, urban',
    ac: 16,
    hp: 144,
    init: 4,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 298'
  },
  {
    guid: '1b36ddcf-1c1c-4238-a320-c602f74670f2',
    fid: 'mm.vampire-warrior',
    name: 'Vampire Warrior',
    cr: 15,
    size: 'Medium',
    type: 'Undead',
    tags: 'Shapechanger',
    section: 'Vampires',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins, urban',
    ac: 18,
    hp: 144,
    init: 4,
    lair: 'lair',
    legendary: 'legendary',
    unique: '',
    sources: 'Monster Manual: 298'
  },
  {
    guid: '595c04c7-7c4d-442a-932a-22be86d294c9',
    fid: 'mm.veteran',
    name: 'Veteran',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'any',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 17,
    hp: 58,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 21, Monster Manual: 350, Princes of the Apocalypse Online Supplement v1.0: 35'
  },
  {
    guid: '590ac204-e32e-4a8e-b4ea-1f7ca2c39851',
    fid: 'mm.vine-blight',
    name: 'Vine Blight',
    cr: '1/2',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Blights',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp',
    ac: 12,
    hp: 26,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 32'
  },
  {
    guid: '667b4af5-1335-4f33-bd19-34d947603dc9',
    fid: 'mm.violet-fungus',
    name: 'Violet Fungus',
    cr: '1/4',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Fungi',
    alignment: 'unaligned',
    environment: 'cave, dungeon, underground',
    ac: 5,
    hp: 18,
    init: -5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 21, Monster Manual: 138, Princes of the Apocalypse Online Supplement v1.0: 36'
  },
  {
    guid: '993c1b2c-ea24-45c5-9c1d-a862d1f6b440',
    fid: 'mm.vrock',
    name: 'Vrock',
    cr: 6,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 15,
    hp: 104,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 64, Princes of the Apocalypse Online Supplement v1.0: 36'
  },
  {
    guid: 'b2f6a630-d8e2-42f5-8844-5a346f55c272',
    fid: 'mm.vulture',
    name: 'Vulture',
    cr: 0,
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'desert, grassland, ruins',
    ac: 10,
    hp: 5,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 48, Monster Manual: 339'
  },
  {
    guid: '8d0e875b-95f1-47a5-9d98-72ac4738c860',
    fid: 'mm.warhorse',
    name: 'Warhorse',
    cr: '1/2',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland, urban',
    ac: 11,
    hp: 19,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 48, Monster Manual: 340, Player\'s Handbook: 311'
  },
  {
    guid: '33ec8ed0-0cd0-4def-9da6-761cf73206da',
    fid: 'mm.warhorse-skeleton',
    name: 'Warhorse Skeleton',
    cr: '1/2',
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Skeletons',
    alignment: 'lawful evil',
    environment: 'grassland, ruins',
    ac: 13,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 273'
  },
  {
    guid: 'ef655c7f-1f59-4b56-8b52-3e96895cb20f',
    fid: 'mm.water-elemental',
    name: 'Water Elemental',
    cr: 5,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: 'Elementals',
    alignment: 'neutral',
    environment: 'aquatic, arctic, cave, coast, dungeon, forest, grassland, mountain, planar, ruins, swamp, underground',
    ac: 14,
    hp: 114,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 49, Monster Manual: 125'
  },
  {
    guid: '0414cbe7-742c-4df8-9d4a-51dd1d37210e',
    fid: 'mm.water-weird',
    name: 'Water Weird',
    cr: 3,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'cave, coast, forest, mountain, planar, swamp, underground',
    ac: 13,
    hp: 58,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 299, Princes of the Apocalypse Online Supplement v1.0: 36'
  },
  {
    guid: '3306e2fd-7249-45d6-abcd-1797c8d8ca30',
    fid: 'mm.weasel',
    name: 'Weasel',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 13,
    hp: 1,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 49, Monster Manual: 340'
  },
  {
    guid: 'db897f13-24c8-4332-808f-682061af4b36',
    fid: 'mm.werebear',
    name: 'Werebear',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: 'Lycanthropes',
    alignment: 'neutral good',
    environment: 'cave, forest, mountain, urban',
    ac: '10 in humanoid form, 11 in bear and hybrid form',
    hp: 135,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 208'
  },
  {
    guid: '59408dc1-6bee-467c-9e7e-108d7870d5a7',
    fid: 'mm.wereboar',
    name: 'Wereboar',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: 'Lycanthropes',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp, urban',
    ac: '10 in humanoid form, 11 in boar and hybrid form',
    hp: 78,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 209, Princes of the Apocalypse Online Supplement v1.0: 37'
  },
  {
    guid: 'c8e2837f-ea3b-42a7-8a9b-84056d186006',
    fid: 'mm.wererat',
    name: 'Wererat',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: 'Lycanthropes',
    alignment: 'lawful evil',
    environment: 'cave, dungeon, ruins, swamp, underground, urban',
    ac: 12,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 209'
  },
  {
    guid: 'e63e1150-3b0b-46e0-a2cb-2a2f30cf5c69',
    fid: 'mm.weretiger',
    name: 'Weretiger',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: 'Lycanthropes',
    alignment: 'neutral',
    environment: 'forest, urban',
    ac: 12,
    hp: 120,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 210'
  },
  {
    guid: '80a17f83-7b94-497e-a10d-3dd1f3a8a889',
    fid: 'mm.werewolf',
    name: 'Werewolf',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: 'Lycanthropes',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, grassland, urban',
    ac: '11 in humanoid form, 12 in wolf or hybrid form',
    hp: 58,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 49, Monster Manual: 211'
  },
  {
    guid: 'c00182cb-df6f-42da-9193-e67eb119083c',
    fid: 'mm.white-dragon-wyrmling',
    name: 'White Dragon Wyrmling',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, underground',
    ac: 16,
    hp: 32,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 102'
  },
  {
    guid: '76b14a0c-551e-4166-9e0a-e06bdb46623d',
    fid: 'mm.wight',
    name: 'Wight',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 14,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 50, Monster Manual: 300'
  },
  {
    guid: '16d4afb3-8246-4709-8308-58d620849408',
    fid: 'mm.will-o-wisp',
    name: 'Will-o\'-Wisp',
    cr: 2,
    size: 'Tiny',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'swamp',
    ac: 19,
    hp: 22,
    init: 9,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 22, Monster Manual: 301, Princes of the Apocalypse Online Supplement v1.0: 37'
  },
  {
    guid: 'e0b6446f-3791-479d-9e03-9a71c0de9f52',
    fid: 'mm.winged-kobold',
    name: 'Winged Kobold',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: 'Kobolds',
    alignment: 'lawful evil',
    environment: 'mountain, underground',
    ac: 13,
    hp: 7,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 23, Monster Manual: 195'
  },
  {
    guid: '4da5597e-8f29-4fce-a4e7-d36ba2c5b6a7',
    fid: 'mm.winter-wolf',
    name: 'Winter Wolf',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral evil',
    environment: 'arctic, cave, forest, grassland',
    ac: 13,
    hp: 75,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 50, Monster Manual: 340'
  },
  {
    guid: '9e4c024d-0b8b-40c7-9e4a-2b447450ab4e',
    fid: 'mm.wolf',
    name: 'Wolf',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'unaligned',
    environment: 'arctic, cave, forest, grassland',
    ac: 13,
    hp: 11,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 50, Monster Manual: 341, Player\'s Handbook: 311'
  },
  {
    guid: '1d37bafc-ea8e-40e5-8546-475276cc903f',
    fid: 'mm.worg',
    name: 'Worg',
    cr: '1/2',
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Misc Creatures',
    alignment: 'neutral evil',
    environment: 'arctic, cave, forest, grassland',
    ac: 13,
    hp: 26,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 51, Monster Manual: 341'
  },
  {
    guid: 'e0c0e8f0-bef8-4c76-872b-898a91c27969',
    fid: 'mm.wraith',
    name: 'Wraith',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins, swamp, urban',
    ac: 13,
    hp: 67,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 302'
  },
  {
    guid: '9b9d4516-f2ae-449d-80ba-0ce4e85438cc',
    fid: 'mm.wyvern',
    name: 'Wyvern',
    cr: 6,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'arctic, coast, grassland',
    ac: 13,
    hp: 110,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 51, HotDQ supplement: 23, Monster Manual: 303'
  },
  {
    guid: '77cbc6b6-3936-4dad-b575-7c45e81e22f9',
    fid: 'mm.xorn',
    name: 'Xorn',
    cr: 5,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'dungeon, planar, underground',
    ac: 19,
    hp: 73,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 304, Princes of the Apocalypse Online Supplement v1.0: 38'
  },
  {
    guid: '7686c9ce-acc2-4d30-8729-665c947c153d',
    fid: 'mm.yeti',
    name: 'Yeti',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Yeti',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, mountain',
    ac: 12,
    hp: 51,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 51, Monster Manual: 305'
  },
  {
    guid: 'b6a29400-8010-4d35-b429-b65eb33de9c3',
    fid: 'mm.yochlol',
    name: 'Yochlol',
    cr: 10,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon, Shapechanger',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'dungeon, planar',
    ac: 15,
    hp: 136,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 65'
  },
  {
    guid: 'e1758345-1f6a-440c-bb64-d86ba7e96bfa',
    fid: 'mm.young-black-dragon',
    name: 'Young Black Dragon',
    cr: 7,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'swamp',
    ac: 18,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 88'
  },
  {
    guid: '5b5912af-1b34-45f7-adae-388645d0a500',
    fid: 'mm.young-blue-dragon',
    name: 'Young Blue Dragon',
    cr: 9,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'desert',
    ac: 18,
    hp: 152,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 91'
  },
  {
    guid: '04cd3926-8097-4020-87c1-74610125a529',
    fid: 'mm.young-brass-dragon',
    name: 'Young Brass Dragon',
    cr: 6,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, underground',
    ac: 17,
    hp: 110,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 105'
  },
  {
    guid: '994dda89-f812-4e4d-8996-40e877539101',
    fid: 'mm.young-bronze-dragon',
    name: 'Young Bronze Dragon',
    cr: 8,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'coast',
    ac: 18,
    hp: 142,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 108'
  },
  {
    guid: 'aec4cd35-e36f-41fd-a4a4-c19ab3d08218',
    fid: 'mm.young-copper-dragon',
    name: 'Young Copper Dragon',
    cr: 7,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic good',
    environment: 'cave, desert, grassland',
    ac: 17,
    hp: 119,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 111'
  },
  {
    guid: 'f858036f-efe8-4f7f-af82-cfa836594474',
    fid: 'mm.young-gold-dragon',
    name: 'Young Gold Dragon',
    cr: 10,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'mountain, ruins',
    ac: 18,
    hp: 178,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 115'
  },
  {
    guid: 'd935c7ad-b765-4e72-8274-adb16a62aade',
    fid: 'mm.young-green-dragon',
    name: 'Young Green Dragon',
    cr: 8,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 18,
    hp: 136,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 52, Monster Manual: 94'
  },
  {
    guid: '3f6ed5da-bf09-4f65-bfd2-b012ebb01648',
    fid: 'mm.young-red-dragon',
    name: 'Young Red Dragon',
    cr: 10,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'mountain',
    ac: 18,
    hp: 178,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 98, Princes of the Apocalypse Online Supplement v1.0: 38'
  },
  {
    guid: 'ca105b5a-d47c-467a-b48f-bd25a9a33de4',
    fid: 'mm.young-red-shadow-dragon',
    name: 'Young Red Shadow Dragon',
    cr: 13,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragon, Shadow',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 178,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 85'
  },
  {
    guid: 'c10d36ba-73da-4718-bf7f-f078791639dd',
    fid: 'mm.young-remorhaz',
    name: 'Young Remorhaz',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Remorhazes',
    alignment: 'unaligned',
    environment: 'arctic, cave',
    ac: 14,
    hp: 93,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 258'
  },
  {
    guid: '6c8026b5-6b8b-4b06-a3a8-78a4822d791f',
    fid: 'mm.young-silver-dragon',
    name: 'Young Silver Dragon',
    cr: 9,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'lawful good',
    environment: 'arctic, mountain',
    ac: 18,
    hp: 168,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 118'
  },
  {
    guid: '3797b48d-5d9e-4e6b-ae4c-43fa4bfcf086',
    fid: 'mm.young-white-dragon',
    name: 'Young White Dragon',
    cr: 6,
    size: 'Large',
    type: 'Dragon',
    tags: '',
    section: 'Dragons',
    alignment: 'chaotic evil',
    environment: 'arctic, cave, underground',
    ac: 17,
    hp: 133,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 101'
  },
  {
    guid: '36d12747-408b-485c-859e-2d33b9c4d90e',
    fid: 'mm.yuan-ti-abomination',
    name: 'Yuan-ti Abomination',
    cr: 7,
    size: 'Large',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, mountain, ruins, swamp',
    ac: 15,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Monster Manual: 308'
  },
  {
    guid: '718cb508-899e-46cd-8c26-6271fe470d62',
    fid: 'mm.yuan-ti-malison',
    name: 'Yuan-ti Malison',
    cr: 3,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, mountain, ruins, swamp',
    ac: 12,
    hp: 66,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 23, Monster Manual: 309'
  },
  {
    guid: '63edeedd-e398-4b1c-b4b8-557286512a8a',
    fid: 'mm.yuan-ti-pureblood',
    name: 'Yuan-ti Pureblood',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, mountain, ruins, swamp',
    ac: 11,
    hp: 40,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'HotDQ supplement: 24, Monster Manual: 310'
  },
  {
    guid: 'ac42a801-316b-4f28-b552-592d7e751649',
    fid: 'mm.zombie',
    name: 'Zombie',
    cr: '1/4',
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'neutral evil',
    environment: 'aquatic, arctic, cave, coast, desert, dungeon, forest, grassland, mountain, ruins, swamp, underground, urban',
    ac: 8,
    hp: 22,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Basic Rules v1: 52, Monster Manual: 316, Player\'s Handbook: 311'
  },
  {
    guid: '',
    fid: 'mtof.abyssal-wretch',
    name: 'Abyssal Wretch',
    cr: '1/4',
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 11,
    hp: 18,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 136'
  },
  {
    guid: '',
    fid: 'mtof.adult-kruthik',
    name: 'Adult Kruthik',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Kruthiks',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 18,
    hp: 39,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 212'
  },
  {
    guid: '',
    fid: 'mtof.adult-oblex',
    name: 'Adult Oblex',
    cr: 5,
    size: 'Medium',
    type: 'Ooze',
    tags: '',
    section: 'Oblex',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 14,
    hp: 75,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 218'
  },
  {
    guid: '',
    fid: 'mtof.air-elemental-myrmidon',
    name: 'Air Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elder Myrmidons',
    alignment: 'neutral',
    environment: 'planar',
    ac: 18,
    hp: 117,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 202'
  },
  {
    guid: '',
    fid: 'mtof.alkilith',
    name: 'Alkilith',
    cr: 11,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'ruins, urban',
    ac: 17,
    hp: 157,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 130'
  },
  {
    guid: '',
    fid: 'mtof.allip',
    name: 'Allip',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, ruins',
    ac: 13,
    hp: 40,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 116'
  },
  {
    guid: '',
    fid: 'mtof.amnizu',
    name: 'Amnizu',
    cr: 18,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 21,
    hp: 202,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 164'
  },
  {
    guid: '',
    fid: 'mtof.armanite',
    name: 'Armanite',
    cr: 7,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 16,
    hp: 84,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 131'
  },
  {
    guid: '',
    fid: 'mtof.astral-dreadnought',
    name: 'Astral Dreadnought',
    cr: 21,
    size: 'Gargantuan',
    type: 'Monstrosity',
    tags: 'Titan',
    section: '',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 20,
    hp: 297,
    init: -2,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 117'
  },
  {
    guid: '',
    fid: 'mtof.autumn-eladrin',
    name: 'Autumn Eladrin',
    cr: 10,
    size: 'Medium',
    type: 'Fey',
    tags: 'Elf',
    section: 'Eladrin',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 19,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 195'
  },
  {
    guid: '',
    fid: 'mtof.bael',
    name: 'Bael',
    cr: 19,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 18,
    hp: 189,
    init: 3,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 170'
  },
  {
    guid: '',
    fid: 'mtof.balhannoth',
    name: 'Balhannoth',
    cr: 11,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, urban, underground',
    ac: 17,
    hp: 114,
    init: -1,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 119'
  },
  {
    guid: '',
    fid: 'mtof.baphomet',
    name: 'Baphomet',
    cr: 23,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 22,
    hp: 275,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 143'
  },
  {
    guid: '',
    fid: 'mtof.berbalang',
    name: 'Berbalang',
    cr: 2,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 14,
    hp: 38,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 120'
  },
  {
    guid: '',
    fid: 'mtof.black-abishai',
    name: 'Black Abishai',
    cr: 7,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 15,
    hp: 58,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 160'
  },
  {
    guid: '',
    fid: 'mtof.blue-abishai',
    name: 'Blue Abishai',
    cr: 17,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 19,
    hp: 195,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 161'
  },
  {
    guid: '',
    fid: 'mtof.boneclaw',
    name: 'Boneclaw',
    cr: 12,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'dungeon, ruins, underground',
    ac: 16,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 121'
  },
  {
    guid: '',
    fid: 'mtof.bronze-scout',
    name: 'Bronze Scout',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Clockworks',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 13,
    hp: 18,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 125'
  },
  {
    guid: '',
    fid: 'mtof.bulezau',
    name: 'Bulezau',
    cr: 3,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 14,
    hp: 52,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 131'
  },
  {
    guid: '',
    fid: 'mtof.cadaver-collector',
    name: 'Cadaver Collector',
    cr: 14,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'planar, ruins',
    ac: 17,
    hp: 189,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 122'
  },
  {
    guid: '',
    fid: 'mtof.canoloth',
    name: 'Canoloth',
    cr: 8,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 16,
    hp: 120,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 247'
  },
  {
    guid: '',
    fid: 'mtof.choker',
    name: 'Choker',
    cr: 1,
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'cave, underground',
    ac: 16,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 123'
  },
  {
    guid: '',
    fid: 'mtof.corpse-flower',
    name: 'Corpse Flower',
    cr: 8,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'forest, ruins, swamp, urban',
    ac: 12,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 127'
  },
  {
    guid: '',
    fid: 'mtof.deathlock',
    name: 'Deathlock',
    cr: 4,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 12,
    hp: 36,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 128'
  },
  {
    guid: '',
    fid: 'mtof.deathlock-mastermind',
    name: 'Deathlock Mastermind',
    cr: 8,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 110,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 129'
  },
  {
    guid: '',
    fid: 'mtof.deathlock-wight',
    name: 'Deathlock Wight',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 12,
    hp: 37,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 129'
  },
  {
    guid: '',
    fid: 'mtof.demogorgon',
    name: 'Demogorgon',
    cr: 26,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 22,
    hp: 406,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 144'
  },
  {
    guid: '',
    fid: 'mtof.derro',
    name: 'Derro',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: 'Derro',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 13,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 158'
  },
  {
    guid: '',
    fid: 'mtof.derro-savant',
    name: 'Derro Savant',
    cr: 3,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Derro',
    section: 'Derro',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 13,
    hp: 36,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 159'
  },
  {
    guid: '',
    fid: 'mtof.dhergoloth',
    name: 'Dhergoloth',
    cr: 7,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 15,
    hp: 119,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 248'
  },
  {
    guid: '',
    fid: 'mtof.dire-troll',
    name: 'Dire Troll',
    cr: 13,
    size: 'Huge',
    type: 'Giant',
    tags: '',
    section: 'Trolls',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, ruins, swamp',
    ac: 15,
    hp: 172,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 243'
  },
  {
    guid: '',
    fid: 'mtof.drow-arachnomancer',
    name: 'Drow Arachnomancer',
    cr: 13,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 15,
    hp: 162,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 182'
  },
  {
    guid: '',
    fid: 'mtof.drow-favored-consort',
    name: 'Drow Favored Consort',
    cr: 18,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 15,
    hp: 225,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 183'
  },
  {
    guid: '',
    fid: 'mtof.drow-house-captain',
    name: 'Drow House Captain',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 16,
    hp: 162,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 184'
  },
  {
    guid: '',
    fid: 'mtof.drow-inquisitor',
    name: 'Drow Inquisitor',
    cr: 14,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 16,
    hp: 143,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 184'
  },
  {
    guid: '',
    fid: 'mtof.drow-matron-mother',
    name: 'Drow Matron Mother',
    cr: 20,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 17,
    hp: 262,
    init: 4,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 186'
  },
  {
    guid: '',
    fid: 'mtof.drow-shadowblade',
    name: 'Drow Shadowblade',
    cr: 11,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Drow',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 17,
    hp: 150,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 187'
  },
  {
    guid: '',
    fid: 'mtof.duergar-despot',
    name: 'Duergar Despot',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 21,
    hp: 119,
    init: -3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 188'
  },
  {
    guid: '',
    fid: 'mtof.duergar-hammerer',
    name: 'Duergar Hammerer',
    cr: 2,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 17,
    hp: 33,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 188'
  },
  {
    guid: '',
    fid: 'mtof.duergar-kavalrachni',
    name: 'Duergar Kavalrachni',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 16,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 189'
  },
  {
    guid: '',
    fid: 'mtof.duergar-mind-master',
    name: 'Duergar Mind Master',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 14,
    hp: 39,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 189'
  },
  {
    guid: '',
    fid: 'mtof.duergar-screamer',
    name: 'Duergar Screamer',
    cr: 3,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 15,
    hp: 38,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 190'
  },
  {
    guid: '',
    fid: 'mtof.duergar-soulblade',
    name: 'Duergar Soulblade',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 14,
    hp: 18,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 190'
  },
  {
    guid: '',
    fid: 'mtof.duergar-stone-guard',
    name: 'Duergar Stone Guard',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 18,
    hp: 39,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 191'
  },
  {
    guid: '',
    fid: 'mtof.duergar-warlord',
    name: 'Duergar Warlord',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 20,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 192'
  },
  {
    guid: '',
    fid: 'mtof.duergar-xarrorn',
    name: 'Duergar Xarrorn',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: 'Duergar',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 18,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 193'
  },
  {
    guid: '',
    fid: 'mtof.dybbuk',
    name: 'Dybbuk',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 14,
    hp: 37,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 132'
  },
  {
    guid: '',
    fid: 'mtof.earth-elemental-myrmidon',
    name: 'Earth Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elder Myrmidons',
    alignment: 'neutral',
    environment: 'planar',
    ac: 18,
    hp: 127,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 202'
  },
  {
    guid: '',
    fid: 'mtof.eidolon',
    name: 'Eidolon',
    cr: 12,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'any',
    environment: 'dungeon, ruins',
    ac: 9,
    hp: 63,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 194'
  },
  {
    guid: '',
    fid: 'mtof.elder-oblex',
    name: 'Elder Oblex',
    cr: 10,
    size: 'Huge',
    type: 'Ooze',
    tags: '',
    section: 'Oblex',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 16,
    hp: 115,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 219'
  },
  {
    guid: '',
    fid: 'mtof.elder-tempest',
    name: 'Elder Tempest',
    cr: 23,
    size: 'Gargantuan',
    type: 'Elemental',
    tags: '',
    section: 'Elder Elementals',
    alignment: 'neutral',
    environment: 'planar',
    ac: 19,
    hp: 264,
    init: 9,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 200'
  },
  {
    guid: '',
    fid: 'mtof.female-steeder',
    name: 'Female Steeder',
    cr: 1,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Steeder',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 14,
    hp: 30,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 238'
  },
  {
    guid: '',
    fid: 'mtof.fire-elemental-myrmidon',
    name: 'Fire Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elder Myrmidons',
    alignment: 'neutral',
    environment: 'planar',
    ac: 18,
    hp: 123,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 203'
  },
  {
    guid: '',
    fid: 'mtof.fraz-urbluu',
    name: 'Fraz-Urb\'luu',
    cr: 23,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 337,
    init: 1,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 146'
  },
  {
    guid: '',
    fid: 'mtof.fraz-urbluu-in-lair',
    name: 'Fraz-Urb\'luu (in lair)',
    cr: 24,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 337,
    init: 1,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 146'
  },
  {
    guid: '',
    fid: 'mtof.frost-salamander',
    name: 'Frost Salamander',
    cr: 9,
    size: 'Huge',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 17,
    hp: 168,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 223'
  },
  {
    guid: '',
    fid: 'mtof.geryon',
    name: 'Geryon',
    cr: 22,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 19,
    hp: 300,
    init: 3,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 173'
  },
  {
    guid: '',
    fid: 'mtof.giff',
    name: 'Giff',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: '',
    section: '',
    alignment: 'lawful neutral',
    environment: '',
    ac: 16,
    hp: 60,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 204'
  },
  {
    guid: '',
    fid: 'mtof.githyanki-gish',
    name: 'Githyanki Gish',
    cr: 10,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 17,
    hp: 123,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 205'
  },
  {
    guid: '',
    fid: 'mtof.githyanki-kithrak',
    name: 'Githyanki Kith\'rak',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 18,
    hp: 180,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 205'
  },
  {
    guid: '',
    fid: 'mtof.githyanki-supreme-commander',
    name: 'Githyanki Supreme Commander',
    cr: 14,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 18,
    hp: 187,
    init: 3,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 206'
  },
  {
    guid: '',
    fid: 'mtof.githzerai-anarch',
    name: 'Githzerai Anarch',
    cr: 16,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 20,
    hp: 144,
    init: 5,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 207'
  },
  {
    guid: '',
    fid: 'mtof.githzerai-enlightened',
    name: 'Githzerai Enlightened',
    cr: 10,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gith',
    section: 'Gith',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 18,
    hp: 112,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 208'
  },
  {
    guid: '',
    fid: 'mtof.gloom-weaver',
    name: 'Gloom Weaver',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Shadar-kai',
    alignment: 'neutral',
    environment: 'planar, urban',
    ac: 14,
    hp: 104,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 224'
  },
  {
    guid: '',
    fid: 'mtof.gray-render',
    name: 'Gray Render',
    cr: 12,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'urban',
    ac: 19,
    hp: 189,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 209'
  },
  {
    guid: '',
    fid: 'mtof.grazzt',
    name: 'Graz\'zt',
    cr: 24,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 20,
    hp: 346,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 149'
  },
  {
    guid: '',
    fid: 'mtof.green-abishai',
    name: 'Green Abishai',
    cr: 15,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 18,
    hp: 187,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 162'
  },
  {
    guid: '',
    fid: 'mtof.hellfire-engine',
    name: 'Hellfire Engine',
    cr: 16,
    size: 'Huge',
    type: 'Construct',
    tags: '',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 18,
    hp: 216,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 165'
  },
  {
    guid: '',
    fid: 'mtof.howler',
    name: 'Howler',
    cr: 8,
    size: 'Large',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 16,
    hp: 90,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 210'
  },
  {
    guid: '',
    fid: 'mtof.hutijin',
    name: 'Hutijin',
    cr: 21,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 19,
    hp: 200,
    init: 2,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 175'
  },
  {
    guid: '',
    fid: 'mtof.hydroloth',
    name: 'Hydroloth',
    cr: 9,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 15,
    hp: 135,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 249'
  },
  {
    guid: '',
    fid: 'mtof.iron-cobra',
    name: 'Iron Cobra',
    cr: 4,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Clockworks',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 13,
    hp: 45,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 125'
  },
  {
    guid: '',
    fid: 'mtof.juiblex',
    name: 'Juiblex',
    cr: 23,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 350,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 151'
  },
  {
    guid: '',
    fid: 'mtof.kruthik-hive-lord',
    name: 'Kruthik Hive Lord',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Kruthiks',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 20,
    hp: 102,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 212'
  },
  {
    guid: '',
    fid: 'mtof.leviathan',
    name: 'Leviathan',
    cr: 20,
    size: 'Gargantuan',
    type: 'Elemental',
    tags: '',
    section: 'Elder Elementals',
    alignment: 'neutral',
    environment: 'aquatic, planar',
    ac: 17,
    hp: 328,
    init: 7,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 198'
  },
  {
    guid: '',
    fid: 'mtof.male-steeder',
    name: 'Male Steeder',
    cr: '1/4',
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Steeder',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 12,
    hp: 13,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 238'
  },
  {
    guid: '',
    fid: 'mtof.marut',
    name: 'Marut',
    cr: 25,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful neutral',
    environment: 'planar',
    ac: 22,
    hp: 432,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 213'
  },
  {
    guid: '',
    fid: 'mtof.maurezhi',
    name: 'Maurezhi',
    cr: 7,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 15,
    hp: 88,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 133'
  },
  {
    guid: '',
    fid: 'mtof.meazel',
    name: 'Meazel',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Meazel',
    section: '',
    alignment: 'neutral evil',
    environment: 'planar, underground',
    ac: 13,
    hp: 35,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 214'
  },
  {
    guid: '',
    fid: 'mtof.merregon',
    name: 'Merregon',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 16,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 166'
  },
  {
    guid: '',
    fid: 'mtof.merrenoloth',
    name: 'Merrenoloth',
    cr: 3,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 13,
    hp: 40,
    init: 3,
    lair: 'lair',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 250'
  },
  {
    guid: '',
    fid: 'mtof.moloch',
    name: 'Moloch',
    cr: 21,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 19,
    hp: 253,
    init: 4,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 177'
  },
  {
    guid: '',
    fid: 'mtof.molydeus',
    name: 'Molydeus',
    cr: 21,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 19,
    hp: 216,
    init: 6,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 134'
  },
  {
    guid: '',
    fid: 'mtof.nabassu',
    name: 'Nabassu',
    cr: 15,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 190,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 135'
  },
  {
    guid: '',
    fid: 'mtof.nagpa',
    name: 'Nagpa',
    cr: 17,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Nagpa',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 19,
    hp: 187,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 215'
  },
  {
    guid: '',
    fid: 'mtof.narzugon',
    name: 'Narzugon',
    cr: 13,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 20,
    hp: 112,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 167'
  },
  {
    guid: '',
    fid: 'mtof.nightwalker',
    name: 'Nightwalker',
    cr: 20,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 14,
    hp: 297,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 216'
  },
  {
    guid: '',
    fid: 'mtof.nupperibo',
    name: 'Nupperibo',
    cr: '1/2',
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 13,
    hp: 11,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 168'
  },
  {
    guid: '',
    fid: 'mtof.oaken-bolter',
    name: 'Oaken Bolter',
    cr: 5,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Clockworks',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 16,
    hp: 58,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 126'
  },
  {
    guid: '',
    fid: 'mtof.oblex-spawn',
    name: 'Oblex Spawn',
    cr: '1/4',
    size: 'Tiny',
    type: 'Ooze',
    tags: '',
    section: 'Oblex',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 13,
    hp: 18,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 217'
  },
  {
    guid: '',
    fid: 'mtof.ogre-battering-ram',
    name: 'Ogre Battering Ram',
    cr: 4,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 14,
    hp: 59,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 220'
  },
  {
    guid: '',
    fid: 'mtof.ogre-bolt-launcher',
    name: 'Ogre Bolt Launcher',
    cr: 2,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 13,
    hp: 59,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 220'
  },
  {
    guid: '',
    fid: 'mtof.ogre-chain-brute',
    name: 'Ogre Chain Brute',
    cr: 3,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 11,
    hp: 59,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 221'
  },
  {
    guid: '',
    fid: 'mtof.ogre-howdah',
    name: 'Ogre Howdah',
    cr: 2,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Ogres',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, swamp',
    ac: 13,
    hp: 59,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 221'
  },
  {
    guid: '',
    fid: 'mtof.oinoloth',
    name: 'Oinoloth',
    cr: 12,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 17,
    hp: 126,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 251'
  },
  {
    guid: '',
    fid: 'mtof.orcus',
    name: 'Orcus',
    cr: 26,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 17,
    hp: 405,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 153'
  },
  {
    guid: '',
    fid: 'mtof.orthon',
    name: 'Orthon',
    cr: 10,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 17,
    hp: 105,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 169'
  },
  {
    guid: '',
    fid: 'mtof.phoenix',
    name: 'Phoenix',
    cr: 16,
    size: 'Gargantuan',
    type: 'Elemental',
    tags: '',
    section: 'Elder Elementals',
    alignment: 'neutral',
    environment: 'planar',
    ac: 18,
    hp: 175,
    init: 8,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 199'
  },
  {
    guid: '',
    fid: 'mtof.red-abishai',
    name: 'Red Abishai',
    cr: 19,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 22,
    hp: 255,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 162'
  },
  {
    guid: '',
    fid: 'mtof.retriever',
    name: 'Retriever',
    cr: 14,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 19,
    hp: 210,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 222'
  },
  {
    guid: '',
    fid: 'mtof.rot-troll',
    name: 'Rot Troll',
    cr: 9,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Trolls',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, ruins, swamp',
    ac: 16,
    hp: 138,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 244'
  },
  {
    guid: '',
    fid: 'mtof.rutterkin',
    name: 'Rutterkin',
    cr: 2,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 12,
    hp: 37,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 136'
  },
  {
    guid: '',
    fid: 'mtof.sacred-statue',
    name: 'Sacred Statue',
    cr: 0,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'any',
    environment: 'dungeon, ruins',
    ac: 19,
    hp: 95,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 194'
  },
  {
    guid: '',
    fid: 'mtof.shadow-dancer',
    name: 'Shadow Dancer',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Shadar-kai',
    alignment: 'neutral',
    environment: 'planar, urban',
    ac: 15,
    hp: 71,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 225'
  },
  {
    guid: '',
    fid: 'mtof.sibriex',
    name: 'Sibriex',
    cr: 18,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 19,
    hp: 150,
    init: -4,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 137'
  },
  {
    guid: '',
    fid: 'mtof.skulk',
    name: 'Skulk',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 14,
    hp: 18,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 228'
  },
  {
    guid: '',
    fid: 'mtof.skull-lord',
    name: 'Skull Lord',
    cr: 15,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon, ruins',
    ac: 18,
    hp: 105,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 230'
  },
  {
    guid: '',
    fid: 'mtof.soul-monger',
    name: 'Soul Monger',
    cr: 11,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: 'Shadar-kai',
    alignment: 'neutral',
    environment: 'planar, urban',
    ac: 15,
    hp: 123,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 226'
  },
  {
    guid: '',
    fid: 'mtof.spirit-troll',
    name: 'Spirit Troll',
    cr: 11,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Trolls',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, ruins, swamp',
    ac: 17,
    hp: 97,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 244'
  },
  {
    guid: '',
    fid: 'mtof.spring-eladrin',
    name: 'Spring Eladrin',
    cr: 10,
    size: 'Medium',
    type: 'Fey',
    tags: 'Elf',
    section: 'Eladrin',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 19,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 196'
  },
  {
    guid: '',
    fid: 'mtof.star-spawn-grue',
    name: 'Star Spawn Grue',
    cr: '1/4',
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: 'Star Spawn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 11,
    hp: 17,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 234'
  },
  {
    guid: '',
    fid: 'mtof.star-spawn-hulk',
    name: 'Star Spawn Hulk',
    cr: 10,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Star Spawn',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 16,
    hp: 136,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 234'
  },
  {
    guid: '',
    fid: 'mtof.star-spawn-larva-mage',
    name: 'Star Spawn Larva Mage',
    cr: 16,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Star Spawn',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 16,
    hp: 168,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 235'
  },
  {
    guid: '',
    fid: 'mtof.star-spawn-mangler',
    name: 'Star Spawn Mangler',
    cr: 5,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Star Spawn',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 14,
    hp: 71,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 236'
  },
  {
    guid: '',
    fid: 'mtof.star-spawn-seer',
    name: 'Star Spawn Seer',
    cr: 13,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Star Spawn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 17,
    hp: 153,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 236'
  },
  {
    guid: '',
    fid: 'mtof.steel-predator',
    name: 'Steel Predator',
    cr: 16,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 20,
    hp: 207,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 239'
  },
  {
    guid: '',
    fid: 'mtof.stone-cursed',
    name: 'Stone Cursed',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: 'dungeon',
    ac: 17,
    hp: 19,
    init: -3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 240'
  },
  {
    guid: '',
    fid: 'mtof.stone-defender',
    name: 'Stone Defender',
    cr: 4,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: 'Clockworks',
    alignment: 'unaligned',
    environment: 'planar',
    ac: 16,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 126'
  },
  {
    guid: '',
    fid: 'mtof.summer-eladrin',
    name: 'Summer Eladrin',
    cr: 10,
    size: 'Medium',
    type: 'Fey',
    tags: 'Elf',
    section: 'Eladrin',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 19,
    hp: 127,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 196'
  },
  {
    guid: '',
    fid: 'mtof.sword-wraith-commander',
    name: 'Sword Wraith Commander',
    cr: 8,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Sword Wraith',
    alignment: 'lawful evil',
    environment: 'ruins',
    ac: 18,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 241'
  },
  {
    guid: '',
    fid: 'mtof.sword-wraith-warrior',
    name: 'Sword Wraith Warrior',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Sword Wraith',
    alignment: 'lawful evil',
    environment: 'ruins',
    ac: 16,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 241'
  },
  {
    guid: '',
    fid: 'mtof.the-angry',
    name: 'The Angry',
    cr: 13,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Sorrowsworn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 18,
    hp: 255,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 231'
  },
  {
    guid: '',
    fid: 'mtof.the-hungry',
    name: 'The Hungry',
    cr: 11,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Sorrowsworn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 17,
    hp: 225,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 232'
  },
  {
    guid: '',
    fid: 'mtof.the-lonely',
    name: 'The Lonely',
    cr: 9,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Sorrowsworn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 16,
    hp: 112,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 232'
  },
  {
    guid: '',
    fid: 'mtof.the-lost',
    name: 'The Lost',
    cr: 7,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Sorrowsworn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 15,
    hp: 78,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 233'
  },
  {
    guid: '',
    fid: 'mtof.the-wretched',
    name: 'The Wretched',
    cr: '1/4',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: 'Sorrowsworn',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 15,
    hp: 10,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 233'
  },
  {
    guid: '',
    fid: 'mtof.titivilus',
    name: 'Titivilus',
    cr: 16,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 20,
    hp: 127,
    init: 6,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 179'
  },
  {
    guid: '',
    fid: 'mtof.tortle',
    name: 'Tortle',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tortle',
    section: 'Tortles',
    alignment: 'lawful good',
    environment: 'coast, forest, grassland, mountain, swamp',
    ac: 17,
    hp: 22,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 242'
  },
  {
    guid: '',
    fid: 'mtof.tortle-druid',
    name: 'Tortle Druid',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tortle',
    section: 'Tortles',
    alignment: 'lawful neutral',
    environment: 'coast, forest, grassland, mountain, swamp',
    ac: 17,
    hp: 33,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 242'
  },
  {
    guid: '',
    fid: 'mtof.vampiric-mist',
    name: 'Vampiric Mist',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 30,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 246'
  },
  {
    guid: '',
    fid: 'mtof.venom-troll',
    name: 'Venom Troll',
    cr: 7,
    size: 'Large',
    type: 'Giant',
    tags: '',
    section: 'Trolls',
    alignment: 'chaotic evil',
    environment: 'dungeon, forest, mountain, ruins, swamp',
    ac: 15,
    hp: 94,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 245'
  },
  {
    guid: '',
    fid: 'mtof.wastrilith',
    name: 'Wastrilith',
    cr: 13,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'aquatic, planar',
    ac: 18,
    hp: 157,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 139'
  },
  {
    guid: '',
    fid: 'mtof.water-elemental-myrmidon',
    name: 'Water Elemental Myrmidon',
    cr: 7,
    size: 'Medium',
    type: 'Elemental',
    tags: '',
    section: 'Elder Myrmidons',
    alignment: 'neutral',
    environment: 'aquatic, planar',
    ac: 18,
    hp: 127,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 203'
  },
  {
    guid: '',
    fid: 'mtof.white-abishai',
    name: 'White Abishai',
    cr: 6,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Devils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 15,
    hp: 68,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 163'
  },
  {
    guid: '',
    fid: 'mtof.winter-eladrin',
    name: 'Winter Eladrin',
    cr: 10,
    size: 'Medium',
    type: 'Fey',
    tags: 'Elf',
    section: 'Eladrin',
    alignment: 'chaotic neutral',
    environment: 'planar',
    ac: 19,
    hp: 127,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 197'
  },
  {
    guid: '',
    fid: 'mtof.yagnoloth',
    name: 'Yagnoloth',
    cr: 11,
    size: 'Large',
    type: 'Fiend',
    tags: 'Yugoloth',
    section: 'Yugoloths',
    alignment: 'neutral evil',
    environment: 'planar',
    ac: 17,
    hp: 147,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 252'
  },
  {
    guid: '',
    fid: 'mtof.yeenoghu',
    name: 'Yeenoghu',
    cr: 24,
    size: 'Huge',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 20,
    hp: 333,
    init: 3,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 155'
  },
  {
    guid: '',
    fid: 'mtof.young-kruthik',
    name: 'Young Kruthik',
    cr: '1/8',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: 'Kruthiks',
    alignment: 'unaligned',
    environment: 'cave, underground',
    ac: 16,
    hp: 9,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 211'
  },
  {
    guid: '',
    fid: 'mtof.zaratan',
    name: 'Zaratan',
    cr: 22,
    size: 'Gargantuan',
    type: 'Elemental',
    tags: '',
    section: 'Elder Elementals',
    alignment: 'neutral',
    environment: 'planar',
    ac: 21,
    hp: 307,
    init: 0,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Mordenkainen\'s Tome of Foes: 201'
  },
  {
    guid: '',
    fid: 'mtof.zariel',
    name: 'Zariel',
    cr: 26,
    size: 'Large',
    type: 'Fiend',
    tags: 'Devil',
    section: 'Archdevils',
    alignment: 'lawful evil',
    environment: 'planar',
    ac: 21,
    hp: 580,
    init: 7,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 180'
  },
  {
    guid: '',
    fid: 'mtof.zuggtmoy',
    name: 'Zuggtmoy',
    cr: 23,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demon Lords',
    alignment: 'chaotic evil',
    environment: 'planar',
    ac: 18,
    hp: 304,
    init: 2,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Mordenkainen\'s Tome of Foes: 157'
  },
  {
    guid: '28c8c457-5a31-4c0d-9ffe-e42c8bb47d8b',
    fid: 'sking.crag-cat',
    name: 'Crag Cat',
    cr: 1,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 13,
    hp: 34,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 240'
  },
  {
    guid: '0a8b2f8f-16f5-4d79-8c56-fb28fdcb7b71',
    fid: 'sking.hulking-crab',
    name: 'Hulking Crab',
    cr: 5,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 17,
    hp: 76,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 240'
  },
  {
    guid: 'c5aab99f-f1f2-45d0-872b-645a9d5d41d6',
    fid: 'sking.iymrith-the-dragon',
    name: 'Iymrith the Dragon',
    cr: 23,
    size: 'Gargantuan',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 22,
    hp: 481,
    init: 0,
    lair: 'lair',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Storm King\'s Thunder: 241'
  },
  {
    guid: '7815b4c9-71e0-49a9-be1b-cb8caf448695',
    fid: 'sking.maegera-the-dawn-titan',
    name: 'Maegera the Dawn Titan',
    cr: 23,
    size: 'Gargantuan',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: '',
    ac: 16,
    hp: 341,
    init: 6,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Storm King\'s Thunder: 241'
  },
  {
    guid: '10580eaf-7424-46d8-a827-fb681e914fcb',
    fid: 'sking.purple-wormling',
    name: 'Purple Wormling',
    cr: 2,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 12,
    hp: 42,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 242'
  },
  {
    guid: '71b533e5-d65f-4fc9-b447-98aef9833200',
    fid: 'sking.tressym',
    name: 'Tressym',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: '',
    ac: 12,
    hp: 5,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 242'
  },
  {
    guid: '3e739adc-919f-4908-976d-292e6a77c39e',
    fid: 'sking.uthgardt-shaman',
    name: 'Uthgardt Shaman',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 38,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 243'
  },
  {
    guid: '0ac2d7e7-8936-4e86-8d41-5fb7b9fa82be',
    fid: 'sking.yakfolk-priest',
    name: 'Yakfolk Priest',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 12,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 245'
  },
  {
    guid: '8c4a7dd1-3e38-4ef5-ad17-d9d81590e202',
    fid: 'sking.yakfolk-warrior',
    name: 'Yakfolk Warrior',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 11,
    hp: 60,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Storm King\'s Thunder: 244'
  },
  {
    guid: '75e7baad-6bbc-45c2-bf63-5b9e13b4bebd',
    fid: 'strahd.baba-lysaga',
    name: 'Baba Lysaga',
    cr: 11,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 15,
    hp: 120,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 228'
  },
  {
    guid: 'f0502677-b3bc-45b5-9d2a-7372fddc1e34',
    fid: 'strahd.baba-lysagas-creeping-hut',
    name: 'Baba Lysaga\'s Creeping Hut',
    cr: 11,
    size: 'Gargantuan',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 16,
    hp: 263,
    init: -2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 226'
  },
  {
    guid: '0f608e49-e8f8-4a97-9fbc-5cc989138b2c',
    fid: 'strahd.barovian-witch',
    name: 'Barovian Witch',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 10,
    hp: 16,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 229'
  },
  {
    guid: 'e70abfb0-fa9f-4562-b65f-2fc63d4c2f9e',
    fid: 'strahd.broom-of-animated-attack',
    name: 'Broom of Animated Attack',
    cr: '1/4',
    size: 'Small',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 17,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 226'
  },
  {
    guid: 'a9a45b1e-1bdd-4e3c-ba90-001df9c3012d',
    fid: 'strahd.ezmerelda-davenir',
    name: 'Ezmerelda d\'Avenir',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'chaotic good',
    environment: '',
    ac: 17,
    hp: 82,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 231'
  },
  {
    guid: 'd7bd3f20-e8da-49dd-87f6-22c2ad01927f',
    fid: 'strahd.guardian-portrait',
    name: 'Guardian Portrait',
    cr: 1,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 5,
    hp: 22,
    init: -5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 227'
  },
  {
    guid: 'a80002c2-40b7-4dab-976f-bef0cfb514f7',
    fid: 'strahd.izek-strazni',
    name: 'Izek Strazni',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 14,
    hp: 112,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 232'
  },
  {
    guid: '79c90ee5-0e3d-437c-90e0-e79b11dba0c1',
    fid: 'strahd.madam-eva',
    name: 'Madam Eva',
    cr: 10,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'chaotic neutral',
    environment: '',
    ac: 10,
    hp: 88,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 233'
  },
  {
    guid: '2ff5978f-9383-4e56-8fe6-27961eedfe77',
    fid: 'strahd.mongrelfolk',
    name: 'Mongrelfolk',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Mongrelfolk',
    section: '',
    alignment: 'any',
    environment: '',
    ac: 11,
    hp: 26,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 234'
  },
  {
    guid: 'e267fda0-3c81-48e2-b299-34c519fe74e1',
    fid: 'strahd.phantom-warrior',
    name: 'Phantom Warrior',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'any',
    environment: '',
    ac: 16,
    hp: 45,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 235'
  },
  {
    guid: 'fd46aa89-2747-4146-a6d3-e82acaeca48c',
    fid: 'strahd.pidlewick-ii',
    name: 'Pidlewick II',
    cr: '1/4',
    size: 'Small',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 14,
    hp: 10,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 236'
  },
  {
    guid: '6bf0d8cb-cd69-4e17-85b8-7b8b35b5e5b1',
    fid: 'strahd.rahadin',
    name: 'Rahadin',
    cr: 10,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 18,
    hp: 135,
    init: 6,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 237'
  },
  {
    guid: '40906ebd-8164-4827-9b90-0b40a0b653ff',
    fid: 'strahd.rictavio',
    name: 'Rictavio',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful good',
    environment: '',
    ac: 12,
    hp: 77,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 238'
  },
  {
    guid: '80ff81db-e64f-48e4-8750-1b8002dec83e',
    fid: 'strahd.strahd-von-zarovich',
    name: 'Strahd von Zarovich',
    cr: 15,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 16,
    hp: 144,
    init: 4,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Curse of Strahd: 240'
  },
  {
    guid: '8c5b9532-9129-48bd-a7ba-2778dc429629',
    fid: 'strahd.strahd-zombie',
    name: 'Strahd Zombie',
    cr: 1,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 8,
    hp: 30,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 241'
  },
  {
    guid: 'c90192cf-fbf1-4bef-8794-f7d82bee36b5',
    fid: 'strahd.strahds-animated-armor',
    name: 'Strahd\'s Animated Armor',
    cr: 6,
    size: 'Medium',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 21,
    hp: 112,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 227'
  },
  {
    guid: '148e9550-d99f-4966-9179-00803048b7b9',
    fid: 'strahd.tree-blight',
    name: 'Tree Blight',
    cr: 7,
    size: 'Huge',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 149,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 230'
  },
  {
    guid: '4565f845-09c8-4842-b298-ad3dd4055b53',
    fid: 'strahd.vladimir-horngaard',
    name: 'Vladimir Horngaard',
    cr: 7,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 17,
    hp: 192,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Curse of Strahd: 241'
  },
  {
    guid: '2f23a622-a37b-4c8e-8e1c-a91b11ca6983',
    fid: 'strahd.wereraven',
    name: 'Wereraven',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human, Shapechanger',
    section: '',
    alignment: 'lawful good',
    environment: '',
    ac: 12,
    hp: 31,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Curse of Strahd: 242'
  },
  {
    guid: '',
    fid: 'tftyp.animated-table',
    name: 'Animated Table',
    cr: 2,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 39,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 230'
  },
  {
    guid: '',
    fid: 'tftyp.centaur-mummy',
    name: 'Centaur Mummy',
    cr: 6,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 13,
    hp: 85,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 231'
  },
  {
    guid: '',
    fid: 'tftyp.choker',
    name: 'Choker',
    cr: 1,
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 16,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 232'
  },
  {
    guid: '',
    fid: 'tftyp.deathlock-wight',
    name: 'Deathlock Wight',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 12,
    hp: 37,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 233'
  },
  {
    guid: '',
    fid: 'tftyp.dread-warrior',
    name: 'Dread Warrior',
    cr: 1,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 18,
    hp: 37,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 233'
  },
  {
    guid: '',
    fid: 'tftyp.duergar-spy',
    name: 'Duergar Spy',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 15,
    hp: 33,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 234'
  },
  {
    guid: '',
    fid: 'tftyp.giant-crayfish',
    name: 'Giant Crayfish',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 235'
  },
  {
    guid: '',
    fid: 'tftyp.giant-ice-toad',
    name: 'Giant Ice Toad',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: '',
    ac: 14,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 235'
  },
  {
    guid: '',
    fid: 'tftyp.giant-lightning-eel',
    name: 'Giant Lightning Eel',
    cr: 3,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 13,
    hp: 42,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 236'
  },
  {
    guid: '',
    fid: 'tftyp.giant-skeleton',
    name: 'Giant Skeleton',
    cr: 7,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 17,
    hp: 115,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 236'
  },
  {
    guid: '',
    fid: 'tftyp.giant-subterranean-lizard',
    name: 'Giant Subterranean Lizard',
    cr: 4,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 14,
    hp: 66,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 236'
  },
  {
    guid: '',
    fid: 'tftyp.greater-zombie',
    name: 'Greater Zombie',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 97,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 237'
  },
  {
    guid: '',
    fid: 'tftyp.kalka-kylla',
    name: 'Kalka-Kylla',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: '',
    ac: 15,
    hp: 85,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 238'
  },
  {
    guid: '',
    fid: 'tftyp.kelpie',
    name: 'Kelpie',
    cr: 4,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 14,
    hp: 67,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 238'
  },
  {
    guid: '',
    fid: 'tftyp.leucrotta',
    name: 'Leucrotta',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 14,
    hp: 67,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 239'
  },
  {
    guid: '',
    fid: 'tftyp.malformed-kraken',
    name: 'Malformed Kraken',
    cr: 10,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 17,
    hp: 172,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 239'
  },
  {
    guid: '',
    fid: 'tftyp.nereid',
    name: 'Nereid',
    cr: 2,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'any chaotic',
    environment: '',
    ac: 13,
    hp: 44,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 240'
  },
  {
    guid: '',
    fid: 'tftyp.ooze-master',
    name: 'Ooze Master',
    cr: 10,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 9,
    hp: 138,
    init: -5,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 241'
  },
  {
    guid: '',
    fid: 'tftyp.sea-lion',
    name: 'Sea Lion',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 90,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 242'
  },
  {
    guid: '',
    fid: 'tftyp.sharwyn-hucrele',
    name: 'Sharwyn Hucrele',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 16,
    hp: 13,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 242'
  },
  {
    guid: '',
    fid: 'tftyp.sir-braford',
    name: 'Sir Braford',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 18,
    hp: 19,
    init: -1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 243'
  },
  {
    guid: '',
    fid: 'tftyp.siren',
    name: 'Siren',
    cr: 3,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic good',
    environment: '',
    ac: 14,
    hp: 38,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 243'
  },
  {
    guid: '',
    fid: 'tftyp.tarul-var',
    name: 'Tarul Var',
    cr: 13,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 16,
    hp: 105,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 244'
  },
  {
    guid: '',
    fid: 'tftyp.tecuziztecatl',
    name: 'Tecuziztecatl',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: '',
    ac: 13,
    hp: 102,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 245'
  },
  {
    guid: '',
    fid: 'tftyp.thayan-apprentice',
    name: 'Thayan Apprentice',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'non-good',
    environment: '',
    ac: 12,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 245'
  },
  {
    guid: '',
    fid: 'tftyp.thayan-warrior',
    name: 'Thayan Warrior',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'non-good',
    environment: '',
    ac: 16,
    hp: 52,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 246'
  },
  {
    guid: '',
    fid: 'tftyp.thorn-slinger',
    name: 'Thorn Slinger',
    cr: '1/2',
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 11,
    hp: 32,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 246'
  },
  {
    guid: '',
    fid: 'tftyp.vampiric-mist',
    name: 'Vampiric Mist',
    cr: 3,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 45,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tales from the Yawning Portal: 247'
  },
  {
    guid: '',
    fid: 'tftyp.white-maw',
    name: 'White Maw',
    cr: 10,
    size: 'Gargantuan',
    type: 'Ooze',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: '',
    ac: 5,
    hp: 217,
    init: -5,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 248'
  },
  {
    guid: '',
    fid: 'tftyp.yusdrayl',
    name: 'Yusdrayl',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 12,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tales from the Yawning Portal: 248'
  },
  {
    guid: '14062b7c-449d-4dd8-b936-e6c3054bc4dd',
    fid: 'tiamat.dragonfang',
    name: 'Dragonfang',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, urban',
    ac: 15,
    hp: 78,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 89'
  },
  {
    guid: 'e69f331d-95ce-4ce4-a45e-ffea0ffc0892',
    fid: 'tiamat.dragonsoul',
    name: 'Dragonsoul',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, urban',
    ac: 16,
    hp: 110,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 89'
  },
  {
    guid: '1280e58c-b863-4b7a-9753-359b3c76ef86',
    fid: 'tiamat.dragonwing',
    name: 'Dragonwing',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon, urban',
    ac: 14,
    hp: 33,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 90'
  },
  {
    guid: 'b47a1d38-ae42-4c8a-bd89-4cf83cdfc99e',
    fid: 'tiamat.ice-toad',
    name: 'Ice Toad',
    cr: 1,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'arctic',
    ac: 12,
    hp: 32,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 90'
  },
  {
    guid: '7e036002-2280-456a-82de-821060bebc86',
    fid: 'tiamat.naergoth-bladelord',
    name: 'Naergoth Bladelord',
    cr: 11,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'dungeon',
    ac: 18,
    hp: 135,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 90'
  },
  {
    guid: '652fe147-4367-4aa9-bf37-63ff054d034f',
    fid: 'tiamat.neronvain',
    name: 'Neronvain',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Elf',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 17,
    hp: 117,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Rise of Tiamat: 91'
  },
  {
    guid: 'decbfdf7-40a4-400c-9dad-92ca6869153d',
    fid: 'tiamat.severin',
    name: 'Severin',
    cr: 11,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 16,
    hp: 150,
    init: 1,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Rise of Tiamat: 92'
  },
  {
    guid: 'ed941a4e-0d5f-449e-973c-50f1e737501a',
    fid: 'tiamat.tiamat',
    name: 'Tiamat',
    cr: 30,
    size: 'Gargantuan',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 25,
    hp: 615,
    init: 0,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Rise of Tiamat: 92'
  },
  {
    guid: '',
    fid: 'toa.acererak',
    name: 'Acererak',
    cr: 23,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 21,
    hp: 285,
    init: 3,
    lair: '',
    legendary: 'legendary',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 209'
  },
  {
    guid: '',
    fid: 'toa.albino-dwarf-warrior',
    name: 'Albino Dwarf Warrior',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Dwarf',
    section: '',
    alignment: 'any alignment',
    environment: '',
    ac: 13,
    hp: 30,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 210'
  },
  {
    guid: '',
    fid: 'toa.aldani-lobsterfolk',
    name: 'Aldani (Lobsterfolk)',
    cr: 1,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'lawful neutral',
    environment: '',
    ac: 14,
    hp: 49,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 210'
  },
  {
    guid: '',
    fid: 'toa.almiraj',
    name: 'Almiraj',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 13,
    hp: 3,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 211'
  },
  {
    guid: '',
    fid: 'toa.ankylosaurus-zombie',
    name: 'Ankylosaurus Zombie',
    cr: 3,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'unaligned',
    environment: '',
    ac: 14,
    hp: 68,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 240'
  },
  {
    guid: '',
    fid: 'toa.artus-cimber',
    name: 'Artus Cimber',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'neutral good',
    environment: '',
    ac: 14,
    hp: 82,
    init: 2,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 212'
  },
  {
    guid: '',
    fid: 'toa.assassin-vine',
    name: 'Assassin Vine',
    cr: 3,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 13,
    hp: 85,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 213'
  },
  {
    guid: '',
    fid: 'toa.atropal',
    name: 'Atropal',
    cr: 13,
    size: 'Huge',
    type: 'Undead',
    tags: 'Titan',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 7,
    hp: 225,
    init: -3,
    lair: '',
    legendary: 'legendary',
    unique: '',
    sources: 'Tomb of Annihilation: 214'
  },
  {
    guid: '',
    fid: 'toa.chwinga',
    name: 'Chwinga',
    cr: 0,
    size: 'Tiny',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: '',
    ac: 15,
    hp: 5,
    init: 5,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 216'
  },
  {
    guid: '',
    fid: 'toa.dragonbait',
    name: 'Dragonbait',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Saurial',
    section: '',
    alignment: 'lawful good',
    environment: '',
    ac: 17,
    hp: 120,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 218'
  },
  {
    guid: '',
    fid: 'toa.eblis',
    name: 'Eblis',
    cr: 1,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 26,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 219'
  },
  {
    guid: '',
    fid: 'toa.flying-monkey',
    name: 'Flying Monkey',
    cr: 0,
    size: 'Small',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 12,
    hp: 3,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 220'
  },
  {
    guid: '',
    fid: 'toa.giant-four-armed-gargoyle',
    name: 'Giant Four-Armed Gargoyle',
    cr: 10,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 17,
    hp: 147,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 221'
  },
  {
    guid: '',
    fid: 'toa.giant-snapping-turtle',
    name: 'Giant Snapping Turtle',
    cr: 3,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 17,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 222'
  },
  {
    guid: '',
    fid: 'toa.girallon-zombie',
    name: 'Girallon Zombie',
    cr: 3,
    size: 'Large',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'chaotic evil',
    environment: '',
    ac: 11,
    hp: 59,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 240'
  },
  {
    guid: '',
    fid: 'toa.jaculi',
    name: 'Jaculi',
    cr: '1/2',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 14,
    hp: 16,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 225'
  },
  {
    guid: '',
    fid: 'toa.kamadan',
    name: 'Kamadan',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 13,
    hp: 67,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 225'
  },
  {
    guid: '',
    fid: 'toa.liara-portyr',
    name: 'Liara Portyr',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful evil',
    environment: '',
    ac: 15,
    hp: 84,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 227'
  },
  {
    guid: '',
    fid: 'toa.mantrap',
    name: 'Mantrap',
    cr: 1,
    size: 'Large',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 12,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 227'
  },
  {
    guid: '',
    fid: 'toa.mwaxanare',
    name: 'Mwaxanare',
    cr: '1/8',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'lawful neutral',
    environment: '',
    ac: 10,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 228'
  },
  {
    guid: '',
    fid: 'toa.pterafolk',
    name: 'Pterafolk',
    cr: 1,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 12,
    hp: 26,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 229'
  },
  {
    guid: '',
    fid: 'toa.ras-nsi',
    name: 'Ras Nsi',
    cr: 7,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 15,
    hp: 127,
    init: 3,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 230'
  },
  {
    guid: '',
    fid: 'toa.stone-juggernaut',
    name: 'Stone Juggernaut',
    cr: 12,
    size: 'Large',
    type: 'Construct',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 15,
    hp: 157,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 231'
  },
  {
    guid: '',
    fid: 'toa.su-monster',
    name: 'Su-monster',
    cr: 1,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 12,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 232'
  },
  {
    guid: '',
    fid: 'toa.tabaxi-hunter',
    name: 'Tabaxi Hunter',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tabaxi',
    section: '',
    alignment: 'chaotic good',
    environment: '',
    ac: 14,
    hp: 40,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 232'
  },
  {
    guid: '',
    fid: 'toa.tabaxi-ministrel',
    name: 'Tabaxi Ministrel',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Tabaxi',
    section: '',
    alignment: 'chaotic good',
    environment: '',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 233'
  },
  {
    guid: '',
    fid: 'toa.tri-flower-frond',
    name: 'Tri-flower Frond',
    cr: '1/2',
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 10,
    hp: 11,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 234'
  },
  {
    guid: '',
    fid: 'toa.tyrannosaurus-zombie',
    name: 'Tyrannosaurus Zombie',
    cr: 8,
    size: 'Huge',
    type: 'Undead',
    tags: '',
    section: 'Zombies',
    alignment: 'unaligned',
    environment: '',
    ac: 11,
    hp: 136,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 241'
  },
  {
    guid: '',
    fid: 'toa.volothamp-volo-geddarm',
    name: 'Volothamp "Volo" Geddarm',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Human',
    section: '',
    alignment: 'chaotic good',
    environment: '',
    ac: 11,
    hp: 31,
    init: 1,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 235'
  },
  {
    guid: '',
    fid: 'toa.xandala',
    name: 'Xandala',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-elf',
    section: '',
    alignment: 'neutral evil',
    environment: '',
    ac: 13,
    hp: 71,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 236'
  },
  {
    guid: '',
    fid: 'toa.yellow-musk-creeper',
    name: 'Yellow Musk Creeper',
    cr: 2,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: 'Yellow Musk Creeper and Zombie',
    alignment: 'unaligned',
    environment: '',
    ac: 6,
    hp: 60,
    init: -4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 237'
  },
  {
    guid: '',
    fid: 'toa.yellow-musk-zombie',
    name: 'Yellow Musk Zombie',
    cr: '1/4',
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Yellow Musk Creeper and Zombie',
    alignment: 'unaligned',
    environment: '',
    ac: 9,
    hp: 33,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 237'
  },
  {
    guid: '',
    fid: 'toa.zindar',
    name: 'Zindar',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Half-dragon',
    section: '',
    alignment: 'lawful good',
    environment: '',
    ac: 13,
    hp: 110,
    init: 0,
    lair: '',
    legendary: '',
    unique: 'unique',
    sources: 'Tomb of Annihilation: 239'
  },
  {
    guid: '',
    fid: 'toa.zorbo',
    name: 'Zorbo',
    cr: '1/2',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 10,
    hp: 27,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Tomb of Annihilation: 241'
  },
  {
    guid: '',
    fid: 'tortle.decapus',
    name: 'Decapus',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: '',
    ac: 14,
    hp: 75,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'The Tortle Package: 21'
  },
  {
    guid: '',
    fid: 'tortle.geonid',
    name: 'Geonid',
    cr: '1/4',
    size: 'Small',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'cave',
    ac: 17,
    hp: 26,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'The Tortle Package: 22'
  },
  {
    guid: '',
    fid: 'tortle.topi',
    name: 'Topi',
    cr: '1/2',
    size: 'Small',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: '',
    ac: 13,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'The Tortle Package: 22'
  },
  {
    guid: 'c59317ff-3950-4548-8a5c-29f6977f0d62',
    fid: 'volo.abjurer',
    name: 'Abjurer',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 84,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 209'
  },
  {
    guid: '2453a9e3-8aaa-4863-a50a-e2f0f2b91544',
    fid: 'volo.alhoon',
    name: 'Alhoon',
    cr: 10,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Mind Flayers',
    alignment: 'any evil',
    environment: 'underground',
    ac: 15,
    hp: 120,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 172'
  },
  {
    guid: 'f70647ec-88aa-4d87-a389-4d0045bb14a4',
    fid: 'volo.annis-hag',
    name: 'Annis Hag',
    cr: 6,
    size: 'Large',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'chaotic evil',
    environment: 'grassland, mountain',
    ac: 17,
    hp: 75,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 159'
  },
  {
    guid: 'b2e98789-523a-44fb-83c1-0e73e53c4c82',
    fid: 'volo.apprentice-wizard',
    name: 'Apprentice Wizard',
    cr: '1/4',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 10,
    hp: 9,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 209'
  },
  {
    guid: 'a00e226f-91a2-4332-ba45-8b0ae5e5ebfc',
    fid: 'volo.archdruid',
    name: 'Archdruid',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'forest, mountain, swamp, aquatic',
    ac: 16,
    hp: 132,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 210'
  },
  {
    guid: '9a3c941c-98a8-4bb8-9710-0b3edf4edf1d',
    fid: 'volo.archer',
    name: 'Archer',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'forest, urban',
    ac: 16,
    hp: 75,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 210'
  },
  {
    guid: 'fadbf43c-75c4-49e1-95f7-99675ee7f93c',
    fid: 'volo.aurochs',
    name: 'Aurochs',
    cr: 2,
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'grassland, mountain',
    ac: 11,
    hp: 38,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 207'
  },
  {
    guid: '30a9ed8c-5fd9-46f1-ab97-6a3d6645eb9a',
    fid: 'volo.babau',
    name: 'Babau',
    cr: 4,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 16,
    hp: 82,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 136'
  },
  {
    guid: 'c80a939f-3eb8-411d-b706-fd854706ce1b',
    fid: 'volo.banderhobb',
    name: 'Banderhobb',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'urban',
    ac: 15,
    hp: 84,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 122'
  },
  {
    guid: '54fb5ff9-91ef-4060-abf5-86d4c0913c32',
    fid: 'volo.bard',
    name: 'Bard',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 15,
    hp: 44,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 211'
  },
  {
    guid: 'efca8fda-9530-4c93-980b-7f3373403b94',
    fid: 'volo.barghest',
    name: 'Barghest',
    cr: 4,
    size: 'Large',
    type: 'Fiend',
    tags: 'Shapechanger',
    section: '',
    alignment: 'neutral evil',
    environment: 'forest, grassland, mountain, underground',
    ac: 17,
    hp: 90,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 123, Tales from the Yawning Portal: 230'
  },
  {
    guid: '36445a43-c0d2-4a75-80a7-e87e05603ae6',
    fid: 'volo.bheur-hag',
    name: 'Bheur Hag',
    cr: 7,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Hags',
    alignment: 'chaotic evil',
    environment: 'arctic',
    ac: 17,
    hp: 91,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 160'
  },
  {
    guid: '732330e6-817c-4de6-8e8e-b702f260761f',
    fid: 'volo.blackguard',
    name: 'Blackguard',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'non-good',
    environment: 'underground, urban',
    ac: 18,
    hp: 153,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 211'
  },
  {
    guid: 'a33ac49f-60a3-4195-8c99-70e7277acbf9',
    fid: 'volo.bodak',
    name: 'Bodak',
    cr: 6,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'swamp, underground, urban',
    ac: 15,
    hp: 58,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 127, Tomb of Annihilation: 215'
  },
  {
    guid: '8cdd733f-c78e-4a92-a451-4ab459682aaa',
    fid: 'volo.boggle',
    name: 'Boggle',
    cr: '1/8',
    size: 'Small',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'forest, grassland, underground, urban',
    ac: 14,
    hp: 18,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 128'
  },
  {
    guid: 'd7bda54e-5efc-4196-a709-80caa0e441e1',
    fid: 'volo.brontosaurus',
    name: 'Brontosaurus',
    cr: 5,
    size: 'Gargantuan',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 15,
    hp: 121,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 139, Tomb of Annihilation: 215'
  },
  {
    guid: '6d5a9c50-fedc-414e-95aa-522a2a5bef5d',
    fid: 'volo.catoblepas',
    name: 'Catoblepas',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'swamp',
    ac: 14,
    hp: 84,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 129'
  },
  {
    guid: '4cc42a2f-f24c-43f5-904e-653cd82945a2',
    fid: 'volo.cave-fisher',
    name: 'Cave Fisher',
    cr: 3,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'underground',
    ac: 16,
    hp: 58,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 130'
  },
  {
    guid: 'fbe8bd0e-da21-4096-a08f-49d414fbeaa9',
    fid: 'volo.champion',
    name: 'Champion',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'desert, urban',
    ac: 18,
    hp: 143,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 212, Tales from the Yawning Portal: 231, Tomb of Annihilation: 216'
  },
  {
    guid: 'f35b9f35-6bd4-47f1-aaaf-305a8877c28c',
    fid: 'volo.chitine',
    name: 'Chitine',
    cr: '1/2',
    size: 'Small',
    type: 'Monstrosity',
    tags: '',
    section: 'Chitines',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 14,
    hp: 18,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 131'
  },
  {
    guid: '18f0b7ed-db92-4567-ab9f-91506439296b',
    fid: 'volo.choldrith',
    name: 'Choldrith',
    cr: 3,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: 'Chitines',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 15,
    hp: 66,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 132'
  },
  {
    guid: '66b29d8c-d2b2-4d46-8a83-109828daffa5',
    fid: 'volo.cloud-giant-smiling-one',
    name: 'Cloud Giant Smiling One',
    cr: 11,
    size: 'Huge',
    type: 'Giant',
    tags: 'Cloud Giant',
    section: 'Giants',
    alignment: 'chaotic neutral',
    environment: 'mountain',
    ac: 15,
    hp: 262,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 146'
  },
  {
    guid: 'e207f392-8307-4b67-968b-f49fe8ba7cf2',
    fid: 'volo.conjurer',
    name: 'Conjurer',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 40,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 212, Tales from the Yawning Portal: 232'
  },
  {
    guid: 'f7def5c8-1b2c-4a36-8bb6-c87c74053dbd',
    fid: 'volo.cow',
    name: 'Cow',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 10,
    hp: 15,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 207'
  },
  {
    guid: '8c8e238a-0c86-43d0-aa45-7860b9da17aa',
    fid: 'volo.cow-ox',
    name: 'Cow (Ox)',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'urban',
    ac: 10,
    hp: 15,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 208'
  },
  {
    guid: 'b469005c-564e-457b-9776-40a65501a171',
    fid: 'volo.cranium-rat',
    name: 'Cranium Rat',
    cr: 0,
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Cranium Rats',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 12,
    hp: 2,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 133'
  },
  {
    guid: '1687a030-f448-44cd-acb8-464494bfb54f',
    fid: 'volo.darkling',
    name: 'Darkling',
    cr: '1/2',
    size: 'Small',
    type: 'Fey',
    tags: '',
    section: 'Darklings',
    alignment: 'chaotic neutral',
    environment: 'forest, swamp, underground, urban',
    ac: 14,
    hp: 13,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 134'
  },
  {
    guid: '67b78e36-a770-4fe1-9056-c049c27f9549',
    fid: 'volo.darkling-elder',
    name: 'Darkling Elder',
    cr: 2,
    size: 'Medium',
    type: 'Fey',
    tags: '',
    section: 'Darklings',
    alignment: 'chaotic neutral',
    environment: 'forest, swamp, underground, urban',
    ac: 15,
    hp: 27,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 134'
  },
  {
    guid: '55a7bf90-28ae-45e0-87a6-bdf486a94825',
    fid: 'volo.death-kiss',
    name: 'Death Kiss',
    cr: 10,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'neutral evil',
    environment: 'underground',
    ac: 16,
    hp: 161,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 124'
  },
  {
    guid: 'bb09f8e7-27dc-4026-9017-7ac0502aad5b',
    fid: 'volo.deep-scion',
    name: 'Deep Scion',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Shapechanger',
    section: '',
    alignment: 'neutral evil',
    environment: 'coast, aquatic',
    ac: 11,
    hp: 67,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 135'
  },
  {
    guid: 'bd9c09e0-1360-464c-bd14-bad41cfc60c0',
    fid: 'volo.deinonychus',
    name: 'Deinonychus',
    cr: 1,
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 13,
    hp: 26,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 139, Tomb of Annihilation: 217'
  },
  {
    guid: '0c8931e1-2bcf-4507-9c41-c040221d0f6c',
    fid: 'volo.devourer',
    name: 'Devourer',
    cr: 13,
    size: 'Large',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 16,
    hp: 178,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 138'
  },
  {
    guid: '9dd0c06e-e952-4915-b224-aae56984ec83',
    fid: 'volo.dimetrodon',
    name: 'Dimetrodon',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'swamp',
    ac: 12,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 139, Tomb of Annihilation: 217'
  },
  {
    guid: '1af0dad1-0468-4fe7-a53c-2d75420bf202',
    fid: 'volo.diviner',
    name: 'Diviner',
    cr: 8,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 67,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 213'
  },
  {
    guid: '6797c463-8d3a-4bda-8073-db40a364a058',
    fid: 'volo.dolphin',
    name: 'Dolphin',
    cr: '1/8',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'coast, aquatic',
    ac: 12,
    hp: 11,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 208'
  },
  {
    guid: 'f423a0b9-5500-4283-9553-541a2f5893bc',
    fid: 'volo.draegloth',
    name: 'Draegloth',
    cr: 7,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 15,
    hp: 123,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 141'
  },
  {
    guid: 'c4a5e124-2aa6-40a5-800c-43148b0fbc99',
    fid: 'volo.elder-brain',
    name: 'Elder Brain',
    cr: 14,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Mind Flayers',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 10,
    hp: 210,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 173'
  },
  {
    guid: 'ccf03973-5098-478b-9573-6cd0d039b226',
    fid: 'volo.enchanter',
    name: 'Enchanter',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 40,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 213, Tales from the Yawning Portal: 234'
  },
  {
    guid: 'a39711ac-adde-4465-8b28-f2a0247def78',
    fid: 'volo.evoker',
    name: 'Evoker',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 66,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 214, Tales from the Yawning Portal: 235'
  },
  {
    guid: '020c9ef9-6365-4c3c-a200-4dd01865befc',
    fid: 'volo.fire-giant-dreadnought',
    name: 'Fire Giant Dreadnought',
    cr: 14,
    size: 'Huge',
    type: 'Giant',
    tags: 'Fire Giant',
    section: 'Giants',
    alignment: 'lawful evil',
    environment: 'mountain, underground',
    ac: 21,
    hp: 187,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 147'
  },
  {
    guid: '79827bf3-c000-4b9c-86a8-f1a58b43f313',
    fid: 'volo.firenewt-warlock-of-imix',
    name: 'Firenewt Warlock of Imix',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Firenewt',
    section: 'Firenewts',
    alignment: 'neutral evil',
    environment: 'grassland, mountain, underground',
    ac: 10,
    hp: 33,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 143, Tomb of Annihilation: 219'
  },
  {
    guid: 'e2175890-b6c6-49c1-bf53-1d821bb4d21a',
    fid: 'volo.firenewt-warrior',
    name: 'Firenewt Warrior',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Firenewt',
    section: 'Firenewts',
    alignment: 'neutral evil',
    environment: 'grassland, mountain, underground',
    ac: 16,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 142, Tomb of Annihilation: 219'
  },
  {
    guid: '501a8481-979a-4349-870c-c7d527be03db',
    fid: 'volo.flail-snail',
    name: 'Flail Snail',
    cr: 3,
    size: 'Large',
    type: 'Elemental',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest, swamp, underground',
    ac: 16,
    hp: 52,
    init: -3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 144, Tomb of Annihilation: 220'
  },
  {
    guid: '7b70915e-78d5-4bfe-b8a3-5c2aaa680da8',
    fid: 'volo.flind',
    name: 'Flind',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, forest, grassland',
    ac: 16,
    hp: 127,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 153'
  },
  {
    guid: 'c88651d6-10c0-4b08-a6a0-ad226f86652d',
    fid: 'volo.froghemoth',
    name: 'Froghemoth',
    cr: 10,
    size: 'Huge',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'swamp, underground',
    ac: 14,
    hp: 184,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 145, Tomb of Annihilation: 221'
  },
  {
    guid: '77374683-ee85-4dd3-b6ae-fda0c2e490c5',
    fid: 'volo.frost-giant-everlasting-one',
    name: 'Frost Giant Everlasting One',
    cr: 12,
    size: 'Huge',
    type: 'Giant',
    tags: 'Frost Giant',
    section: 'Giants',
    alignment: 'chaotic evil',
    environment: 'arctic, coast',
    ac: 15,
    hp: 189,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 148'
  },
  {
    guid: 'd72537f3-e20c-4c8c-8186-7d6c7efc249f',
    fid: 'volo.gauth',
    name: 'Gauth',
    cr: 6,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 15,
    hp: 67,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 125'
  },
  {
    guid: '1c337d34-8887-44dc-8b20-4c250ca6c2c5',
    fid: 'volo.gazer',
    name: 'Gazer',
    cr: '1/2',
    size: 'Tiny',
    type: 'Aberration',
    tags: '',
    section: 'Beholders',
    alignment: 'neutral evil',
    environment: 'underground',
    ac: 13,
    hp: 13,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 126'
  },
  {
    guid: '9f92a394-a7a3-43b4-affa-473c73c01112',
    fid: 'volo.giant-strider',
    name: 'Giant Strider',
    cr: 1,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: 'Firenewts',
    alignment: 'neutral evil',
    environment: 'grassland, mountain, underground',
    ac: 14,
    hp: 22,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 143, Tomb of Annihilation: 222'
  },
  {
    guid: '8fe276ab-13c5-4a24-b998-23f9490bc049',
    fid: 'volo.girallon',
    name: 'Girallon',
    cr: 4,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'forest',
    ac: 13,
    hp: 59,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 152, Tomb of Annihilation: 222'
  },
  {
    guid: '1377d091-b506-4969-83a5-2e15ebbc5ceb',
    fid: 'volo.gnoll-flesh-gnawer',
    name: 'Gnoll Flesh Gnawer',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, forest, grassland',
    ac: 14,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 154'
  },
  {
    guid: '5484ec7e-b482-4a2c-8d13-46cd775436c6',
    fid: 'volo.gnoll-hunter',
    name: 'Gnoll Hunter',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Gnoll',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, forest, grassland',
    ac: 13,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 154'
  },
  {
    guid: 'a947f2aa-a0f0-4c5b-83e6-190de7616b41',
    fid: 'volo.gnoll-witherling',
    name: 'Gnoll Witherling',
    cr: '1/4',
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Gnolls',
    alignment: 'chaotic evil',
    environment: 'arctic, forest, grassland',
    ac: 12,
    hp: 11,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 155'
  },
  {
    guid: 'da56cbc6-b72a-4472-9910-07661977337b',
    fid: 'volo.grung',
    name: 'Grung',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Grung',
    section: 'Grungs',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 12,
    hp: 11,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 156, Tomb of Annihilation: 223'
  },
  {
    guid: '5c0f26e8-790f-4c79-a3df-cfaa21537d6f',
    fid: 'volo.grung-elite-warrior',
    name: 'Grung Elite Warrior',
    cr: 2,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Grung',
    section: 'Grungs',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 13,
    hp: 49,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 157, Tomb of Annihilation: 223'
  },
  {
    guid: '4f42ad2b-d9b0-47d7-8fc3-0f446b7ad848',
    fid: 'volo.grung-wildling',
    name: 'Grung Wildling',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Grung',
    section: 'Grungs',
    alignment: 'lawful evil',
    environment: 'forest',
    ac: 13,
    hp: 27,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 157, Tomb of Annihilation: 224'
  },
  {
    guid: '8f11d800-1103-492b-ae24-320ef1013644',
    fid: 'volo.guard-drake',
    name: 'Guard Drake',
    cr: 2,
    size: 'Medium',
    type: 'Dragon',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'dungeon, urban',
    ac: 14,
    hp: 52,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Hoard of the Dragon Queen: 91, Rise of Tiamat: 90, Volo\'s Guide to Monsters: 158'
  },
  {
    guid: '2c47a6bb-caac-434c-a71e-6bdb82d03cf7',
    fid: 'volo.hadrosaurus',
    name: 'Hadrosaurus',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'grassland, swamp',
    ac: 11,
    hp: 19,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 140, Tomb of Annihilation: 224'
  },
  {
    guid: '8b90ae47-ee46-4b14-afb7-4a12a6c500e2',
    fid: 'volo.hobgoblin-devastator',
    name: 'Hobgoblin Devastator',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Hobgoblins',
    alignment: 'lawful evil',
    environment: 'forest, grassland',
    ac: 13,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 161'
  },
  {
    guid: 'cc0e5ae5-8819-4694-a26a-126d66a1fdab',
    fid: 'volo.hobgoblin-iron-shadow',
    name: 'Hobgoblin Iron Shadow',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: 'Hobgoblins',
    alignment: 'lawful evil',
    environment: 'forest, grassland',
    ac: 15,
    hp: 32,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 162'
  },
  {
    guid: 'fd3b4e47-8210-4d3a-9571-431c164c2747',
    fid: 'volo.illusionist',
    name: 'Illusionist',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 38,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 214, Tales from the Yawning Portal: 237'
  },
  {
    guid: '79401bb2-32be-48b5-a7c8-ef212ec99cfe',
    fid: 'volo.ki-rin',
    name: 'Ki-rin',
    cr: 12,
    size: 'Huge',
    type: 'Celestial',
    tags: '',
    section: '',
    alignment: 'lawful good',
    environment: 'coast, desert, grassland, mountain',
    ac: 20,
    hp: 152,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 163'
  },
  {
    guid: 'd1b0378d-9bdc-4c31-a23d-70a8a9368b9d',
    fid: 'volo.kobold-dragonshield',
    name: 'Kobold Dragonshield',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: 'Kobolds',
    alignment: 'lawful evil',
    environment: 'forest, grassland, mountain, underground, urban',
    ac: 15,
    hp: 44,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 165'
  },
  {
    guid: 'ca07f8dc-fd62-4ce6-95d5-37f59b7b8377',
    fid: 'volo.kobold-inventor',
    name: 'Kobold Inventor',
    cr: '1/4',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: 'Kobolds',
    alignment: 'lawful evil',
    environment: 'forest, grassland, mountain, underground, urban',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 166, Tomb of Annihilation: 226'
  },
  {
    guid: 'a67eedd6-934c-4622-9de3-0e869c1e1e44',
    fid: 'volo.kobold-scale-sorcerer',
    name: 'Kobold Scale Sorcerer',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Kobold',
    section: 'Kobolds',
    alignment: 'lawful evil',
    environment: 'forest, grassland, mountain, underground, urban',
    ac: 15,
    hp: 27,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 167, Tomb of Annihilation: 226'
  },
  {
    guid: 'a0982a32-cc7f-40c8-b839-fe33a9242462',
    fid: 'volo.korred',
    name: 'Korred',
    cr: 7,
    size: 'Small',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic neutral',
    environment: 'forest',
    ac: 17,
    hp: 102,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 168'
  },
  {
    guid: 'c128a8c7-997b-44a5-805c-f718cf90953f',
    fid: 'volo.kraken-priest',
    name: 'Kraken Priest',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'coast, aquatic',
    ac: 10,
    hp: 75,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 215'
  },
  {
    guid: 'e5c7fbd0-16f6-47ab-a2b1-297a9baa140f',
    fid: 'volo.leucrotta',
    name: 'Leucrotta',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'desert, grassland',
    ac: 14,
    hp: 67,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 169'
  },
  {
    guid: '69b0d522-7ad1-4909-86f5-a8f716afd9d0',
    fid: 'volo.martial-arts-adept',
    name: 'Martial Arts Adept',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 16,
    hp: 60,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 216, Tales from the Yawning Portal: 240'
  },
  {
    guid: '6272a878-47ee-43b8-99be-0fbc5af1a8e2',
    fid: 'volo.master-thief',
    name: 'Master Thief',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 16,
    hp: 84,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 216'
  },
  {
    guid: '3414fdd4-a1ca-4501-87fb-e6c571d134a6',
    fid: 'volo.maw-demon',
    name: 'Maw Demon',
    cr: 1,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 13,
    hp: 33,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 137'
  },
  {
    guid: '21fd5290-e0e5-4ca7-85e6-729b083335ef',
    fid: 'volo.meenlock',
    name: 'Meenlock',
    cr: 2,
    size: 'Small',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'forest, swamp, urban',
    ac: 15,
    hp: 31,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 170'
  },
  {
    guid: '41c4c37c-2cc1-4bd9-8332-2494f89d37fc',
    fid: 'volo.mind-flayer-lich-illithilich',
    name: 'Mind Flayer Lich (Illithilich)',
    cr: 22,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: 'Mind Flayers',
    alignment: 'any evil',
    environment: 'underground',
    ac: 17,
    hp: 135,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 172'
  },
  {
    guid: '156b6bb2-0a7f-4cc6-b2bd-f5b169334c9e',
    fid: 'volo.mindwitness',
    name: 'Mindwitness',
    cr: 5,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Mind Flayers',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 15,
    hp: 75,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 176'
  },
  {
    guid: '02054aad-6910-4e17-b22c-3d07b460240e',
    fid: 'volo.morkoth',
    name: 'Morkoth',
    cr: 11,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'coast, aquatic',
    ac: 17,
    hp: 130,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 177'
  },
  {
    guid: 'eefda17b-25ca-4266-b8ca-752b4893fc76',
    fid: 'volo.mouth-of-grolantor',
    name: 'Mouth of Grolantor',
    cr: 6,
    size: 'Huge',
    type: 'Giant',
    tags: 'Hill Giant',
    section: 'Giants',
    alignment: 'chaotic evil',
    environment: 'grassland',
    ac: 14,
    hp: 105,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 149'
  },
  {
    guid: 'c3ccfd43-a212-4181-b175-c65ff82a6fcc',
    fid: 'volo.necromancer',
    name: 'Necromancer',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'desert, urban',
    ac: 12,
    hp: 66,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 217, Tales from the Yawning Portal: 241'
  },
  {
    guid: '5b0404ef-4495-498f-ab87-067e4c9d1e65',
    fid: 'volo.neogi',
    name: 'Neogi',
    cr: 3,
    size: 'Small',
    type: 'Aberration',
    tags: '',
    section: 'Neogi',
    alignment: 'lawful evil',
    environment: 'grassland, underground',
    ac: 15,
    hp: 33,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 180'
  },
  {
    guid: '6d22face-0a8c-425b-90f1-defd1b65405f',
    fid: 'volo.neogi-hatchling',
    name: 'Neogi Hatchling',
    cr: '1/8',
    size: 'Tiny',
    type: 'Aberration',
    tags: '',
    section: 'Neogi',
    alignment: 'lawful evil',
    environment: 'grassland, underground',
    ac: 11,
    hp: 7,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 179'
  },
  {
    guid: '637e8b38-d786-4ca3-bb10-595c6b47cb6f',
    fid: 'volo.neogi-master',
    name: 'Neogi Master',
    cr: 4,
    size: 'Medium',
    type: 'Aberration',
    tags: '',
    section: 'Neogi',
    alignment: 'lawful evil',
    environment: 'grassland, underground',
    ac: 15,
    hp: 71,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 180'
  },
  {
    guid: 'f69dd8f8-3e32-4353-89b1-76772d043145',
    fid: 'volo.neothelid',
    name: 'Neothelid',
    cr: 13,
    size: 'Gargantuan',
    type: 'Aberration',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground',
    ac: 16,
    hp: 325,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 181'
  },
  {
    guid: '2daab424-8390-4a2e-a1f6-c9d64c46f417',
    fid: 'volo.nilbog',
    name: 'Nilbog',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Goblinoid',
    section: '',
    alignment: 'chaotic evil',
    environment: 'grassland, underground',
    ac: 13,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 182'
  },
  {
    guid: '809eb1b7-e519-4541-8627-04045700ec5a',
    fid: 'volo.orc-blade-of-ilneval',
    name: 'Orc Blade of Ilneval',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'forest, grassland, mountain, underground',
    ac: 18,
    hp: 60,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 183'
  },
  {
    guid: 'e4315ee2-bbef-4aac-9fe3-98e581b72bb3',
    fid: 'volo.orc-claw-of-luthic',
    name: 'Orc Claw of Luthic',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'mountain, underground',
    ac: 14,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 183'
  },
  {
    guid: 'ace61424-ca9f-4ad8-abb9-2c69f8d051db',
    fid: 'volo.orc-hand-of-yurtrus',
    name: 'Orc Hand of Yurtrus',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'forest, grassland, mountain, underground',
    ac: 12,
    hp: 30,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 184'
  },
  {
    guid: 'fb666504-a649-459d-a774-ca6505e12fef',
    fid: 'volo.orc-nurtured-one-of-yurtrus',
    name: 'Orc Nurtured One of Yurtrus',
    cr: '1/2',
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'forest, grassland, mountain, underground',
    ac: 9,
    hp: 30,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 184'
  },
  {
    guid: 'c59837a7-5864-417c-a778-2cf43e912857',
    fid: 'volo.orc-red-fang-of-shargaas',
    name: 'Orc Red Fang of Shargaas',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'forest, grassland, mountain, underground, urban',
    ac: 15,
    hp: 52,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 185'
  },
  {
    guid: 'e3c8935d-7149-49a2-be6e-ba6e9018c3c9',
    fid: 'volo.quetzalcoatlus',
    name: 'Quetzalcoatlus',
    cr: 2,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'coast, grassland, mountain',
    ac: 13,
    hp: 30,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 140, Tomb of Annihilation: 230'
  },
  {
    guid: '7c9f42e8-b973-40e0-8870-5c947af01d9f',
    fid: 'volo.quickling',
    name: 'Quickling',
    cr: 1,
    size: 'Tiny',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'forest',
    ac: 16,
    hp: 10,
    init: 6,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 187'
  },
  {
    guid: '59d3c891-5410-4111-9763-ad8aa4aed76d',
    fid: 'volo.redcap',
    name: 'Redcap',
    cr: 3,
    size: 'Small',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'forest, grassland, swamp',
    ac: 13,
    hp: 45,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 188'
  },
  {
    guid: 'e8defb89-88b4-49e7-b636-6aa98ce0974a',
    fid: 'volo.rothe',
    name: 'Rothe',
    cr: '1/4',
    size: 'Medium',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'grassland, underground',
    ac: 10,
    hp: 13,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 208'
  },
  {
    guid: 'c133c8f1-0c56-4559-9d65-e5670f03ee51',
    fid: 'volo.sea-spawn',
    name: 'Sea Spawn',
    cr: 1,
    size: 'Medium',
    type: 'Humanoid',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'coast, aquatic',
    ac: 11,
    hp: 32,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 189'
  },
  {
    guid: '24d66847-cd56-4edf-a8c9-2973df80b051',
    fid: 'volo.shadow-mastiff',
    name: 'Shadow Mastiff',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp',
    ac: 12,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 190'
  },
  {
    guid: '3538794e-d8ff-426f-9019-04bbcd08397b',
    fid: 'volo.shadow-mastiff-alpha',
    name: 'Shadow Mastiff Alpha',
    cr: 2,
    size: 'Medium',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'forest, grassland, swamp',
    ac: 12,
    hp: 48,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 190'
  },
  {
    guid: '282b94f2-c50e-4e06-b8ce-31d89d0803d1',
    fid: 'volo.shoosuva',
    name: 'Shoosuva',
    cr: 8,
    size: 'Large',
    type: 'Fiend',
    tags: 'Demon',
    section: 'Demons',
    alignment: 'chaotic evil',
    environment: 'arctic, forest, grassland',
    ac: 14,
    hp: 110,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 137'
  },
  {
    guid: '3020ee17-2fc0-473d-a8e3-f730c05bc935',
    fid: 'volo.slithering-tracker',
    name: 'Slithering Tracker',
    cr: 3,
    size: 'Medium',
    type: 'Ooze',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'underground, urban',
    ac: 14,
    hp: 32,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 191'
  },
  {
    guid: '4d268c1d-293b-4351-b849-6fbb79aeae8a',
    fid: 'volo.spawn-of-kyuss',
    name: 'Spawn of Kyuss',
    cr: 5,
    size: 'Medium',
    type: 'Undead',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'desert, underground',
    ac: 10,
    hp: 76,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 192'
  },
  {
    guid: 'afe3a501-2d72-44e9-8761-1f3f5d773255',
    fid: 'volo.stegosaurus',
    name: 'Stegosaurus',
    cr: 4,
    size: 'Huge',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 13,
    hp: 76,
    init: -1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 140, Tomb of Annihilation: 231'
  },
  {
    guid: '9140ec74-c6cb-453d-b9fa-624a177aa84a',
    fid: 'volo.stench-kow',
    name: 'Stench Kow',
    cr: '1/4',
    size: 'Large',
    type: 'Beast',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'grassland',
    ac: 10,
    hp: 15,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 208'
  },
  {
    guid: 'fcdea77a-b7ab-4b3d-aaf7-78706009286c',
    fid: 'volo.stone-giant-dreamwalker',
    name: 'Stone Giant Dreamwalker',
    cr: 10,
    size: 'Huge',
    type: 'Giant',
    tags: 'Stone Giant',
    section: 'Giants',
    alignment: 'chaotic neutral',
    environment: 'coast, grassland, mountain',
    ac: 18,
    hp: 161,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 150'
  },
  {
    guid: '2e0ba72f-74ff-4828-bf1c-3479944bea4a',
    fid: 'volo.storm-giant-quintessent',
    name: 'Storm Giant Quintessent',
    cr: 16,
    size: 'Huge',
    type: 'Giant',
    tags: 'Storm Giant',
    section: 'Giants',
    alignment: 'chaotic good',
    environment: 'arctic, coast, desert, mountain, aquatic',
    ac: 12,
    hp: 230,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 151'
  },
  {
    guid: '036a9bdf-1a1e-4213-88c7-9080d95dfa3d',
    fid: 'volo.swarm-of-cranium-rats',
    name: 'Swarm of Cranium Rats',
    cr: 5,
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: 'Cranium Rats',
    alignment: 'lawful evil',
    environment: 'underground, urban',
    ac: 12,
    hp: 36,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 133'
  },
  {
    guid: '073f64fe-97e3-4d24-b203-fa511db582cc',
    fid: 'volo.swarm-of-rot-grubs',
    name: 'Swarm of Rot Grubs',
    cr: '1/2',
    size: 'Medium',
    type: 'Beast',
    tags: 'Swarm',
    section: '',
    alignment: 'unaligned',
    environment: 'swamp, underground',
    ac: 8,
    hp: 22,
    init: -2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 208'
  },
  {
    guid: '51d8819f-c57b-4fa3-8efc-f98c665e0683',
    fid: 'volo.swashbuckler',
    name: 'Swashbuckler',
    cr: 3,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'non-lawful',
    environment: 'coast, urban',
    ac: 17,
    hp: 66,
    init: 4,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 217'
  },
  {
    guid: 'e976e397-075c-4771-93b7-70982088dcdd',
    fid: 'volo.tanarukk',
    name: 'Tanarukk',
    cr: 5,
    size: 'Medium',
    type: 'Fiend',
    tags: 'Demon, Orc',
    section: 'Orcs',
    alignment: 'chaotic evil',
    environment: 'grassland, mountain, underground',
    ac: 14,
    hp: 95,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 186'
  },
  {
    guid: 'a8c83916-acbc-4a73-9c9b-cf75d187611a',
    fid: 'volo.thorny',
    name: 'Thorny',
    cr: 1,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'neutral',
    environment: 'forest, swamp',
    ac: 14,
    hp: 27,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 197, Tomb of Annihilation: 233'
  },
  {
    guid: '33f3dc3e-b613-42c0-b39c-679ef86fd3d8',
    fid: 'volo.tlingcalli',
    name: 'Tlincalli',
    cr: 5,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'desert',
    ac: 15,
    hp: 85,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 193'
  },
  {
    guid: '583e060b-a8a2-48e4-8474-cdcdf09da2fa',
    fid: 'volo.transmuter',
    name: 'Transmuter',
    cr: 5,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 12,
    hp: 40,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 218, Tales from the Yawning Portal: 247'
  },
  {
    guid: '49058685-76c7-40bd-9d54-cf1f129b4232',
    fid: 'volo.trapper',
    name: 'Trapper',
    cr: 3,
    size: 'Large',
    type: 'Monstrosity',
    tags: '',
    section: '',
    alignment: 'unaligned',
    environment: 'underground',
    ac: 13,
    hp: 85,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 194'
  },
  {
    guid: '839c78e4-80b5-4bb8-8520-a34dbe2bf267',
    fid: 'volo.ulitharid',
    name: 'Ulitharid',
    cr: 9,
    size: 'Large',
    type: 'Aberration',
    tags: '',
    section: 'Mind Flayers',
    alignment: 'lawful evil',
    environment: 'underground',
    ac: 15,
    hp: 127,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 175'
  },
  {
    guid: '50d25ff2-2415-4ecd-a613-888faa8e2297',
    fid: 'volo.vargouille',
    name: 'Vargouille',
    cr: 1,
    size: 'Tiny',
    type: 'Fiend',
    tags: '',
    section: '',
    alignment: 'chaotic evil',
    environment: 'desert, swamp, underground',
    ac: 12,
    hp: 13,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 195'
  },
  {
    guid: '88fc8e6f-80d2-4a2c-8de8-8fe8c20dd7e1',
    fid: 'volo.vegepygmy',
    name: 'Vegepygmy',
    cr: '1/4',
    size: 'Small',
    type: 'Plant',
    tags: '',
    section: 'Vegepygmies',
    alignment: 'neutral',
    environment: 'forest, swamp',
    ac: 13,
    hp: 9,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 196, Tomb of Annihilation: 234'
  },
  {
    guid: '483f3c15-a707-4ef1-8e37-6d1168d7b404',
    fid: 'volo.vegepygmy-chief',
    name: 'Vegepygmy Chief',
    cr: 2,
    size: 'Small',
    type: 'Plant',
    tags: '',
    section: 'Vegepygmies',
    alignment: 'neutral',
    environment: 'forest, swamp',
    ac: 14,
    hp: 33,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 197, Tomb of Annihilation: 235'
  },
  {
    guid: '65747477-fa7c-49b1-98c3-ba5235b7f085',
    fid: 'volo.velociraptor',
    name: 'Velociraptor',
    cr: '1/4',
    size: 'Tiny',
    type: 'Beast',
    tags: '',
    section: 'Dinosaurs',
    alignment: 'unaligned',
    environment: 'forest, grassland',
    ac: 13,
    hp: 10,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 140, Tomb of Annihilation: 235'
  },
  {
    guid: '246cfb3f-20e8-40f9-ba67-3a6909231608',
    fid: 'volo.war-priest',
    name: 'War Priest',
    cr: 9,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'desert, urban',
    ac: 18,
    hp: 117,
    init: 0,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 218'
  },
  {
    guid: '55ebdcc0-9888-43c8-a018-452f9f6f8bed',
    fid: 'volo.warlock-of-the-archfey',
    name: 'Warlock of the Archfey',
    cr: 4,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, forest, mountain, swamp, urban',
    ac: 11,
    hp: 49,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 219'
  },
  {
    guid: '8c42b1a6-9628-4846-a720-d10860de5c57',
    fid: 'volo.warlock-of-the-fiend',
    name: 'Warlock of the Fiend',
    cr: 7,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, desert, underground, urban',
    ac: 12,
    hp: 78,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 219'
  },
  {
    guid: 'e17a93a4-e1b1-4919-b10c-d033db5918d2',
    fid: 'volo.warlock-of-the-great-old-one',
    name: 'Warlock of the Great Old One',
    cr: 6,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'arctic, grassland, mountain, underground, urban',
    ac: 12,
    hp: 91,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 220'
  },
  {
    guid: '934ebd78-8ec6-46f4-86d7-e079209102b8',
    fid: 'volo.warlord',
    name: 'Warlord',
    cr: 12,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Any Race',
    section: 'NPCs',
    alignment: 'any',
    environment: 'urban',
    ac: 18,
    hp: 229,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 220'
  },
  {
    guid: '7d758025-5f81-4ac7-a59e-6fca8774d61d',
    fid: 'volo.wood-woad',
    name: 'Wood Woad',
    cr: 5,
    size: 'Medium',
    type: 'Plant',
    tags: '',
    section: '',
    alignment: 'lawful neutral',
    environment: 'forest',
    ac: 18,
    hp: 75,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 198'
  },
  {
    guid: 'c6323018-2f32-49bd-8b94-2889024f4dbc',
    fid: 'volo.xvart',
    name: 'Xvart',
    cr: '1/8',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Xvart',
    section: 'Xvarts',
    alignment: 'chaotic evil',
    environment: 'grassland, underground',
    ac: 13,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 200'
  },
  {
    guid: '6746c13c-6bb1-4e96-8dd1-b0027e8683c8',
    fid: 'volo.xvart-speaker',
    name: 'Xvart Speaker',
    cr: '1/8',
    size: 'Small',
    type: 'Humanoid',
    tags: 'Xvart',
    section: 'Xvarts',
    alignment: 'chaotic evil',
    environment: 'grassland, underground',
    ac: 13,
    hp: 7,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 200'
  },
  {
    guid: '9af47702-ffc4-4555-a977-7d1339b59933',
    fid: 'volo.xvart-warlock-of-raxivort',
    name: 'Xvart Warlock of Raxivort',
    cr: 1,
    size: 'Small',
    type: 'Humanoid',
    tags: 'Xvart',
    section: 'Xvarts',
    alignment: 'chaotic evil',
    environment: 'grassland, underground',
    ac: 12,
    hp: 22,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 200'
  },
  {
    guid: '65451d58-1f95-4b9b-9fd0-d6c5205edf42',
    fid: 'volo.yeth-hound',
    name: 'Yeth Hound',
    cr: 4,
    size: 'Large',
    type: 'Fey',
    tags: '',
    section: '',
    alignment: 'neutral evil',
    environment: 'forest, grassland',
    ac: 14,
    hp: 51,
    init: 3,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 201'
  },
  {
    guid: '104b98f3-efa1-4403-bda1-e26350fcd23c',
    fid: 'volo.yuan-ti-anathema',
    name: 'Yuan-ti Anathema',
    cr: 12,
    size: 'Huge',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, underground',
    ac: 16,
    hp: 189,
    init: 1,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 202'
  },
  {
    guid: '1f60a7d3-7592-41cc-92d3-a696addde426',
    fid: 'volo.yuan-ti-broodguard',
    name: 'Yuan-ti Broodguard',
    cr: 2,
    size: 'Medium',
    type: 'Humanoid',
    tags: 'Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, underground',
    ac: 14,
    hp: 45,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 203, Tomb of Annihilation: 238'
  },
  {
    guid: '6c82d15c-907b-48e0-8866-dcb7f3e4cd0c',
    fid: 'volo.yuan-ti-mind-whisperer',
    name: 'Yuan-ti Mind Whisperer',
    cr: 4,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, underground',
    ac: 14,
    hp: 71,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 204'
  },
  {
    guid: '16795632-bba4-48e3-86cd-08d66cbdd4b5',
    fid: 'volo.yuan-ti-nightmare-speaker',
    name: 'Yuan-ti Nightmare Speaker',
    cr: 4,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, underground',
    ac: 14,
    hp: 71,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 205, Tomb of Annihilation: 239'
  },
  {
    guid: 'cc9084c4-a3ed-4ffd-9273-e68a03b5b40f',
    fid: 'volo.yuan-ti-pit-master',
    name: 'Yuan-ti Pit Master',
    cr: 5,
    size: 'Medium',
    type: 'Monstrosity',
    tags: 'Shapechanger, Yuan-ti',
    section: 'Yuan-ti',
    alignment: 'neutral evil',
    environment: 'desert, forest, underground',
    ac: 14,
    hp: 88,
    init: 2,
    lair: '',
    legendary: '',
    unique: '',
    sources: 'Volo\'s Guide to Monsters: 206'
  }
];