import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // paddingLeft: "2rem",
    flewGrow: 1,
    fontFamily: "draconis",
    backgroundColor: theme.palette.primary.background,
    marginTop: "45px",
    overflow: "hidden",
    overflowY: "hidden",
    // height: "calc(100% -2em)"
  },
  title:{
  fontSize: 32,
  margin: "0 auto",
  fontFamily: "draconis",
  paddingBottom: "1rem"
  },
  titleBattle:{
    fontSize: 32,
    // margin: "0 auto",
    margin: "0 0",
    fontFamily: "draconis",
    paddingBottom: "1rem"
  },
  subtitle:{
    fontSize: 18,
    margin: "0 auto",
    fontFamily: "draconis",
    padding: "2rem 0"
  },
  difficult:{
    fontFamily: "draconis",
    fontSize: 20,
  },
  container: {
    marginTop: "65px"
  },
  multilineColor: {
    color: theme.palette.secondary.light,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 20,
    color: theme.palette.secondary.light,
    fontFamily: "draconis",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
      fontFamily: "draconis"
    },
  },
  randomButton: {
    justifyContent: "center", 
    alignItems: "center", 
    margin: "auto 1rem", 
    backgroundColor:  theme.palette.primary.main
  },
  buttonPlayer: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    height: "1.5rem",
    color: theme.palette.secondary.light,
    fontFamily: "draconis",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
      fontFamily: "draconis"
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    marginBottom: "1rem",
    minWidth: 120,
    height: '2rem'
  },
  selectEmpty: {
    // marginTop: theme.spacing(.5),
    margin: "0",
    height: "1rem",
  },
  table:{
    backgroundColor: theme.palette.primary.background
  },
  totals:{
    fontSize: 20,
    padding: "5px 0",
    margin: "5px 0",
    display: "flex",
    // '&:p':{
    //   padding: "-10px 0",
    //   margin: "-10px 0",
    // }
  },
  totalsSmall: {
    fontSize: 14,
    fontWeight: 100,
    fontFamily: "monotype",
    // maringTop: -12
  },
  list:{
    padding: ".05rem 0",
    fontSize: 20
  },

}));