import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// Material UI
import HomeStyle from '../components/styles/HomeStyle';
import HomeStyleMain from '../components/styles/HomeStyleBody';
import Form from '../components/styles/FormStyle';

// Components
import { AlertSuccess } from "../components/AlertSuccess";
import Footer from '../components/Footer';

// Temp Logo
import logo from '../images/Artboard_7.png'

const Home = () =>
{
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleEmail = (event) =>
  {
    event.preventDefault();
    if (email === "" || emailIsValid === false)
    {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    setAlert(true);

    setTimeout(
      function ()
      {
        setAlert(false);
      },
      4000
    );

    setFinished(true);

    const data = { email: email };

    fetch('https://us-central1-the-tavern-91a7d.cloudfunctions.net/api/registeremail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data =>
      {
        console.log('Success:', data);
      })
      .catch((error) =>
      {
        console.error('Error:', error);
      });
  };

  const emailIsValid = () =>
  {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <HomeStyle>
      {alert ? <AlertSuccess /> : null}
      <HomeStyleMain>
        <div className='left_box'>
          <img src={logo} className='logo' alt="logo" />
          <h2>
            HOW MAY WE SERVE YOU
          </h2>
          <div className='main_body'>
            <h3>
              The tavern is going to change the way players and dungeon masters experience D&D. We understand the pain of having far to many tabs open to try to run one session. We plan to fix that; by putting everything into one application and improving on existing functions seen in other applications. We plan to do more than just be an assistant to your D&D games, we have long term plans for the website and as a brand as a whole. This is just a first step in a long journey that we hope you will join us for.
            </h3>
          </div>
          {
            <>
              {!finished &&
                <Form>
                  <div></div>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    style={{color: "#FFCB9A"}}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    onClick={handleEmail}
                  >
                    Subscribe
                  </button>
                </Form>
              }
            </>
          }
          {finished ? (
            <div>
              <h2 className='email_success'>
                Thank you for for joining thetavern.io!
              </h2>
            </div>
          ) : <div>
            {emailError ? (
              <div>
                <p className='email_error'>
                  *Not a valid email.
                </p>
              </div>
            ) : null
            }
            <h4>
              Never spam. No personal information, ever.
            </h4>
          </div>
          }
          <div className='terms_links'>
            <p
              className="links"
            >
              <Link style={{ color: "#FFCB9A" }} to='/terms'>
                Terms and Conditions
              </Link>

            </p>
            <p className="links">
              <Link style={{ color: "#FFCB9A" }} to='/policy'>
                Privacy and Policy
              </Link>
            </p>
          </div>
        </div>
      </HomeStyleMain>
      <Footer />
    </HomeStyle>
  )
}

export default Home;