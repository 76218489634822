import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// redux
import { connect, useSelector, useDispatch } from 'react-redux';
import { selectMonster, addMonster, subMonster, removeMonster, addPlayer } from '../../redux/actions/battleActions';
import { updatelist, resetMonsterList,  removePlayer} from '../../redux/actions/battleActions';

// components
import { useWindowDimensions } from '../../components/hooks/useWindowDimensions';
import MonsterList from '../../components/monsterlist/MonsterList';
import { useDebounce } from '../../components/hooks/useDebounce';
import SearchBar from '../../components/searchbar/SearchBar';
import Table from '../../components/table/Table';

// Material UI
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormHelperText from '@material-ui/core/FormHelperText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';

// monsters
import { monsters } from './monsters';


//styles
import { useStyles } from './BattlePlan.styles';
import BattlePlayer from './../../components/BattlePlayer';
import BattleExp from '../../components/battleexp/BattleExp';
import DataTable from '../../components/datatable/DataTable';
import { isMobile } from 'react-device-detect';

const BattlePlan = (props) => {
    // console.log(props);
    const classes = useStyles();
    // const debouncedFn = useDebounce(dispatch(updatelist(newTempList)));
    // const memoizedDebounce = useCallback(debouncedFn, [debouncedFn]);

    // dispatch(updatelist(newTempList));
    const {height, width} = useWindowDimensions();

    const dispatch = useDispatch();
    const [battleDifficult, setBattleDifficulty] = useState({easy: 25, medium: 50, hard: 75, deadly: 100});
    const selectedMonsters = useSelector(state=> state.battle.selectedMonsters);
    const monsterList = useSelector(state => state.battle.monsterList);
    const adjustExp = useSelector( state =>  state.battle.adjustXp);
    const totalExp = useSelector(state => state.battle.totalXp);
    // const [dailyBudget, setDailyBudget] = useState(300);
    const dailyBudget = useSelector( state => state.battle.dailyBudget);
    const [selMonster, setselMonster] = useState([]);
    const [arrLevels, setArrLevels] = useState([]);
    // const [newList, setNewList] = useState([]);
    const [players, setPlayers] = useState(1);
    const [levels, setLevels] = useState(1);
    const [word, setWord] = useState("");
    // const playerCollection = useSelector(state => state.battle.playerCollection);
    // TODO: Makde this a product of the other list design for the level of players

    // const handleChange = (event) => {
    //   console.log(event.target)
    //   const values = {
    //     players,
    //     levels
    //   }
    //   if(event.target.name === "players"){
    //     setPlayers(event.target.value);
    //     values.players = event.target.value;
    //   }

    //   if(event.target.name === "levels"){
    //     setLevels(event.target.value);
    //     values.levels = event.target.value;
    //   }

    //   setBattleDifficulty(
    //     { easy: (values.players* easyMap.get(values.levels)),
    //       medium: (values.players* mediumMap.get(values.levels)),
    //       hard: (values.players* hardMap.get(values.levels)),
    //       deadly: (values.players* deadlyMap.get(values.levels))
    //   })

    //   const budget =  values.players* dailyBudgetMap.get(values.levels);
    //   setDailyBudget(budget);
    // };


    // word will keep track of any change to the input in filter box
    // useEffect(() => {

    // }, [props.selectedMonsters]);

    //
    // const selectedMonster = (monster) => {
    //     const temp = [...selMonster];
    //     const index = temp.findIndex(obj => obj.name === monster.name);
        
    //     if (-1 === index) {
    //         setselMonster([...selMonster, { ...monster, count: 1 }]);
    //         props.selectMonster([...selMonster, { ...monster, count: 1 }]);
    //     } else {
    //         temp[index].count = temp[index].count + 1;
    //         setselMonster(temp);
    //         props.selectMonster(temp);
    //     }
    // };
    //
    // const subMonsterCount = monster => {
    //     const temp = [...selMonster];
    //     console.log(temp);
    //     const index = temp.findIndex(obj => obj.name === monster.name);

    //     if (temp[index].count <= 1) {
    //         return unselectMonster(monster);
    //     } else {
    //         temp[index].count = temp[index].count - 1;
    //         setselMonster(temp);
    //         props.subMonster(temp);
    //     }
    // };

    // unselecting monster from list
    // const unselectMonster = (monster) => {

    //     const temp = [...selMonster];
    //     const index = temp.findIndex(obj => obj.name === monster.name);
        
    //     temp.splice(index, 1);

    //     setselMonster([...temp]);
    //     props.removeMonster([...temp]);
    // };

    const search = (monster, wx) => {
        const w = wx.toLowerCase();
        if ((monster.guid).toLowerCase().includes(w)) return true;
        if ((monster.fid).toLowerCase().includes(w)) return true;
        if ((monster.name).toLowerCase().includes(w)) return true;
        if (monster.cr === w) return true;
        if ((monster.size).toLowerCase().includes(w)) return true;
        if ((monster.type).toLowerCase().includes(w)) return true;
        if ((monster.tags).toLowerCase().includes(w)) return true;
        if ((monster.section).toLowerCase().includes(w)) return true;
        if ((monster.alignment).toLowerCase().includes(w)) return true;
        if ((monster.environment).toLowerCase().includes(w)) return true;
        if (monster.ac === w) return true;
        if (monster.hp === w) return true;
        if (monster.init === w) return true;
        if ((monster.lair).toLowerCase().includes(w)) return true;
        if ((monster.legendary).toLowerCase().includes(w)) return true;
        if ((monster.unique).toLowerCase().includes(w)) return true;
        if ((monster.sources).toLowerCase().includes(w)) return true;

        return false;
    };
    // TODO: add lodash for interval
    // searching for monster

    const handleAddPlayer = () =>{
      dispatch( addPlayer() );
    }


    const searchWord = (event) => {
        // console.log(event);
        setWord(event);

       
        if (event !== "") {
            const newTempList = monsters.filter(x => search(x, event));

            dispatch(updatelist(newTempList));
        } else {

            dispatch(resetMonsterList());
        }
    };

    // const calculateDiff = ()=>{

    //   if(totalExp <= battleDifficult.easy )
    //     return "Easy"
    //   if(totalExp > battleDifficult.easy && totalExp <= battleDifficult.medium)
    //     return "Medium"
    //   if(totalExp > battleDifficult.medium && totalExp <= battleDifficult.hard)
    //     return "Hard"
    //   if(totalExp > battleDifficult.hard)
    //     return "Deadly"
    // }

    // console.log("height: ",  height-66, "width: ", width);
    return (
    <Grid container spacing={0} component="main" className={classes.root} style={{height: `${height-66}px`, overflowY: 'auto'}}>
        {isMobile && <div style= {{display: 'flex', top: "50%", justifyContent: 'center', alignContent: 'center'}}> <h1 style={{fontWeight: 100, }}>Mobile Support Coming Soon!</h1>
          </div>}
      {  !isMobile && 
      <>
      <Grid item xs={12} sm={4} style={{paddingLeft: '1rem', marginTop: '1rem'}}>
          <div>
            <div>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} >
                <p className={classes.title}>Party Info</p>
                <div className={classes.totals}>
                  <div>
                    <div style={{minWidth: 120}}>{`Total Exp: ${(totalExp/players).toLocaleString("en-US")}`}</div>
                    <div className={classes.totalsSmall}>{`Per Player: ${(totalExp/players).toLocaleString("en-US")}`}</div>
  
                  </div>
                  <div style={{paddingLeft: "1rem"}}>
                    <div>{`Adjust Exp: ${(adjustExp/players).toLocaleString("en-US")}`}</div>
                    <div className={classes.totalsSmall}>{`Per Player: ${(adjustExp/players).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <BattlePlayer/>
                <Button className={classes.buttonPlayer} onClick={() => handleAddPlayer()}><AddIcon size="sm"/>Add Another Player</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div style={{marginRight: "1rem", marginTop: '.5rem', textAlign: "right"}}>
                <BattleExp classes={classes} />
                <p className={classes.list}>Daily Budget: {dailyBudget}</p>
              </div>
            </Grid>
            </Grid>
            </div>
            <Divider/>
            <div >
              <div style={{marginTop: ".5rem", display: "flex", justifyContent: "space-between",alignItems:"flex-start", flex: "1" }}>
                <p className={classes.titleBattle}>Battle Info</p>
                <div className={classes.randomButton}>
                  {/* FIXME: for the bottuns below className={classes.button} */}
                  <Button diabled  style={{height: "2rem", cursor: 'default'}}>Random Coming Soon!</Button>
                  {/* <Button disbled style={{height: "2rem", cursor: 'default', borderLeft: "1px solid white"}}><ExpandMoreIcon/></Button> */}
                </div> 
              </div>
              <MonsterList classes={classes}/>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} style={{ overflowY: "hidden"}}>
            <SearchBar value={word} searchWord={event => searchWord(event.target.value)} />
            {/* <Table  /> */}
            <DataTable/>
        </Grid>
        </>
        }
    </Grid>

    );
};

BattlePlan.propTypes = {
    // selectedMonsters: PropTypes.array.isRequired,
    selectMonster: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    selectedMonsters: state.battle.selectedMonsters,
});

const mapActionsToProps = {
    selectMonster,
    addMonster,
    subMonster,
    removeMonster,
};

// FIXME: FIX THIS
// export default connect(
//     mapStateToProps,
//     mapActionsToProps
// )(BattlePlan);

export default BattlePlan;