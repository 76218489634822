import
{
  SET_SELECTED_MONSTER,
  ADD_MONSTER,
  SUB_MONSTER,
  REMOVE_SELECETED_MONSTER,
  ADD_MONSTER_FILTER,
  FILTER_MONSTER_LIST,
  RESET_FILTERS,
  UPDATE_LIST,
  RESET_MONSTER_LIST,
  ADD_ANOTHER_PLAYER,
  REMOVE_PLAYER,
  EDIT_PLAYER,
  SELECT_ROW,
} from "./../types";

const selectedMonster = (currentList, monster) => {
  const temp = [...currentList];
  const index = temp.findIndex(obj => obj.name === monster.name);
  
  if (-1 === index) {
      // setselMonster([...selMonster, { ...monster, count: 1 }]);
      // props.selectMonster([...currentList, { ...monster, count: 1 }]);
      temp.push({ ...monster, count: 1 });
  } else {
      temp[index].count = temp[index].count + 1;
      // setselMonster(temp);
  }
   return temp;
};

const unselectMonster = (currentList, monster) => {

  const temp = currentList.filter(obj => obj.name !== monster.name);
  // const index = temp.findIndex(obj => obj.name === monster.name);
  
  // temp.splice(index, 1);

  
  // setselMonster([...temp]);
  // props.removeMonster([...temp]);

  return temp;
};
// //
const subMonsterCount = (currentList, monster) => {
  // const temp = [...currentList];

  const index = currentList.findIndex(obj => obj.name === monster.name);

  currentList[index].count = currentList[index].count - 1;

  return currentList;
};


const exp = new Map();
    exp.set( 0, 10)
    exp.set( "1/8", 25)
    exp.set( "1/4", 50)
    exp.set( "1/2", 100)
    exp.set( 1, 200)
    exp.set( 2, 450)
    exp.set( 3, 700)
    exp.set( 4, 1100)
    exp.set( 5, 1800)
    exp.set( 6, 2300)
    exp.set( 7, 2900)
    exp.set( 8, 3900)
    exp.set( 9, 5000)
    exp.set( 10, 5900)
    exp.set( 11, 7200)
    exp.set( 12, 8400)
    exp.set( 13, 10000)
    exp.set( 14, 11500)
    exp.set( 15, 13000)
    exp.set( 16, 15000)
    exp.set( 17, 18000)
    exp.set( 18, 20000)
    exp.set( 19, 22000)
    exp.set( 20, 25000)
    exp.set( 21, 33000)
    exp.set( 22, 41000)
    exp.set( 23, 50000)
    exp.set( 24, 62000)
    exp.set( 25, 75000)
    exp.set( 26, 90000)
    exp.set( 27, 105000)
    exp.set( 28, 120000)
    exp.set( 29, 135000)
    exp.set( 30, 155000)

const mulExp = new Map();
  mulExp.set(1, 1)
  mulExp.set(2, 1.5)
  mulExp.set(3, 2)
  mulExp.set(4, 2)
  mulExp.set(5, 2)
  mulExp.set(6, 2)
  mulExp.set(7, 2.5)
  mulExp.set(8, 2.5)
  mulExp.set(9, 2.5)
  mulExp.set(10, 2.5)
  mulExp.set(11, 3)
  mulExp.set(12, 3)
  mulExp.set(13, 3)
  mulExp.set(14, 3)
  mulExp.set(15, 4)



const handleCrExpConversion = (cr) =>{
    // console.log(exp.get(cr))
    return exp.get(cr);
}

const calculateTotals = (monsterList) =>{

  const totals = {
    totalCount: 0,
    totalXp: 0,
    adjustXp: 0,
    monsterList
  }
  
  monsterList.map((monster) => {
    totals.totalCount += monster.count;
    totals.totalXp += (monster.count * handleCrExpConversion(monster.cr))
  })

  if(totals.totalCount === 0)
    totals.adjustXp = 0
  else{
    if(totals.totalCount <= 15)
      totals.adjustXp = mulExp.get(totals.totalCount) * totals.totalXp;
    else 
      totals.adjustXp = mulExp.get(15) * totals.totalXp;
  }

  return totals;
}

export const searchMonster = ( userEntry ) => (dispatch) =>{

}

export const addPlayer = (   ) => (dispatch) =>{
  dispatch({type: ADD_ANOTHER_PLAYER});
}
export const editPlayer = (player) => (dispatch) => {
  dispatch({type: EDIT_PLAYER, payload: player})
}

export const removePlayer = ( player ) => (dispatch) => {
  dispatch({type: REMOVE_PLAYER, payload: player})
}

export const updatelist = (monsterList) => (dispatch) =>{

  dispatch({ type: UPDATE_LIST, payload: monsterList });
}

export const resetMonsterList = () => (dispatch) =>{
  
  dispatch({ type: RESET_MONSTER_LIST});
}
export const selectMonster = (monsterList, monster) => (dispatch) =>{
  const newMosterList = selectedMonster(monsterList, monster)
  const totals = calculateTotals(newMosterList)

  dispatch({ type: SET_SELECTED_MONSTER, payload: totals });
};
export const addMonster = (monsterList) => (dispatch) =>
{
  const totals = calculateTotals(monsterList)
  dispatch({ type: ADD_MONSTER, payload: totals });
};
export const subMonster = (monsterList, monster) => (dispatch) =>
{
  const newMonsterList = subMonsterCount(monsterList, monster);
  const totals = calculateTotals(newMonsterList);

  dispatch({ type: SUB_MONSTER, payload: totals });
};

export const removeMonster = (monsterList, monster) => (dispatch) =>{

  const newMonsterList = unselectMonster(monsterList, monster);
  const totals = calculateTotals(newMonsterList);

  dispatch({ type: REMOVE_SELECETED_MONSTER, payload: totals });
};

export const addMonsterFilter = ( filters) => (dispatch) => {
  dispatch({ type: ADD_MONSTER_FILTER, payload: filters});
}

export const filterMonsterList = (filterName, filterValue) => (dispatch) => {
  dispatch({type: FILTER_MONSTER_LIST, paylod: {name: filterName, value: filterValue }});
}

export const resetMonsterFilter = () => (dispatch) => {
  dispatch({ type: RESET_FILTERS});
}

export const saveBattlePlan = () => (dispatch) => {

}

export const loadBattlePlan = () => (dispatch) => {

}