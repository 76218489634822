import styled from "@emotion/styled";

const AboutBodyStyle = styled.body`
    display: grid;
    grid-template-columns: 30% 70%;
    grid-auto-flow: dense;
    column-gap: 10px;
    font-weight: 500;
    line-height: 1.7em;
    background-color: transparent;
    text-align: center;
    border-color: aqua;
    padding: 10px;
    :nth-child(odd){
        direction: rtl;
        .member_description {
            direction: ltr;
        }
        .member_name { 
            direction: ltr;
        }
        img {
            direction: ltr;
        }
    }
    .image{
        align-self: center;
        justify-content: center;
    }
    img {
        max-height: 200px;
        max-width: 200px;
        border-radius: 7%;
        background-size: cover;
        background-position: center;
    }
    .image_div {
        display: grid;
        grid-template-rows: 1fr;
        align-self: center;
        justify-content: center;
        text-align: center;    
        color: #E0FFFF;
    }
    .member_name {
        display: grid;
        grid-template-columns: 30% 70%;
        text-align: start;
        column-gap: 10px;
    }
    .member_description {
        color: #F5DEB3;
        align-self: flex-start;
        text-align: center;
        padding: 10px;
    }
`;

export default AboutBodyStyle;