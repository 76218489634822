import styled from "@emotion/styled";

const TicketStyles = styled.div`
    max-width: 1200px;
    min-height: 100vh;
    height: 100vh;
    display: grid;
    grid-template-rows: auto;
    row-gap: 15px;
    padding-top: 65px;
    margin: auto;
    font-family: prototype;
    background-color: #424242;
    text-align: center;
    h1 {
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 40px;
    }
    h4 {
        font-weight: 100;
        font-size: x-large;
    }
    form {
        display: grid;
        grid-template-rows:  5% 10% 5% 10% 5% 20% 10% 5%;
        grid-row-gap: 20px;
        justify-items: flex-start;
        align-content: flex-start;
        line-height: 1.5rem;
        font-weight: 600;
        padding-left: 20rem;
        padding-right: 5em;
        
    }
    input,
    select {
        width: 350px;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 100;
        border: 2px solid grey;
        height: 130%;
        border-radius: 5px;
        background-color: #424242;
        color: #FFCB9A;
        &:focus {
            outline: 0;
            background-color: #2C2F33;
            border-color: #D9B08C;
            font-size: 1.2rem;
            font-weight: 100;
        }
    }
    textarea{
        width: 80%;
        padding: 0.5rem;
        font-size: 1rem;
        border: 2px solid grey;
        height: 130%;
        border-radius: 5px;
        background-color: #424242;
        color: #FFCB9A;
        &:focus {
            outline: 0;
            background-color: #2C2F33;
            border-color: #D9B08C;
        }
    }
    .submit{
        box-shadow: 0 2px 0 rgba(0,0,0,.35);
        background-color: #116466;
        width: 5em;
        height: auto;
        max-height: 2em;
        color: #FFCB9A;
        margin-left: 30;
        margin-top: 20;
        margin-bottom: 0;
        
        font-size: 1.5rem;
        border-radius: 5px;
        border-color: transparent;
        max-width: 20%;
        justify-self: center;
        &:hover {
            color: #116466;
            background-color: #D1E8E2;
            font-family: prototype;
            cursor: pointer;
            
            min-height: 44;
            margin-top: 20;
            margin-bottom: 0;
        }
    }
    label {
        font-weight: 100;
        font-size: 20px;
    }
    .email {
        font-weight: 100;
        font-size: 20px;
    }
`;

export default TicketStyles;