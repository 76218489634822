import axios from "axios";

class Api 
{
    constructor(baseUrl = '/', headers = null, params = null)
    {
        this.instance = axios.create({
            baseURL: baseUrl,
            headers: headers || { 'Content-Type': 'application/json' },
            params: params || {},
        })
    }

    setBearerToken(token)
    {
        this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    removeBearerToken()
    {
        delete this.instance.defaults.headers.common.Authorization;
    }
}

export default Api;