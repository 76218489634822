import styled from "@emotion/styled";

const AboutStyled = styled.div`
    max-width: 1200px;
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 15px;
    padding-top: 65px;
    margin: auto;
    h1 {
        font-size: 3em;
        font-weight: 900;
        color: #B0C4DE;
        margin-bottom: 0px;
        text-align: center;
    }
`;

export default AboutStyled;